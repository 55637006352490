import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { DateToTimestampService } from 'src/app/services/date-to-timestamp.service';
import { QuotationsService } from 'src/app/services/quotations.service';

@Component({
  selector: 'app-truck-form',
  templateUrl: './truck-form.component.html',
  styleUrls: ['./truck-form.component.scss']
})
export class TruckFormComponent implements OnInit {

  selectedfile = null;
  reader: FileReader;
  selectedfile2 = null;
  reader2: FileReader;
  rides: any;
  max: any = null
  min: any = null
  startDateFormat: any = null
  startIssueDateFormat: any = null

  isSubmit: boolean = false

  truckForm: any = this.fb.group({
    color: [],
    year: [,],
    number: [, Validators.required],
    model: [, Validators.required],
    license_number: [, Validators.required],
    manufacturer: [,],
    weight: [,],
    capacity: [,],
    license_expiration_date: [, Validators.required],
    license_issue_date: [, Validators.required],
    attachments_attributes: [],
  });

  constructor(private dialogRef: MatDialogRef<TruckFormComponent>,
    private dateToTimestamp: DateToTimestampService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private quotationsService: QuotationsService,
    private fb: FormBuilder,
    public translate: TranslateService,) {
    this.reader = new FileReader();
    this.reader2 = new FileReader();
  }

  setDateBox(event, name) {

    if (name == 'startDate') {
      this.startDateFormat = this.dateToTimestamp.convertDateToTimestamp(event)
      this.min = event.target.value
    } else {
      this.startIssueDateFormat = this.dateToTimestamp.convertDateToTimestamp(event)
      this.max = event.target.value
    }

  }



  closeModal() {
    this.dialogRef.close();
  }

  addOneDay(date = new Date()) {
    date.setDate(date.getDate() + 1);

    return date;
  }

  ngOnInit() {
    this.min = this.addOneDay(new Date())

    if (this.data != null) {
      this.truckForm.patchValue({
        color: this.data.truck?.color,
        year: this.data.truck?.year,
        number: this.data.truck?.number,
        model: this.data.truck?.model,
        license_number: this.data.truck?.license_number,
        license_expiration_date: new Date(this.data?.truck?.license_expiration_date),
        license_issue_date: new Date(this.data?.truck?.license_issue_date),
      })
    }
  }


  OnFileSelected(event) {
    this.selectedfile = <File>event.target.files[0];
    if (this.selectedfile) {
      const fileTypes = ['jpg', 'png', 'jpeg', 'gif'];
      let name = this.selectedfile.name;
      let array = name.split('.');
      let fileType = array[array.length - 1];
      if (fileTypes.includes(fileType)) {
        this.reader.readAsDataURL(this.selectedfile);
        this.reader.onload = () => { };
      } else {
        this.translate
          .get('MARKETPLACES.messages.mass_file_not_available', {
            value: 'mass_file_not_available',
          })
          .subscribe((res: string) => {
            const dialogRef = this.dialog.open(MessageFormComponent, {
              width: '400px',
              disableClose: true,
              data: { message: res, state: false },

            });
            dialogRef.afterClosed().subscribe(() => {
              this.selectedfile = {}
            });
          });
      }
    }
  }
  OnFileSelected2(event) {
    this.selectedfile2 = <File>event.target.files[0];
    if (this.selectedfile2) {
      const fileTypes = ['jpg', 'png', 'jpeg', 'gif'];
      let name = this.selectedfile2.name;
      let array = name.split('.');
      let fileType = array[array.length - 1];
      if (fileTypes.includes(fileType)) {
        this.reader2.readAsDataURL(this.selectedfile2);
        this.reader2.onload = () => { };
      } else {
        this.translate
          .get('MARKETPLACES.messages.mass_file_not_available', {
            value: 'mass_file_not_available',
          })
          .subscribe((res: string) => {
            const dialogRef = this.dialog.open(MessageFormComponent, {
              width: '400px',
              disableClose: true,
              data: { message: res, state: false },

            });
            dialogRef.afterClosed().subscribe(() => {
              this.selectedfile2 = {}
            });
          });
      }
    }
  }

  infoFormFormatted() {
    // this.startDateFormat = this.truckForm.get('license_expiration_date').value.getTime()
    // this.startIssueDateFormat = this.truckForm.get('license_issue_date').value.getTime()

    this.truckForm.patchValue({
      license_expiration_date: this.startDateFormat,
      license_issue_date: this.startIssueDateFormat,

    })

  }


  createTruck() {
    this.isSubmit = true;
    if (this.truckForm.valid) {
      this.infoFormFormatted();
      let data = {
        ride: {
          ...this.truckForm.value
        }
      }
      if (this.selectedfile != null || this.selectedfile2 != null) {
        data.ride.attachments_attributes = [
          {
            name: "front",
            file: this.reader.result
          },
          {
            name: "back",
            file: this.reader2.result
          },
        ]
      }

      this.dialogRef.close(data);
    }
  }


}
