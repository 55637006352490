<div class="component-loader" (errorCallback)="getStoreProfile()" [errorMessage]="'lOADER.errorMessage' | translate "
  component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
  [componentLoading]="loading">

  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <div class="flex items-center">
        <h2 class="mb-2"> {{ "STOREPROFILE.extra.profile_info" | translate }} </h2>
      </div>

      <div>

        <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="updateStoreProfile()">
          {{
          "STOREPROFILE.action.edite_store_profile" | translate }}</button>

        <!-- <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="shareProfile()"> {{
          "STOREPROFILE.action.shareLink" | translate }}</button> -->


      </div>
    </div>
    <div class=" grid grid-cols-8 gap-3 p-4">
      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "STOREPROFILE.extra.name_ar" | translate }}</p>
        <span class="tabel-content"> {{ storeProfile?.name }}</span>
      </div>

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "STOREPROFILE.extra.description_ar" | translate }}</p>
        <span class="tabel-content"> {{ shopNameAr?.description }}</span>
      </div>




      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "STOREPROFILE.extra.description_en" | translate }}</p>
        <span class="tabel-content"> {{ shopNameEn?.description }}</span>
      </div>

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "STOREPROFILE.extra.business_category_ar" | translate }}</p>
        <span class="tabel-content"> {{ shopNameAr?.business_category }}</span>
      </div>

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "STOREPROFILE.extra.business_category_en" | translate }}</p>
        <span class="tabel-content"> {{ shopNameEn?.business_category }}</span>
      </div>

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "STOREPROFILE.extra.email" | translate }}</p>
        <span class="tabel-content"> {{ storeProfile?.email }}</span>
      </div>
      <div class="col-span-4 md:col-span-2 row-span-2 show-phone">
        <p> {{ "STOREPROFILE.extra.phone" | translate }}</p>
        <span class="tabel-content"> {{ storeProfile?.phone }} </span>
      </div>
      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "STOREPROFILE.extra.tax_licence" | translate }}</p>
        <span class="tabel-content"> {{ storeProfile?.tax_number }}</span>
      </div>
      <div class="col-span-4 md:col-span-4 row-span-2">
        <p> {{ "WAREHOUSES.attributes.address.label" | translate }} <i class='bx bx-copy share-io pointer'
            (click)="copyAddress()"></i></p>
        <span class="tabel-content"> {{ storeProfile?.address?.address_line1 }}</span>
      </div>


      <!-- LOGO -->
      <div class="col-span-2 row-span-2">
        <p class="mb-4">{{ "STOREPROFILE.extra.image" | translate }}</p>
        <div class="pointer image">
          <img src="{{ storeProfile?.image_url }}" alt="" (click)="onClickImageNewTab($event.target.src)">
        </div>
      </div>

    </div>



  </div>

</div>