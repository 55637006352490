import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-marketplace-offer-form',
  templateUrl: './marketplace-offer-form.component.html',
  styleUrls: ['./marketplace-offer-form.component.scss']
})
export class MarketplaceOfferFormComponent implements OnInit {
  reactiveForm: FormGroup
  constructor(private dialogRef: MatDialogRef<MarketplaceOfferFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,) {
    this.reactiveForm = this.createForm()
  }

  ngOnInit() {
    this.reactiveForm.patchValue({
      quotation_request_id: this.data.quotation_request_id,
    })
  }

  createForm() {
    return this.fb.group({
      total: ['', Validators.required],
      quotation_request_id: ['', Validators.required],
    })
  }

  onSubmit() {

    if (this.reactiveForm.valid) {
      let data = {
        "quotation": { ...this.reactiveForm.value }
      }
      this.dialogRef.close(data)
    } else {
      this.reactiveForm.markAllAsTouched();
    }

  }

  closeModal() {
    this.dialogRef.close(null);
  }

}
