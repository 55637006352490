import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  MalyLightBoxComponent } from './maly-light-box/maly-light-box.component';




@NgModule({
  declarations: [ MalyLightBoxComponent],
  imports: [
    CommonModule
  ],
  exports: [MalyLightBoxComponent]
})
export class MalyLightBoxModule { }
