import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appEvenNumber]'
})
export class EvenNumberDirective {

  constructor() { }
  @HostListener('keypress', ['$event'])

  evenNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && charCode != 45 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
