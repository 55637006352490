import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private profileSubject: Subject<string>;

  constructor() {
    this.profileSubject = new Subject<string>()
  }

  updateProfilePicUrl(picUrl: string): void {
    this.profileSubject.next(picUrl);
  }

  onProfilePicChange(): Observable<string> {
    return this.profileSubject.asObservable();
  }
}
