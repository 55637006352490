import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';

@Component({
  selector: 'app-driver-form',
  templateUrl: './driver-form.component.html',
  styleUrls: ['./driver-form.component.scss']
})
export class DriverFormComponent implements OnInit {

  selectedfileBack = null;
  reader: FileReader;
  readerIdentity: FileReader;
  selectedfileIdentity = null;
  readerFront: FileReader;
  selectedfileFont = null;
  selectedfileProfile = null;
  readerProfile: FileReader;
  max: any = null
  min: any = null
  startDateFormat: any = null
  isSubmit: boolean = false
  startIssueDateFormat: any = null

  driverForm: any = this.fb.group({
    name: [, Validators.required],
    phone: [, Validators.required],
    email: [,],
    password: [,],
    license_expiration_date: [, Validators.required],
    license_number: [, Validators.required],
    license_issue_date: [, Validators.required],
    attachments_attributes: [],
  });

  constructor(private dialogRef: MatDialogRef<DriverFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public translate: TranslateService,) {
    this.reader = new FileReader();
    this.readerIdentity = new FileReader();
    this.readerProfile = new FileReader();
    this.readerFront = new FileReader();
  }





  closeModal() {
    this.dialogRef.close();
  }

  addOneDay(date = new Date()) {
    date.setDate(date.getDate() + 1);

    return date;
  }

  ngOnInit() {
    this.min = this.addOneDay(new Date())
    if (this.data != null) {
      this.driverForm.patchValue({
        name: this.data.user?.name,
        phone: this.data.user?.phone,
        email: this.data.user?.email,
        license_number: this.data.user?.license_number,
        license_expiration_date: new Date(this.data?.user?.license_expiration_date),
        license_issue_date: new Date(this.data?.user?.license_issue_date),

      })
    }
  }

  OnFileSelectedBack(event) {
    this.selectedfileBack = <File>event.target.files[0];
    if (this.selectedfileBack) {
      const fileTypes = ['jpg', 'png', 'jpeg', 'gif'];
      let name = this.selectedfileBack.name;
      let array = name.split('.');
      let fileType = array[array.length - 1];
      if (fileTypes.includes(fileType)) {
        this.reader.readAsDataURL(this.selectedfileBack);
        this.reader.onload = () => { };
      } else {
        this.translate
          .get('MARKETPLACES.messages.mass_file_not_available', {
            value: 'mass_file_not_available',
          })
          .subscribe((res: string) => {
            const dialogRef = this.dialog.open(MessageFormComponent, {
              width: '400px',
              disableClose: true,
              data: { message: res, state: false },

            });
            dialogRef.afterClosed().subscribe(() => {
              this.selectedfileBack = {}
            });
          });
      }
    }
  }
  OnFileSelected2(event) {
    this.selectedfileIdentity = <File>event.target.files[0];
    if (this.selectedfileIdentity) {
      const fileTypes = ['jpg', 'png', 'jpeg', 'gif'];
      let name = this.selectedfileIdentity.name;
      let array = name.split('.');
      let fileType = array[array.length - 1];
      if (fileTypes.includes(fileType)) {
        this.readerIdentity.readAsDataURL(this.selectedfileIdentity);
        this.readerIdentity.onload = () => { };
      } else {
        this.translate
          .get('MARKETPLACES.messages.mass_file_not_available', {
            value: 'mass_file_not_available',
          })
          .subscribe((res: string) => {
            const dialogRef = this.dialog.open(MessageFormComponent, {
              width: '400px',
              disableClose: true,
              data: { message: res, state: false },

            });
            dialogRef.afterClosed().subscribe(() => {
              this.selectedfileIdentity = {}
            });
          });
      }
    }
  }

  OnFileSelected22(event) {
    this.selectedfileFont = <File>event.target.files[0];
    if (this.selectedfileFont) {
      const fileTypes = ['jpg', 'png', 'jpeg', 'gif'];
      let name = this.selectedfileFont.name;
      let array = name.split('.');
      let fileType = array[array.length - 1];
      if (fileTypes.includes(fileType)) {
        this.readerFront.readAsDataURL(this.selectedfileFont);
        this.readerFront.onload = () => { };
      } else {
        this.translate
          .get('MARKETPLACES.messages.mass_file_not_available', {
            value: 'mass_file_not_available',
          })
          .subscribe((res: string) => {
            const dialogRef = this.dialog.open(MessageFormComponent, {
              width: '400px',
              disableClose: true,
              data: { message: res, state: false },

            });
            dialogRef.afterClosed().subscribe(() => {
              this.selectedfileFont = {}
            });
          });
      }
    }
  }
  OnFileSelectedProfile(event) {
    this.selectedfileProfile = <File>event.target.files[0];
    if (this.selectedfileProfile) {
      const fileTypes = ['jpg', 'png', 'jpeg', 'gif'];
      let name = this.selectedfileProfile.name;
      let array = name.split('.');
      let fileType = array[array.length - 1];
      if (fileTypes.includes(fileType)) {
        this.readerProfile.readAsDataURL(this.selectedfileProfile);
        this.readerProfile.onload = () => { };
      } else {
        this.translate
          .get('MARKETPLACES.messages.mass_file_not_available', {
            value: 'mass_file_not_available',
          })
          .subscribe((res: string) => {
            const dialogRef = this.dialog.open(MessageFormComponent, {
              width: '400px',
              disableClose: true,
              data: { message: res, state: false },

            });
            dialogRef.afterClosed().subscribe(() => {
              this.selectedfileProfile = {}
            });
          });
      }
    }
  }

  infoFormFormatted() {
    this.startDateFormat = this.driverForm.get('license_expiration_date').value.getTime()
    this.startIssueDateFormat = this.driverForm.get('license_issue_date').value.getTime()

    this.driverForm.patchValue({
      license_expiration_date: (this.startDateFormat - this.driverForm?.get('license_expiration_date')?.value?.getTimezoneOffset() * 60000) / 1000,
      license_issue_date: (this.startIssueDateFormat - this.driverForm?.get('license_issue_date')?.value?.getTimezoneOffset() * 60000) / 1000,

    })
  }

  setDateBox(event, name) {
    if (name == 'startDate') {
      this.min = event.target.value
    } else {
      this.max = event.target.value
    }
  }

  createDriver() {
    this.isSubmit = true;
    if (this.driverForm.valid) {
      this.infoFormFormatted();
      let data = {
        user: {
          ...this.driverForm.value
        }
      }
      if (this.selectedfileBack != null || this.selectedfileIdentity != null || this.selectedfileProfile != null || this.selectedfileFont != null) {
        data.user.attachments_attributes = [
          {
            name: "identity",
            file: this.readerIdentity.result
          },
          {
            name: "driving_licence_front",
            file: this.readerFront.result
          },
          {
            name: "driving_licence_back",
            file: this.reader.result
          },
          {
            name: "profile",
            file: this.readerProfile.result
          },
        ]
      }
      this.dialogRef.close(data);
    }
  }


}
