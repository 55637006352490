import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { MapFormComponent } from 'src/app/main/components/map-form/map-form.component';
import { FileTypeService } from 'src/app/services/file-type.service';
import { StoreProfileService } from 'src/app/services/store-profile.service';

@Component({
  selector: 'app-update-store-profile',
  templateUrl: './update-store-profile.component.html',
  styleUrls: ['./update-store-profile.component.scss']
})
export class UpdateStoreProfileComponent implements OnInit {

  imageSrc: string = '';
  banner_image: string = ''
  cover_image: string = ''
  actionName: string;
  selectedfile = null;
  reader: FileReader;
  isSubmitted: boolean = false;
  isWholesale: boolean = false;
  isMarketplace: boolean = false;
  isStoreOnline: boolean = false;
  shopeProfileForm = new FormGroup({
    // address_line1: new FormControl(''),
    delivery_time: new FormControl(''),
    name_ar: new FormControl(''),
    name_en: new FormControl('', [Validators.pattern('^[a-zA-Z0-9 \-\']+')]),
    tax_number: new FormControl(''),
    email: new FormControl(''),
    shop_category_ids: new FormControl([]),
    phone: new FormControl(''),
    video_url: new FormControl(''),
    description_ar: new FormControl(''),
    description_en: new FormControl(''),
    business_category_ar: new FormControl(''),
    business_category_en: new FormControl('', [Validators.pattern('^[a-zA-Z0-9 \-\']+')]),
    lat: new FormControl(null),
    long: new FormControl(null),
    address_line1: new FormControl(null),
    attachment: new FormControl(),
  })
  shopCategories: any
  constructor(
    private dialogRef: MatDialogRef<UpdateStoreProfileComponent>,
    private fileTypeService: FileTypeService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private storeProfileService: StoreProfileService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    // this.getShopCategories()
    if (this.data != null) {
      this.isWholesale = this.data?.is_wholesale;
      this.isMarketplace = this.data.is_marketplace;
      this.isStoreOnline = this.data.online;

      this.shopeProfileForm.patchValue({
        delivery_time: this.data.delivery_time,
        name_ar: this.data.shop_names?.find(item => item.locale == 'ar').name,
        name_en: this.data.shop_names?.find(item => item.locale == 'en').name,
        tax_number: this.data.tax_number,
        email: this.data.email,
        description_en: this.data.shop_names?.find(item => item.locale == 'en').description,
        description_ar: this.data.shop_names?.find(item => item.locale == 'ar').description,
        business_category_en: this.data.shop_names?.find(item => item.locale == 'en').business_category,
        business_category_ar: this.data.shop_names?.find(item => item.locale == 'ar').business_category,
        phone: this.data.phone,
        attachment: this.data.attachment,
        lat: this.data.lat,
        long: this.data.long,
        video_url: this.data.video_url,
        address_line1: this.data.address?.address_line1
      });

      let shop_category_ids = []
      this.data.shop_categories.forEach(item => {
        shop_category_ids.push(item.id)
      });


      this.shopeProfileForm.patchValue({
        shop_category_ids: shop_category_ids,
      })
    }
  }

  getShopCategories() {
    let param = {
      all: true
    }
    this.storeProfileService.getShopCategories(param).subscribe((res: any) => {
      this.shopCategories = res.data.items
    })
  }

  OnFileProfileSelected(event) {
    let file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];

    if (this.fileTypeService.checkPattern(file)) {

      let reader = new FileReader();
      reader.onload = this.handleReaderLoadedProfile.bind(this);
      reader.readAsDataURL(file);

    } else {
      alert("This file Type is not supported");
    }
  }

  OnFileBannerSelected(event) {
    let file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];

    if (this.fileTypeService.checkPattern(file)) {
      let reader = new FileReader();
      reader.onload = this.handleReaderLoadedBanner.bind(this);
      reader.readAsDataURL(file);

    } else {
      alert("This file Type is not supported");
    }
  }

  OnFileCoverSelected(event) {
    let file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];

    if (this.fileTypeService.checkPattern(file)) {

      let reader = new FileReader();
      reader.onload = this.handleReaderLoadedCover.bind(this);
      reader.readAsDataURL(file);
    } else {
      alert("This file Type is not supported");
    }
  }

  handleReaderLoadedProfile(e) {
    let reader = e.target;
    this.imageSrc = reader.result;
  }
  handleReaderLoadedCover(e) {
    let reader = e.target;
    this.cover_image = reader.result;
  }
  handleReaderLoadedBanner(e) {
    let reader = e.target;
    this.banner_image = reader.result;
  }

  openMapForm() {
    const dialogRef = this.dialog.open(MapFormComponent, {
      width: '800px',
      data: this.data,
    });
    dialogRef.afterClosed().subscribe((locationData) => {
      if (locationData != undefined) {
        const address = locationData.address;

        if (address.length == 0) {
          this.translate
            .get('MAP.messages.location_error', { value: 'location_error' })
            .subscribe((res: string) => {
              this.dialog.open(MessageFormComponent, {
                width: '400px',
                disableClose: true,
                data: { message: res, state: false },
              });
            });
        } else {
          this.shopeProfileForm.patchValue({
            address_line1: address[0].formatted_address,
          });
        }
        this.shopeProfileForm.patchValue({
          lat: locationData.lat,
          long: locationData.lng,
        });
      }
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    let attachments = [
      { "name": 'profile', "file": this.imageSrc },
      { "name": 'cover', "file": this.cover_image },
      { "name": 'banner', "file": this.banner_image }
    ]
    let storeProfile = {
      shop: {
        ...this.shopeProfileForm.value,
        attachments: attachments,
        shop_names: [
          {
            locale: 'ar',
            business_category: this.shopeProfileForm.value.business_category_ar,
            description: this.shopeProfileForm.value.description_ar,
            name: this.shopeProfileForm.value.name_ar,
          },
          {
            locale: 'en',
            business_category: this.shopeProfileForm.value.business_category_en,
            description: this.shopeProfileForm.value.description_en,
            name: this.shopeProfileForm.value.name_en,
          },
        ]
      }
    }


    if (this.shopeProfileForm.valid) {
      this.dialogRef.close(storeProfile);
    }
  }
  closeModal() {
    this.dialogRef.close();
  }


}
