<div class="details-container">
  <div class="details-header flex flex-col md:flex-row justify-between p-4">
    <h2 class="mb-2"> {{ "PROFILE.extra.profile_info" | translate }}</h2>
    <div>

      <button class="btn btn-primary mb-2" (click)="openUserChangePassword()">
        {{ "PROFILE.action.change_password" | translate }}
      </button>

      <button class="btn btn-primary mb-2" (click)="updateProfileData()">
        {{ "PROFILE.action.edite_profile" | translate }}
      </button>
    </div>
  </div>

  <div class=" grid grid-cols-4 gap-3 p-4">
    <div class="col-span-4 md:col-span-2 row-span-2">
      <p>{{ "PROFILE.extra.name" | translate }} </p>
      <span class="tabel-content"> {{ profile?.name }}</span>
    </div>

    <div class="col-span-4 md:col-span-2 row-span-2">
      <p>{{ "PROFILE.extra.email" | translate }}</p>
      <span class="tabel-content">
        {{ profile?.email }}
      </span>
    </div>
    <div class="col-span-4 md:col-span-2 row-span-2 show-phone">
      <p>{{ "PROFILE.extra.phone" | translate }}</p>
      <span class="tabel-content">
        {{ profile?.phone }}
      </span>
    </div>

    <div class="col-span-2 row-span-2">
      <p class="mb-4">{{ "PROFILE.extra.image" | translate }}</p>
      <div class="pointer image">
        <img [defaultImage]="defaultImage" [lazyLoad]="profile?.image_url" (click)="showImage($event.target.src)">
      </div>
    </div>

  </div>

</div>