import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';
import { GetCurrencyService } from 'src/app/core/services/get-currency.service';
import { WorkersService } from 'src/app/services/workers.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';
import { WorkerFormComponent } from '../worker-form/worker-form.component';

@Component({
  selector: 'app-worker-details',
  templateUrl: './worker-details.component.html',
  styleUrls: ['./worker-details.component.scss']
})
export class WorkerDetailsComponent implements OnInit {
  params: any = {}
  page = 1
  tripId: any = null
  defaultImage = 'assets/image/uim_image-v.png'
  worker: any;
  url: any = '';
  loadingError = false;
  loading = false;
  massage: any
  Id: any;
  currency: any


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private workersService: WorkersService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private alertify: AlertifyService,
    private dialog: MatDialog,
    public languageService: ChangeLanguageService,
    private getCurrencyService: GetCurrencyService,


  ) {

    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map
        }
        if (this.params['tripId'] != null) {
          this.tripId = this.params['tripId']
        }
      }

    })
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(prams => {
      this.Id = prams['id'];
    });
    this.getCurrency()
    this.getDriver();
  }


  getDriver() {
    this.loading = true;
    this.workersService.getDriver(this.Id).subscribe((res) => {
      this.worker = res.data;
      this.loading = false;
      this.loadingError = false;
    }, error => {
      this.loadingError = true;
    });
  }


  onClickImageNewTab(event) {
    window.open(event);
  }

  getCurrency() {
    this.currency = this.getCurrencyService.getCurrency()
  }

  backTolist() {
    this.router.navigate(['workers'], { queryParamsHandling: 'preserve' });
  }

  update() {
    const dialogRef = this.dialog.open(WorkerFormComponent, {
      width: '90%',
      disableClose: true,
      data: { user: this.worker }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.workersService.updateDriver(this.worker.id, date).subscribe((res: any) => {
          this.getDriver()
        })
      }

    }, (error => {
      this.loading = false
      this.loadingError = true
    }));
  }

  backToDetails() {
    this.router.navigate([`/trips/details/${this.tripId}`], { queryParamsHandling: 'preserve' });
  }
}
