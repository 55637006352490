<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "PAYMENT_METHODS.extras.label" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="table">
            <table>
              <thead>
                <tr>
                  <th class="">
                    {{ "PAYMENT_METHODS.extras.name" | translate }}
                  </th>
                  <th class="" *ngIf="hasPaymentMethodRole"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let payment of paymentMethods">
                  <ng-container *ngIf="payment.code == 'cash_on_delivery'">
                    <td class="">
                      {{"PAYMENT_METHODS.extras.cash_on_delivery" | translate}}
                    </td>
                    <td class="" *ngIf="hasPaymentMethodRole">
                      <div class="flex items-center h-full" *ngIf="payment.disabled == false">
                        <input *ngIf="payment.id" class="form-check-input mr-2 ml-2" type="checkbox"
                          id="{{ payment.id }}" [checked]="payment.selected"
                          (change)=" changeShopPayment(payment.id, $event.target.checked)">
                        <label class="form-check-label ml-2 mr-2" for="flexCheckDefault">

                        </label>
                      </div>
                      <div *ngIf="payment.disabled == true">
                        {{ "STATE.not_enabled" | translate }}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="payment.code == 'online_payment'">
                    <td class="">
                      {{ "PAYMENT_METHODS.extras.online_payment" | translate }}
                    </td>
                    <td class="" *ngIf="hasPaymentMethodRole">
                      <div class="flex items-center h-full" *ngIf="payment.disabled == false">
                        <input *ngIf="payment.id" class="form-check-input mr-2 ml-2" type="checkbox"
                          [disabled]="!currentUser.shop.has_online_payment" id="{{ payment.id }}"
                          [checked]="payment.selected" (change)="changeShopPayment(payment.id, $event.target.checked)">
                        <label class="form-check-label ml-2 mr-2" for="flexCheckDefault">

                        </label>
                      </div>

                      <div *ngIf="payment.disabled == true">
                        {{ "STATE.not_enabled" | translate }}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="payment.code == 'bank_transfer'">
                    <td class="">
                      {{ "PAYMENT_METHODS.extras.bank_transfer" | translate }}
                    </td>
                    <td class="" *ngIf="hasPaymentMethodRole">
                      <div class="flex items-center h-full" *ngIf="payment.disabled == false">
                        <input *ngIf="payment.id" class="form-check-input mr-2 ml-2" type="checkbox"
                          id="{{ payment.id }}" [checked]="payment.selected"
                          (change)=" changeShopPayment(payment.id, $event.target.checked)">
                        <label class="form-check-label ml-2 mr-2" for="flexCheckDefault">

                        </label>
                      </div>
                      <div *ngIf="payment.disabled == true">
                        {{ "STATE.not_enabled" | translate }}
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer" *ngIf="hasPaymentMethodRole">
      <button class="btn btn-primary mb-2" (click)="updateShopPaymentMethods()">
        {{"PAYMENT_METHODS.actions.update_shop_payment_methods"| translate}}
      </button>
      <button class="btn btn-default mb-2" (click)="closeModal()">
        {{ "COMMON.cancel" | translate }}
      </button>
    </div>
  </div>
</div>