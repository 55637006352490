<div class="component-loader" (errorCallback)="logout()" [errorMessage]="'lOADER.errorMessage' | translate "
  component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
  [componentLoading]="loader">


  <div class="modal-wrapper">
    <i class='bx bx-x pointer text-3xl flex' (click)="closeModal()"></i>
    <!-- <div class="modal-header">
      <p class="w-full text-center">

      </p>
    </div> -->
    <div class="modal-content">
      <div class="modal-body mt-4 mb-4">
        <div class="row">
          <div class="col-md-12">
            <h1>{{ "LOGOUT.title" | translate }}</h1>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-4">
        <button class="btn btn-primary  mb-2" (click)="logout()">
          {{ "LOGOUT.logout" | translate }}
        </button>
        <button class="btn btn-default  mb-2" (click)="closeModal()">
          {{ "LOGOUT.close" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>