<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "MARKETPLACES.actions.create_new_Wallet" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <form [formGroup]="walletForm">
          <div class="row mb-2">




            <div class="col-md-6">
              <div class="form-group">
                {{ "MARKETPLACES.extras.amount" | translate }}
                <input class="form-control" type="text" formControlName="amount" appFloatNumber="$event" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group file">
                <label class="mb-2"> {{ "MARKETPLACES.extras.image_form" | translate }}</label>
                <div class="input-group mb-3">
                  <input class="form-control " accept=".png, .jpg, .jpeg, .gif" (change)="OnFileSelected($event)"
                    type="file" formControlName="attachment_attributes" />
                </div>

              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-2"> {{ "EMPLOYES.extra.note" | translate }}</label>
                <textarea class="form-control" cols="40" formControlName="note"></textarea>
              </div>
            </div>




          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="createQuotation()" [disabled]="walletForm.invalid">
        {{ "MARKETPLACES.actions.create_new_Wallet" | translate }}
      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>
