<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">{{ "STOREPROFILE.extra.incoming_order_categories" | translate }}</p>
  </div>
  <div class="modal-content">

    <form [formGroup]="shopeProfileForm">
      <div class="modal-body">
        <div class="row ">
          <div class="col-md-12">
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <th scope="col">
                      {{ "CATEGORIES.attributes.category_number.label" | translate }}
                    </th>

                    <th scope="col">
                      {{ "CATEGORIES.attributes.category_name.label" | translate }}
                    </th>
                    <th scope="col">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let category of shopCategories">
                    <td>
                      {{ category?.id }}
                    </td>
                    <td>
                      {{ category?.name }}
                    </td>
                    <td>
                      <span class="flex items-center h-full">
                        <input class="form-check-input mr-2 ml-2" type="checkbox" [id]="category.id"
                          [checked]="shop_category_ids.includes(category.id)"
                          (change)="changeCategory(category , $event.target.checked)">
                        <label [for]="category.id"></label>
                      </span>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="modal-footer">
      <button class="btn btn-primary mb-2" (click)="onSubmit()">
        {{ "COMMON.edit" | translate }}
      </button>
      <button class="btn btn-default mb-2" (click)="closeModal()">
        {{ "COMMON.cancel" | translate }}
      </button>
    </div>
  </div>
</div>