import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './services/authentication.service';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderInterceptorProvider } from './interceptors/loader.interceptor';
import { ArabicNumberPipe } from './pipes/arabic-number.pipe';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { FloatNumberDirective } from './directives/float-number.directive';
import { EvenNumberDirective } from './directives/even-number.directive';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from '../app.module';
import { SpinLoaderComponent } from './components/spin-loader/spin-loader.component';
import { RoundingnumberPipe } from './pipes/roundingnumber.pipe';
import { MessageFormComponent } from './components/message-form/message-form.component';
import { ConfirmationFormComponent } from './components/confirmation-form/confirmation-form.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NumberPhoneDirective } from './directives/number-phone.directive';
import { ProfitRatioDirective } from './directives/profit-ratio.directive';
import { TimestampToDatePipe } from './pipes/timestamp-to-date.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimePipe } from './pipes/time.pipe';
import { ReasonConfirmationComponent } from './components/reason-confirmation/reason-confirmation.component';
import { nl2brPipe } from './pipes/nl2br.pipe';

@NgModule({
  declarations: [
    LoaderComponent,
    ArabicNumberPipe,
    RoundingnumberPipe,
    NumberOnlyDirective,
    FloatNumberDirective,
    EvenNumberDirective,
    SpinLoaderComponent,
    PaginationComponent,
    MessageFormComponent,
    ConfirmationFormComponent,
    NumberPhoneDirective,
    ProfitRatioDirective,
    TimestampToDatePipe,
    TimePipe,
    ReasonConfirmationComponent,
    nl2brPipe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgxPaginationModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    LoaderComponent,
    ArabicNumberPipe,
    RoundingnumberPipe,
    NumberOnlyDirective,
    FloatNumberDirective,
    PaginationComponent,
    SpinLoaderComponent,
    EvenNumberDirective,
    NumberPhoneDirective,
    ProfitRatioDirective,
    TimestampToDatePipe,
    TimePipe,
    nl2brPipe
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthenticationService,
        LoaderInterceptorProvider
      ]
    };
  }

  static forChild(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: []
    };
  }
}
