<div class=" pagination-list flex flex-col md:flex-row justify-between items-center">
  <ul>
    <li class="page-item">
      <a class="page-link ">
        {{'PAGINATION.show_page'|translate}} {{current_page}} {{'PAGINATION.from'|translate}} {{total_pages}}
        {{'PAGINATION.page'|translate}} {{total_items}} {{'PAGINATION.items'|translate}}
      </a>
    </li>
  </ul>
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item" [class.disabled]="current_page == 1">
        <a class="page-link pointer" (click)="pageChanged(current_page - 1)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
          {{'PAGINATION.previous_label'|translate}}
        </a>
      </li>
      <ng-container *ngFor="let page of pages | slice:0:1;">
        <li class="page-item">
          <a (click)="pageChanged(page.number)" class="page-link pointer"
            [class.active]="page.active">{{page.number}}</a>
        </li>
        <li class="page-item mt-1" *ngIf=" current_page != total_pages">
          ...
        </li>

      </ng-container>
      <ng-container *ngFor="let page of pages | slice:start:end;">
        <li class="page-item">
          <a (click)="pageChanged(page.number)" class="page-link pointer"
            [class.active]="page.active">{{page.number}}</a>
        </li>
      </ng-container>
      <ng-container *ngIf="total_pages > end && current_page != total_pages">
        <ng-container *ngFor="let page of pages | slice:total_pages-1:total_pages;">
          <li class="page-item mt-1">
            ...
          </li>
          <li class="page-item">
            <a (click)="pageChanged(page.number)" class="page-link pointer"
              [class.active]="page.active">{{page.number}}</a>
          </li>
        </ng-container>
      </ng-container>


      <li class="page-item" [class.disabled]="current_page == total_pages">
        <a class="page-link pointer" (click)="pageChanged(current_page + 1)" aria-label="Next">
          {{'PAGINATION.next_label'|translate}}
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</div>