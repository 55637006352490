<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "MARKETPLACES.actions.offer" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <form [formGroup]="reactiveForm">
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="form-group">
                {{ "MARKETPLACES.actions.offer_price" | translate }}
                <input class="form-control" type="number" formControlName="total" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary mb-2" type="submit" (click)="onSubmit()" [disabled]="reactiveForm.invalid">
        {{ "COMMON.send" | translate }}
      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>