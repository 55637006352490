import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PetrolStationDetailsComponent } from './petrol-station-details/petrol-station-details.component';
import { PetrolStationsComponent } from './petrol-stations/petrol-stations.component';

const routes: Routes = [
  { path: '', component: PetrolStationsComponent, data: { title: 'PetrolStations' } },
  { path: 'details/:id', component: PetrolStationDetailsComponent, data: { title: 'PetrolStations Details' } },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PetrolStationsRoutingModule { }
