import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MarketplacesRoutingModule } from './marketplaces-routing.module';
import { MarketplaceListComponent } from './marketplace-list/marketplace-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPaginationModule } from 'ngx-pagination';
import { CoreModule } from 'src/app/core/core.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { MalyLightBoxModule } from 'src/app/core/maly-light-box/maly-light-box.module';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from 'src/app/app.module';
import { MarketplaceDetailsComponent } from './marketplace-details/marketplace-details.component';
import { MarketplaceOutgoingListComponent } from './marketplace-outgoing-list/marketplace-outgoing-list.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MarketplaceOutgoingDetailsComponent } from './marketplace-outgoing-details/marketplace-outgoing-details.component';
import { MatTimepickerModule } from 'mat-timepicker';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { MarketplaceOfferFormComponent } from './marketplace-offer-form/marketplace-offer-form.component';
// import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatIconModule } from '@angular/material/icon';
import { DeliveryManagementDetailsComponent } from './delivery-management-details/delivery-management-details.component';
import { DeliveryManagementListComponent } from './delivery-management-list/delivery-management-list.component';
import { MarketplaceOutgoingCurrentComponent } from './marketplace-outgoing-current/marketplace-outgoing-current.component';
import { MarketplaceOutgoingHistoryComponent } from './marketplace-outgoing-history/marketplace-outgoing-history.component';
import { ContractFormComponent } from './contract-form/contract-form.component';

@NgModule({
  declarations: [MarketplaceListComponent, MarketplaceDetailsComponent,
    MarketplaceOutgoingListComponent,
    MarketplaceOutgoingDetailsComponent,
    DeliveryManagementListComponent,
    DeliveryManagementDetailsComponent,
    MarketplaceOfferFormComponent,
    MarketplaceOutgoingCurrentComponent,
    MarketplaceOutgoingHistoryComponent,
    ContractFormComponent
  ],
  imports: [
    CommonModule,
    MarketplacesRoutingModule,
    FormsModule,
    MatDialogModule,
    NgxPaginationModule,
    CoreModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    LoaderModule,
    MalyLightBoxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    LazyLoadImageModule,
    MatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    Ng2TelInputModule,
    MatIconModule,
    MatTimepickerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ]
})
export class MarketplacesModule { }
