import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';
import { GetCurrencyService } from 'src/app/core/services/get-currency.service';
import { DriversService } from 'src/app/services/drivers.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';
import { AssignDriverComponent } from '../assign-driver/assign-driver.component';
import { TruckFormComponent } from '../truck-form/truck-form.component';

@Component({
  selector: 'app-truck-details',
  templateUrl: './truck-details.component.html',
  styleUrls: ['./truck-details.component.scss']
})
export class TruckDetailsComponent implements OnInit {

  defaultImage = 'assets/image/uim_image-v.png'
  truck: any;
  url: any = '';
  loadingError = false;
  loading = false;
  massage: any
  Id: any;
  currency: any
  tripId: any = null
  params: any = {}
  page = 1
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private driversService: DriversService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private alertify: AlertifyService,
    private dialog: MatDialog,
    public languageService: ChangeLanguageService,
    private getCurrencyService: GetCurrencyService,


  ) {

    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map
        }
        if (this.params['tripId'] != null) {
          this.tripId = this.params['tripId']
        }
      }

    })

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(prams => {
      this.Id = prams['id'];
    });
    this.getCurrency()
    this.getTruck();
  }


  getTruck() {
    this.loading = true;
    this.driversService.getTruck(this.Id).subscribe((res) => {
      this.truck = res.data;
      this.loading = false;
      this.loadingError = false;
    }, error => {
      this.loadingError = true;
    });
  }


  onClickImageNewTab(event) {
    window.open(event);
  }

  getCurrency() {
    this.currency = this.getCurrencyService.getCurrency()
  }

  backTolist() {
    this.router.navigate(['trucks'], { queryParamsHandling: 'preserve' });
  }


  update() {
    const dialogRef = this.dialog.open(TruckFormComponent, {
      width: '90%',
      disableClose: true,
      data: { truck: this.truck }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.driversService.updateTruck(this.truck.id, date).subscribe((res: any) => {
          this.getTruck()
        })
      }

    }, (error => {
      this.loading = false
      this.loadingError = true
    }));
  }


  assignDriver() {
    const dialogRef = this.dialog.open(AssignDriverComponent, {
      width: '50%',
      disableClose: true,
      data: { truck: this.truck }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.driversService.assignDriver(this.truck.id, date).subscribe((res: any) => {
          this.getTruck()
        })
      }

    }, (error => {
      this.loading = false
      this.loadingError = true
    }));
  }

  openDriverDetails(diverId) {
    this.router.navigate([]).then(result => { window.open(`/drivers/details/${diverId}`, '_blank'); });
  }

  backToDetails() {
    this.router.navigate([`/trips/details/${this.tripId}`], { queryParamsHandling: 'preserve' });
  }
}
