import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anp'
})
export class ArabicNumberPipe implements PipeTransform {

  numbersObject: { [x: string]: string } = {
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
    '٠': '0',
  };

  transform(n: number | string): string {
    if (n === null || n === undefined) return '';
    n = n + ''; // to make it a string if it was a number 
    let newString = '';
    for (let i = 0; i < n.length; i++) {
      if (this.numbersObject[n.charAt(i)])
        newString += this.numbersObject[n.charAt(i)];
      else
        newString += n.charAt(i);
    }
    return newString;

  }

}
