import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { AuthenticationService } from 'src/app/core/services/authentication.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changeHiddenPassword = "password";
  changeHiddenCurrentPassword = "password";

  changePasswordForm: FormGroup | any = new FormGroup({
    current_password: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])

  })
  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authenticationService: AuthenticationService,
    private alertify: AlertifyService,

  ) { }

  ngOnInit(): void {
  }


  changeCurrentPasswordHidden() {
    if (this.changeHiddenCurrentPassword == 'password') {
      this.changeHiddenCurrentPassword = 'text'
    } else {
      this.changeHiddenCurrentPassword = 'password'
    }
  }
  changePasswordHidden() {
    if (this.changeHiddenPassword == 'password') {
      this.changeHiddenPassword = 'text'
    } else {
      this.changeHiddenPassword = 'password'
    }
  }
  onSubmit() {
    let userData: any = {
      current_password: this.changePasswordForm.get("current_password").value,
      password: this.changePasswordForm.get("password").value
    }
    if (this.changePasswordForm.valid) {
      this.authenticationService.changePassword(userData).subscribe(
        () => {
          this.dialogRef.close();
          this.authenticationService.logout()
        },
        (err) => this.alertify.error(err?.error?.error)
      );
    }
  }
  closeModal() {
    this.dialogRef.close();
  }

}
