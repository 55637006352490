import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WorkersService {
    apiUrl = `superadmins/api/v1`;
    constructor(private http: HttpClient) { }

    getDrivers(params) {
        return this.http.get(baseUrl + `${this.apiUrl}/users`, { params });
    }
    createDrivers(model: any) {
        return this.http.post<any>(baseUrl + `${this.apiUrl}/users`, model);
    }
    getDriver(id): Observable<any> {
        return this.http.get(baseUrl + `${this.apiUrl}/users/` + id);
    }

    updateDriver(driverId, model) {
        return this.http.put(baseUrl + `${this.apiUrl}/users/${driverId}`, model);
    }

    getTrucks(params) {
        return this.http.get(baseUrl + `${this.apiUrl}/rides`, { params });
    }


    createTrucks(model: any) {
        return this.http.post<any>(baseUrl + `${this.apiUrl}/rides`, model);
    }
    getTruck(id): Observable<any> {
        return this.http.get(baseUrl + `${this.apiUrl}/rides/` + id);
    }

    updateTruck(driverId, model) {
        return this.http.put(baseUrl + `${this.apiUrl}/rides/${driverId}`, model);
    }
    assignDriver(driverId, model) {
        return this.http.put(baseUrl + `${this.apiUrl}/rides/${driverId}/assign_driver`, model);
    }

}
