<div class="table-container">



  <div class="flex flex-col md:flex-row justify-between pl-4 pr-4 pt-4">
    <h2 class="mb-2"> {{ "HEADER.DATA_MANAGEMENT.petrol_stations" | translate }}</h2>
    <div class="flex justify-end pl-4 pr-4 pb-4">
      <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="createPetrolStations()"> {{
        "DRIVERS.actions.add_new_station" | translate }} </button>
      <!-- <button class="btn btn-default ml-2 mr-2" (click)="clearFilter()"><i class='bx bx-revision'></i></button>
      <button class="btn btn-default" [class.btn-open-filter]="isFilter" (click)="openFilter()">
        <i class='bx bx-filter-alt'></i>
      </button> -->
    </div>

  </div>



  <!-- <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap "
    [class.open-filter]="isFilter">




  </div> -->




  <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
  </app-pagination>



  <div class="component-loader" (errorCallback)="getPetrolStations()"
    [errorMessage]="'lOADER.errorMessage' | translate " component-loader [componentError]="loadingError"
    [loadingMessage]="'lOADER.loadingMessage' | translate" [componentLoading]="loading">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ "DRIVERS.extras.name" | translate }}
          </th>


          <th scope="col">
            {{ "USER.extras.image" | translate }}
          </th>
          <th scope="col">
            {{ "SUPPLIER.extras.address_info" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.fuels" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.worker_add" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.actions" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.station_active" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of petrol_stations | paginate: paginationConfig">


          <td class="pointer weight-label" (click)="goToDetails(item.id)">
            {{ item?.name }}
          </td>

          <td>
            <img class="w-10" [defaultImage]="defaultImage" [lazyLoad]="item?.image_url" />
          </td>

          <td>
            <div class="col-span-4 md:col-span-4 row-span-2">
              <p> {{ "WAREHOUSES.attributes.address.label" | translate }} <i class='bx bx-copy share-io pointer'
                  (click)="copyAddress(item)"></i></p>
              <span class="tabel-content"> {{ item?.address_line1 }}</span>
            </div>
          </td>

          <td>
            {{ item?.petrol_types }}
          </td>

          <td *ngIf="item?.user == null">
            <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="assignDriver(item)">
              {{ "COMMON.assign" | translate }} </button>
          </td>

          <td class="">
            <i class='bx bx-edit-alt edit pointer mx-2' (click)="update(item)" id="update"></i>
          </td>

          <td>
            <!-- <div _ngcontent-dvj-c175="" class="form-switch"><input _ngcontent-dvj-c175="" type="checkbox"
                class="form-check-input ng-pristine ng-valid ng-touched" id="list-914" value="item?.active"></div> -->

            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="list-{{item.id}}" [ngModel]="item?.active"
                (change)="releaseToDrivers(item.id, $event.target['checked'])">
            </div>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
</app-pagination>
