<div class="modal-wrapper">
  <i class='bx bx-x pointer text-3xl flex' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center"></p>

  </div>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="flex flex-middle justify-center">
            <img class="mr-2" width="40" src=".../.../../../../../../../assets/image/warning.svg">
          </div>
          <h2 class="text-center">{{ message }}</h2>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-4">
      <button class="btn btn-primary mb-2" (click)="done()" *ngIf="data?.type == 'serials'">
        {{ "COMMON.confirm" | translate }}
      </button>
      <button class="btn btn-default mb-2" (click)="closeModal()">
        {{ "COMMON.close" | translate }}
      </button>
    </div>
  </div>
</div>