import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PetrolStationsRoutingModule } from './petrol-stations-routing.module';
import { PetrolStationDetailsComponent } from './petrol-station-details/petrol-station-details.component';
import { PetrolStationsComponent } from './petrol-stations/petrol-stations.component';
import { PetrolStationFormComponent } from './petrol-station-form/petrol-station-form.component';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { createTranslateLoader } from 'src/app/app.module';
import { CoreModule } from 'src/app/core/core.module';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { MalyLightBoxModule } from 'src/app/core/maly-light-box/maly-light-box.module';
import { AssignDriverComponent } from './assign-driver/assign-driver.component';


@NgModule({
  declarations: [
    PetrolStationDetailsComponent,
    PetrolStationsComponent,
    PetrolStationFormComponent,
    AssignDriverComponent
  ],
  imports: [
    CommonModule,
    PetrolStationsRoutingModule,
    FormsModule,
    MatDialogModule,
    NgxPaginationModule,
    CoreModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    LoaderModule,
    MalyLightBoxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgOtpInputModule,
    NgbModule,
    LazyLoadImageModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ]
})
export class PetrolStationsModule { }
