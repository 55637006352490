<div class="component-loader" (errorCallback)="getMarketplace()" [errorMessage]="'lOADER.errorMessage' | translate "
  component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
  [componentLoading]="loading">



  <!--details content-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "MARKETPLACES.extras.marketplace_details" | translate }}</h2>
      <div>

        <button class="btn btn-default mb-2" (click)="backTolist()">
          {{ "MARKETPLACES.actions.back_to_shipping_management" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>

      </div>
    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-3 md:col-span-2 row-span-2 ">
        <p> {{ "MARKETPLACES.extras.marketplace_number" | translate }}</p>
        <span class="tabel-content"> {{ marketplace?.id }}</span>
      </div>
      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "QUOTION.attributes.status.label" | translate
          }}</p>
        <span class="tabel-content"> {{ ("QUOTION.attributes.status." + marketplace?.status) | translate }}</span>

      </div>
      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "QUOTION.attributes.created_at.label" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.created_at | date :'medium' }}</span>
      </div>


      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.start_date" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.contract_from | date }}</span>
      </div>

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.expires_at" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.contract_to | date }}</span>
      </div>

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.ex_at" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.expires_at | date }}</span>
      </div>



    </div>

  </div>

  <!--details content-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.actions.trip_details" | translate }}</h2>
      <div>

      </div>
    </div>


    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.number_of_shippers" | translate
          }}</p>
        <span class="tabel-content"> {{marketplace?.quotation_request?.number_of_shippers}}</span>
      </div>
      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.weight" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.weight }}</span>
      </div>
    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">

      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.repetition" | translate
          }}</p>
        <span class="tabel-content"> {{ ("MARKETPLACES.extras." + marketplace?.quotation_request?.repetition) |
          translate }} </span>
      </div>

      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.repetitionTime" | translate
          }}</p>
        <span class="tabel-content">{{ ("MARKETPLACES.extras." + marketplace?.quotation_request?.repetition_time) |
          translate }} </span>
      </div>

    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">

      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.ride_type" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.ride_type }}</span>
      </div>
      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.package_type" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.package_type }}</span>
      </div>
    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.direction" | translate
          }}</p>
        <span class="tabel-content"> {{ ("MARKETPLACES.extras." + marketplace?.quotation_request?.direction) | translate
          }}</span>
      </div>


      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.pickup_time" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.pickup_time }}</span>
      </div>
      <div class="col-span-3 md:col-span-2 row-span-2" *ngIf="marketplace?.quotation_request?.direction == 'two_way'">
        <p> {{
          "MARKETPLACES.extras.return_time" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.delivery_time }}</span>
      </div>
    </div>

    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.address_line1" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.pickup_address?.address_line1 }}</span>
      </div>

      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.city_id" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.pickup_address?.city_name }}</span>
      </div>
    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.address_line1to" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.delivery_address?.address_line1 }}</span>
      </div>

      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.city_id_to" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.quotation_request?.delivery_address?.city_name }}</span>
      </div>
    </div>

  </div>



</div>