<app-loader></app-loader>

<app-header></app-header>
<div class="relative">
  <div class="sidebar" [class]="sideBarService.sideBarSubject |async">
    <app-sidebar></app-sidebar>
  </div>

  <section class="main-section">
    <router-outlet></router-outlet>
  </section>
</div>