<div class="table-container">



  <div class="flex flex-col md:flex-row justify-between pl-4 pr-4 pt-4">
    <h2 class="mb-2">{{ "HEADER.DATA_MANAGEMENT.marketplace_incoming" | translate }}</h2>
    <div class="flex justify-end pl-4 pr-4 pb-4">
      <button class="btn btn-default ml-2 mr-2" (click)="clearFilter()"><i class='bx bx-revision'></i></button>
      <button class="btn btn-default" [class.btn-open-filter]="isFilter" (click)="openFilter()">
        <i class='bx bx-filter-alt'></i>
      </button>
    </div>

  </div>



  <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap "
    [class.open-filter]="isFilter">

    <select (change)="selectStatusMarketPlaces($event.target.value)" class="form-select ml-2  mb-2"
      [(ngModel)]="status">
      <option selected value="all">{{ "QUOTION.attributes.status.all" | translate }}
      </option>
      <option value="pending">{{ "HEADER.DATA_MANAGEMENT.marketplace_outgoing_current" | translate }}
      </option>
      <option value="history">{{ "HEADER.DATA_MANAGEMENT.marketplace_outgoing_history" | translate }}</option>

    </select>

    <!-- <select (change)="selectShopCategoryIds($event.target.value)" class="form-select ml-2  mb-2"
            [(ngModel)]="shop_category_ids">
            <option selected value="all">{{ "CATEGORIES.extras.categories" | translate }}
            </option>
            <ng-container *ngFor="let shopCategory of shopCategories">
                <option [value]="shopCategory.id">{{ shopCategory.name }}
                </option>
            </ng-container>
        </select> -->

  </div>




  <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
  </app-pagination>



  <div class="component-loader" (errorCallback)="getMarketPlaces()" [errorMessage]="'lOADER.errorMessage' | translate "
    component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
    [componentLoading]="loading">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ "MARKETPLACES.extras.marketplace_number" | translate }}
          </th>
          <th scope="col">
            {{ "MARKETPLACES.actions.marketplace_name" | translate }}
          </th>
          <th scope="col">
            {{
            "QUOTION.attributes.created_at.label"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "MARKETPLACES.extras.number_of_shippers"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "MARKETPLACES.extras.start_date"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "MARKETPLACES.extras.expires_at"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "QUOTION.attributes.status.label"
            | translate
            }}
          </th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let marketplace of marketplaces | paginate: paginationConfig">
          <td class="pointer weight-label" (click)="goToDetails(marketplace.id)">
            {{ marketplace?.id }}
          </td>
          <td class="">
            <div>
              {{ marketplace?.user.name }}
            </div>
          </td>
          <td class="">
            <div>
              {{ marketplace?.created_at | date :'medium' }}
            </div>
          </td>
          <td class="">
            <div>
              {{ marketplace?.number_of_shippers }}
            </div>
          </td>
          <td class="">
            <div>
              {{ marketplace?.contract_from | date }}
            </div>
          </td>
          <td class="">
            <div>
              {{ marketplace?.contract_to | date }}
            </div>
          </td>

          <td>
            {{ ("MARKETPLACES.status." + marketplace?.status) | translate }}
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
</app-pagination>