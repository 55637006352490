import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkersComponent } from './workers/workers.component';
import { WorkerDetailsComponent } from './worker-details/worker-details.component';

const routes: Routes = [
  { path: '', component: WorkersComponent, data: { title: 'Workers' } },
  { path: 'details/:id', component: WorkerDetailsComponent, data: { title: 'Workers Details' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkerRoutingModule { }
