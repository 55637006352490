<div class="auth flex items-center justify-center w-full ">
  <div class="mt-7">
    <div class="text-center">
      <a class="navbar-brand m-0" href="#">
        <img width="150" src="../../../assets/image/Group 22.png" />
      </a>
    </div>
    <div class="ptb-10 mt-5 text-center">

    </div>
    <div class="col-md-12 flex justify-center mt-2 text-4xl mb-4">
      <label>
        {{ "LOGIN.header.stay_to_active" | translate }}
      </label>
    </div>
    <div class="col-md-12 flex justify-center mt-2 text-xl" style="width: 50vw;">
      <div class="form-group mb-6 col-md-6">
        <button class="btn btn btn-primary btn-block text-center spec-submit w-full" (click)="openWhatsApp()">
          {{ "LOGIN.header.contact_head" | translate }}
        </button>
        <button class="btn btn btn-primary-t2 btn-block text-center spec-submit w-full mt-2" (click)="goLogin()">
          {{ "LOGIN.header.label" | translate }}
        </button>
      </div>

    </div>
  </div>
</div>