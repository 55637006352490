import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appProfitRatio]'
})
export class ProfitRatioDirective {

  @HostListener('keypress', ['$event'])

  profitRationNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 46 || charCode > 57) || charCode == 47) {
      return false;
    }
    return true;

  }
}
