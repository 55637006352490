import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampToDate',
})
export class TimestampToDatePipe implements PipeTransform {
  transform(timestamp: any): any {
 
    if (timestamp === null || timestamp === undefined || timestamp === '') return ''

    let timeZone = Math.abs(new Date().getTimezoneOffset()) * 60000;
    return new Date((timeZone + parseInt(timestamp) )).toISOString().replace('Z', '');
     
   
  }
}
