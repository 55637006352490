import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TripDetailsComponent } from './trip-details/trip-details.component';
import { TripsComponent } from './trips/trips.component';

const routes: Routes = [
  { path: '', component: TripsComponent, data: { title: 'Trips' } },
  { path: 'details/:id', component: TripDetailsComponent, data: { title: 'Trips Details' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TripsRoutingModule { }
