import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarketplacesService {

  constructor(private http: HttpClient) { }

  apiUrl = `users/api/v1`;

  getMarketPlacesIncoming(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrl + '/quotation_requests', { params: params });
  }
  getMarketPlacesOutgoing(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrl + '/quotation_requests', { params: params });
  }
  getAllDeliveryManagement(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrl + '/quotations', { params: params });
  }
  getDeliveryManagement(id): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrl}/quotations/` + id);
  }
  getMarketplace(id): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrl}/quotation_requests/` + id);
  }
  createOffer(model: any) {
    return this.http.post(baseUrl + `${this.apiUrl}/quotations`, model);
  }
  acceptQuotation(model: any) {
    return this.http.post<any>(baseUrl + `${this.apiUrl}/quotations/pay`, model);
  }
  releaseQuotation(id: any, model: any) {
    return this.http.put<any>(baseUrl + `${this.apiUrl}/quotations/${id}/release_quotation`, model);
  }

  createContract(model: any) {
    return this.http.post(baseUrl + `${this.apiUrl}/quotation_requests`, model);
  }

}
