import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuotationsService {

  constructor(private http: HttpClient) { }

  apiUrl = `shops/api/v1/quotations`;

  getRide(params = {}): Observable<any> {
    return this.http.get(baseUrl + `users/api/v1/ride_types`, { params: params });
  }
  getCities(params = {}): Observable<any> {
    return this.http.get(baseUrl + `users/api/v1/cities`, { params: params });
  }
  getPackageType(params = {}): Observable<any> {
    return this.http.get(baseUrl + `users/api/v1/package_types`, { params: params });
  }

  getQuotations(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrl, { params: params });
  }
  createQuotation(model: any) {
    return this.http.post<any>(baseUrl + this.apiUrl, model);
  }
  createNewQuotation(model: any) {
    return this.http.post<any>(baseUrl + 'shops/api/v1/quotation_workshops', model);
  }
  getQuotation(id): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrl}/` + id);
  }
  updateQuotation(id: number, model: any) {
    return this.http.put<any>(baseUrl + `${this.apiUrl}/` + id, model);
  }
  deleteQuotation(id): Observable<any> {
    return this.http.delete(baseUrl + `${this.apiUrl}/` + id);
  }
  assignNewUser(id, data) {
    return this.http.put(baseUrl + `${this.apiUrl}/${id}`, data);
  }
  cancelQuotation(id: number, model: any) {
    return this.http.post(baseUrl + `${this.apiUrl}/${id}/cancel`, model);
  }
  downloadQuotationPdf(id): Observable<any> {
    const headers = new HttpHeaders();

    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'arraybuffer';
      withCredentials?: boolean;
    } = {
      responseType: 'arraybuffer'
    };
    return this.http.get(baseUrl + `${this.apiUrl}/` + id + '.pdf', { ...options });
  }

  releaseQuotation(id, data) {
    return this.http.put(baseUrl + "shops/api/v1/quotation_workshops/" + `${id}` + "/release_quotation", data);
  }




}
