import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';
import { TripsService } from 'src/app/services/trips.service';
import { UpdateTripstatusComponent } from './update-tripstatus/update-tripstatus.component';
import { Globals } from 'src/app/core/globals';
import { AssignTruckComponent } from '../assign-truck/assign-truck.component';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { AddCommentComponent } from '../add-comment/add-comment.component';
import { ShipmentRescheduleRequestsComponent } from './shipment-reschedule-requests/shipment-reschedule-requests.component';
import { ConfirmationFormComponent } from 'src/app/core/components/confirmation-form/confirmation-form.component';
import { AddExtraChargesComponent } from './add-extra-charges/add-extra-charges.component';

@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.scss']
})
export class TripDetailsComponent implements OnInit {
  params: any = {}
  page = 1
  shipmentPage = 1
  defaultImage = 'assets/image/uim_image-v.png'
  trip: any;
  url: any = '';
  loadingError = false;
  loading = false;
  massage: any
  tripId: any;
  currency: any
  currentUser: any
  marketplaceId: any = null
  shipmentRescheduleRequest: any = []
  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'trips',
  }

  pendingStatus = ['pending', 'arrived_at_source', 'shipment_loading', 'processing', 'on_way', 'arrived_at_destination', 'shipment_deloading', 'delivered']
  arrivedAtSourceStatus = ['arrived_at_source', 'shipment_loading', 'processing', 'on_way', 'arrived_at_destination', 'shipment_deloading', 'delivered']
  shipmentLoadingStatus = ['shipment_loading', 'processing', 'on_way', 'arrived_at_destination', 'shipment_deloading', 'delivered']
  processingStatus = ['processing', 'on_way', 'arrived_at_destination', 'shipment_deloading', 'delivered']
  onWayStatus = ['on_way', 'arrived_at_destination', 'shipment_deloading', 'delivered']
  arrivedAtDestinationStatus = ['arrived_at_destination', 'shipment_deloading', 'delivered']
  shipmentDeloadingStatus = ['shipment_deloading', 'delivered']
  deliveredStatus = ['delivered']

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private tripsService: TripsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private alertify: AlertifyService,
    private globals: Globals,
    private dialog: MatDialog,
    public languageService: ChangeLanguageService,

  ) {
    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map,
        }
        if (this.params['page'] != null) {
          this.page = this.params['page']
        }
        if (this.params['marketplaceId'] != null) {
          this.marketplaceId = this.params['marketplaceId']
        }
      }
    })
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.globals.currentUser || null)
    this.activatedRoute.params.subscribe(prams => {
      this.tripId = prams['id'];
      this.params.tripId = this.tripId
    });
    this.getTrip();
    this.getShipmentRescheduleRequest()
  }


  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'trips',
    }
  }


  onPageChangeMarketPlaces(pageNo: any) {
    this.shipmentPage = pageNo
    this.getShipmentRescheduleRequest()
  }

  accept(item) {
    this.translate
      .get('DRIVERS.massage.accept', {
        value: 'accept',
      })
      .subscribe((res: string) => {
        const dialogRef = this.dialog.open(ConfirmationFormComponent, {
          width: '50%',
          disableClose: true,
          data: { component: 'acceptQuotation', message: res }
        });
        dialogRef.afterClosed().subscribe((mood) => {
          if (mood != false) {
            this.tripsService.accept(item.id, this.trip.id, {}).subscribe((res: any) => {
              this.getTrip()
              this.getShipmentRescheduleRequest()
            }, (error: any) => {
              if (error.error.code == 400) {
                this.alertify.error(error.error.error);
              }
            })
          }

        });
      });

  }
  refuse(item) {
    this.translate
      .get('DRIVERS.massage.refuse', {
        value: 'refuse',
      })
      .subscribe((res: string) => {
        const dialogRef = this.dialog.open(ConfirmationFormComponent, {
          width: '50%',
          disableClose: true,
          data: { component: 'acceptQuotation', message: res }
        });
        dialogRef.afterClosed().subscribe((mood) => {
          if (mood != false) {
            this.tripsService.refuse(item.id, this.trip.id, {}).subscribe((res: any) => {
              this.getTrip()
              this.getShipmentRescheduleRequest()
            }, (error: any) => {
              if (error.error.code == 400) {
                this.alertify.error(error.error.error);
              }
            })
          }

        });
      });
  }
  addExtraCharges(item) {
    const dialogRef = this.dialog.open(AddExtraChargesComponent, {
      width: '500px',
      disableClose: true,
      data: { trip: this.trip }
    });
    dialogRef.afterClosed().subscribe((dataValue: any) => {
      if (dataValue != null) {
        this.tripsService.addExtraCharges(item.id, this.trip.id, dataValue).subscribe((res) => {
          this.getTrip();
          this.getShipmentRescheduleRequest();
        }, (error) => {
          this.translate.get('MARKETPLACES.messages.error_message', { value: 'error_message' }).subscribe((res: string) => {
            this.alertify.error(res);
          });
        });
      }
    });
  }

  getShipmentRescheduleRequest() {
    // this.loading = true;
    const params = {
      page: this.shipmentPage,
    }
    this.tripsService.getShipmentRescheduleRequest(this.tripId, params).subscribe((res) => {
      this.shipmentRescheduleRequest = res.data.items;
      this.updatePagination(res.data.pagination)
      this.loading = false;
      this.loadingError = false;
    }, error => {
      this.loadingError = true;
    });
  }

  getTrip() {
    this.loading = true;
    this.tripsService.getTrip(this.tripId).subscribe((res) => {
      this.trip = res.data;
      this.loading = false;
      this.loadingError = false;
    }, error => {
      this.loadingError = true;
    });
  }


  onClickImageNewTab(event) {
    if (event != null) {
      window.open(event);
    }
  }

  shipmentRescheduleRequests() {
    const dialogRef = this.dialog.open(ShipmentRescheduleRequestsComponent, {
      width: '500px',
      disableClose: true,
      data: { trip: this.trip }
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data != null) {
        this.loading = true;
        this.tripsService.shipmentRescheduleRequests(this.trip.id, data).subscribe((res) => {
          this.getShipmentRescheduleRequest();
        }, (error) => {
          this.translate.get('MARKETPLACES.messages.error_message', { value: 'error_message' }).subscribe((res: string) => {
            this.alertify.error(res);
          });
        });
      }
    });
  }

  backTolist() {
    delete this.params.marketplaceId
    delete this.params.tripId
    this.router.navigate(['trips'], { queryParamsHandling: 'preserve' });
  }

  updateTrip(name?: string) {
    if (name != 'cancelled') {
      if (this.trip.status == 'pending') {
        this.trip.statusNext = 'arrived_at_source'
      }
      else if (this.trip.status == 'arrived_at_source') {
        this.trip.statusNext = 'shipment_loading'
      }
      else if (this.trip.status == 'shipment_loading') {
        this.trip.statusNext = 'processing'
      }
      else if (this.trip.status == 'processing') {
        this.trip.statusNext = 'on_way'
      }
      else if (this.trip.status == 'on_way') {
        this.trip.statusNext = 'arrived_at_destination'
      }
      else if (this.trip.status == 'arrived_at_destination') {
        this.trip.statusNext = 'shipment_deloading'
      }
      else if (this.trip.status == 'shipment_deloading') {
        this.trip.statusNext = 'delivered'
      }

    } else {
      this.trip.statusNext = 'cancelled'
    }
    const dialogRef = this.dialog.open(UpdateTripstatusComponent, {
      width: '500px',
      disableClose: true,
      data: { trip: this.trip }
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data != null) {
        this.loading = true;
        this.tripsService.updateTrip(this.trip.id, data).subscribe((res) => {
          this.getTrip();
        }, (error) => {
          this.translate.get('MARKETPLACES.messages.error_message', { value: 'error_message' }).subscribe((res: string) => {
            this.alertify.error(res);
          });
        });
      }
    });
  }


  addComment() {
    const dialogRef = this.dialog.open(AddCommentComponent, {
      width: '50%',
      disableClose: true,
      data: { trip: this.trip }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.tripsService.addComment(this.trip.id, date).subscribe((res: any) => {
          this.getTrip()
        }, (error => {
          this.loading = false
          this.dialog.open(MessageFormComponent, {
            width: '400px',
            disableClose: true,
            data: { message: error.error.error, state: false },
          })
        }))
      }

    });
  }


  assignTruck() {
    const dialogRef = this.dialog.open(AssignTruckComponent, {
      width: '50%',
      disableClose: true,
      data: { trip: this.trip }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.tripsService.assignTruck(this.trip.id, date).subscribe((res: any) => {
          this.getTrip()
        }, (error => {
          this.loading = false
          this.dialog.open(MessageFormComponent, {
            width: '400px',
            disableClose: true,
            data: { message: error.error.error, state: false },
          })
        }))
      }

    });
  }


  goToDetails(id, routeString: string) {
    delete this.params.marketplaceId
    delete this.params.tripId
    this.params = {
      ...this.params,
      page: this.page,
    }
    this.router.navigate([`/${routeString}/${id}`], { queryParams: this.params })
  }

}
