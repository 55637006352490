import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DriversService } from 'src/app/services/drivers.service';

@Component({
  selector: 'app-update-tripstatus',
  templateUrl: './update-tripstatus.component.html',
  styleUrls: ['./update-tripstatus.component.scss']
})
export class UpdateTripstatusComponent implements OnInit {

  contractShipmentForm: any = this.fb.group({
    status: [],
  });

  constructor(private dialogRef: MatDialogRef<UpdateTripstatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private driversService: DriversService,
    private fb: FormBuilder,
    public translate: TranslateService,) {

  }



  closeModal() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.contractShipmentForm.patchValue({
      status: this.data.trip?.statusNext
    })
  }

  assignDriver() {
    if (this.contractShipmentForm.valid) {
      let data = {
        contract_shipment: {
          ...this.contractShipmentForm.value
        }
      }

      this.dialogRef.close(data);
    }
  }


}
