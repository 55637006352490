import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private loaderService: LoaderService,
    private auth: AuthenticationService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(

      catchError(error => {
        if (error.status === 401) {
          this.auth.logout();
        }
        this.loaderService.hide();
        if ((error)) {
          return throwError(error);
        }
        if (error.status === 401 || error.status === 404 || error.status == 0) {
          this.auth.logout();
          return throwError(error.statusText);

        }


        if (error instanceof HttpErrorResponse) {
          const applicationError = error.headers.get('Application-Error');
          if (applicationError) {
            return throwError(applicationError);
          }
          const serverError = error.error;
          let modalStateErrors = '';
          if (typeof serverError.errors === 'object') {
            for (const key in serverError.errors) {
              if (serverError.errors.hasOwnProperty(key)) {
                if (serverError.errors[key]) {
                  modalStateErrors += serverError.errors[key] + '\n';
                }
              }
            }
          }
          return throwError(modalStateErrors || serverError || 'Server Error');
        }
      })
    );
  }

}

export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true
};

