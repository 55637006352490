<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center"> {{ "COMMON.share" | translate }} </p>
  </div>

  <div class="modal-content">

    <div class="modal-body text-center">
      <div class="row">
        <!-- To show shared string -->
        <!-- <div class="col-md-12 text-center">
          <input class="form-control" dir="ltr" readonly type="text" [(ngModel)]="shareURL" />
        </div> -->

        <div class="col-md-12 mt-3">
          <share-buttons theme="material-dark" class="pb-2"
            [include]="['telegram','messenger','twitter','whatsapp','email']" [url]="shareURL" [show]="5">
          </share-buttons>
        </div>

        <div class="col-md-12 text-center flex justify-center">
          <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="shareURL"
            cssClass="qrcodeshadow"></ngx-qrcode>
        </div>

        <div class="col-md-12 text-center">
          <button class="btn btn-outline-warning mt-3 w-full"
            (click)="copyLink()">{{'STOREPROFILE.extra.copy_link'|translate}}</button>
        </div>

      </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-default mb-2" (click)="closeModal()">
        {{ "COMMON.close" | translate }}
      </button>
    </div>

  </div>
</div>