import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';
import { GetCurrencyService } from 'src/app/core/services/get-currency.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';
import { WalletsService } from 'src/app/services/wallets.service';

@Component({
  selector: 'app-wallet-details',
  templateUrl: './wallet-details.component.html',
  styleUrls: ['./wallet-details.component.scss']
})
export class WalletDetailsComponent implements OnInit {

  defaultImage = 'assets/image/uim_image-v.png'
  wallet: any;
  url: any = '';
  loadingError = false;
  loading = false;
  massage: any
  walletId: any;
  currency: any


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private walletsService: WalletsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userPermission: UserPermissionService,
    public translate: TranslateService,
    private alertify: AlertifyService,
    private dialog: MatDialog,
    public languageService: ChangeLanguageService,
    private getCurrencyService: GetCurrencyService,


  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(prams => {
      this.walletId = prams['id'];
    });
    this.getCurrency()
    this.geWallet();
  }


  geWallet() {
    this.loading = true;
    this.walletsService.geWallet(this.walletId).subscribe((res) => {
      this.wallet = res.data;
      this.loading = false;
      this.loadingError = false;
    }, error => {
      this.loadingError = true;
    });
  }


  onClickImageNewTab(event) {
    window.open(event);
  }

  getCurrency() {
    this.currency = this.getCurrencyService.getCurrency()
  }

  backTolist() {
    this.router.navigate(['wallet'], { queryParamsHandling: 'preserve' });
  }
}
