import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { TripsService } from 'src/app/services/trips.service';
import { AssignTruckComponent } from '../assign-truck/assign-truck.component';
import { Globals } from 'src/app/core/globals';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { AddCommentComponent } from '../add-comment/add-comment.component';
import {
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators'
import { Subject, Subscription } from 'rxjs';
@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss']
})
export class TripsComponent implements OnInit {

  defaultImage = 'assets/image/uim_image-v.png'
  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'trips',
  }
  isFilter: boolean = false
  currentUser: any
  trips: any
  loadingError = false
  loading = false
  params: any = {}
  page = 1
  startDateParam: any
  endDateParam: any
  startDate: any = null
  endDate: any = null
  max: any = null
  min: any = null
  currentDate = new Date()
  requestSubscription: Subscription
  private searchDecouncer$: Subject<string> = new Subject();
  key: any
  status: any
  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private tripsService: TripsService,
    private alertify: AlertifyService,
    private router: Router,
    private globals: Globals,
    private activatedRoute: ActivatedRoute,
  ) {
    this.setupSearchDebouncer()
  }

  setStartDate() {
    this.startDate =
      (this.currentDate.getTime() -
        this.currentDate.getTimezoneOffset() * 60000) /
      1000

    this.min = this.currentDate
    this.startDateParam = this.currentDate
    let from = { from: this.startDate }
    this.params = {
      ...this.params,
      ...from
    }
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.globals.currentUser || null)

    this.currentDate = new Date()
    this.setStartDate()
    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map,
        }
        if (this.params['from'] != null) {
          this.startDateParam = new Date(this.params['from'] * 1000)
        }
        if (this.params['to'] != null) {
          this.endDateParam = new Date(this.params['to'] * 1000)
        }
        if (this.params['page'] != null) {
          this.page = this.params['page']
        }
        if (this.params['status']) {
          this.status = this.params['status']
        }
      } else {
        delete this.params.status
        this.status = 'all'
      }
      this.getTrips()
    })
  }

  ngOnDestroy() {
    this.searchDecouncer$.unsubscribe()
  }

  getTrips() {
    this.loading = true
    const params = {
      ...this.params,
      page: this.page,
    }
    this.addQueryString(params)
    this.requestSubscription = this.tripsService.getTrips(params).subscribe((res: any) => {
      this.trips = res.data.items
      this.loading = false
      this.loadingError = false
      this.updatePagination(res.data.pagination)
    })
  }
  selectStatusTrips(statusName: string) {
    if (statusName != 'all') {
      this.params.status = statusName
    } else {
      delete this.params.status
      this.status = 'all'
    }
    this.page = 1
    this.getTrips()

  }

  setDateBox(event, name) {
    this.page = 1
    if (name == 'startDate') {
      this.startDate =
        (event.target.value.getTime() -
          event.target.value.getTimezoneOffset() * 60000) /
        1000
      this.min = event.target.value
    } else {
      this.endDate =
        (event.target.value.getTime() -
          event.target.value.getTimezoneOffset() * 60000) /
        1000
      this.max = event.target.value
    }

    if (this.startDate != null && this.endDate != null) {
      this.params = {
        ...this.params,
        from: this.startDate,
        to: this.endDate,
      }
    } else if (this.startDate != null) {
      this.params = {
        ...this.params,
        from: this.startDate,
      }
    } else {
      this.params = {
        ...this.params,
        to: this.endDate,
      }
    }

    this.getTrips()
  }

  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'trips',
    }
  }





  onPageChangeMarketPlaces(pageNo: any) {
    this.page = pageNo
    this.getTrips()
  }


  goToDetails(id) {
    this.params = {
      ...this.params,
      page: this.page,
    }
    this.router.navigate([`/trips/details/${id}`], { queryParams: this.params })
  }


  openFilter() {
    this.isFilter = !this.isFilter
  }


  addQueryString(param) {
    let queryParams: string = param
    let params = new HttpParams()
    for (const [key, value] of Object.entries(queryParams)) {
      params = params.append(key, value)
    }

    window.history.replaceState({}, '', `/trips?${params.toString()}`)
  }

  clearFilter() {
    window.history.replaceState({}, '', `/trips`)
    delete this.params.status
    this.status = 'all'
    this.startDateParam = ''
    this.endDateParam = ''
    delete this.params.quotation_request_id
    delete this.params.to
    delete this.params.from
    this.page = 1
    this.getTrips()
  }


  addComment(trip: any) {
    const dialogRef = this.dialog.open(AddCommentComponent, {
      width: '50%',
      disableClose: true,
      data: { trip: trip }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.tripsService.addComment(trip.id, date).subscribe((res: any) => {
          this.getTrips()
        }, (error => {
          this.loading = false
          this.dialog.open(MessageFormComponent, {
            width: '400px',
            disableClose: true,
            data: { message: error.error.error, state: false },
          })
        }))
      }

    });
  }

  assignTruck(trip: any) {
    const dialogRef = this.dialog.open(AssignTruckComponent, {
      width: '50%',
      disableClose: true,
      data: { trip: trip }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.tripsService.assignTruck(trip.id, date).subscribe((res: any) => {
          this.getTrips()
        }, (error => {
          this.loading = false
          this.dialog.open(MessageFormComponent, {
            width: '400px',
            disableClose: true,
            data: { message: error.error.error, state: false },
          })
        }))
      }

    });
  }


  search(event: any, key: string) {
    let value = event.target.value
    this.requestSubscription.unsubscribe()
    this.searchDecouncer$.next(value);
    this.key = key

  }

  setupSearchDebouncer() {
    this.searchDecouncer$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe((term: string) => {
      if (term.length > 0) {
        this.params[this.key] = term
      } else {
        delete this.params[this.key]
      }
      this.getTrips()
    });
  }

}
