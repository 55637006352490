<div class="auth flex items-center justify-center w-full ">
  <div class="mt-7 content">
    <div class="text-center">
      <a class="navbar-brand m-0" href="#">
        <img width="150" src="../../../assets/image/b2pay.svg" />
      </a>
    </div>
    <div class="ptb-10 mt-5 text-center">

    </div>
    <form [formGroup]="FormRegister" (ngSubmit)="onSubmit()">
      <div class="row m-2 ">
        <div class="col-md-12 flex justify-center">
          <div class="form-group mb-6 col-md-6" [class.has-error]="
              (!FormRegister.get('phone').valid &&
              FormRegister.get('phone').touched)|| hasPhoneNumberError
            ">
            <label class="mb-2">{{
              "LOGIN.phone.label" | translate
              }}</label>
            <div class="input-group " *ngIf="onlyCountries.length >0">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <img src="../../../assets/image/uiw_mobile.svg" alt="">
                </span>
              </div>
              <input class="form-control" type="text" appNumberPhone="$event" ng2TelInput
                [ng2TelInputOptions]="{initialCountry: initialCountry ,separateDialCode:true ,onlyCountries:onlyCountries }"
                (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event)"
                (countryChange)="onCountryChange($event)" required />
            </div>
            <span class="text-error size-small mt-2 validator" *ngIf=" hasPhoneNumberError">
              {{ "USER.attributes.mobile.error_msg" | translate }}
            </span>
          </div>
        </div>
        <div class="col-md-12 flex justify-center">
          <div class="form-group mb-6 col-md-6" [class.has-error]="
              !FormRegister.get('name').valid &&
              FormRegister.get('name').touched
            ">
            <label class="mb-2">{{
              "LOGIN.name.label" | translate
              }}</label>
            <div class="input-group flex">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <img src="../../../assets/image/bx_user.svg" alt="">
                </span>
              </div>
              <input class="form-control  " type="text" formControlName="name" required
                [placeholder]="'LOGIN.name.placeholder' | translate" required aria-describedby="basic-addon1" />

            </div>

            <span class="text-error size-small mt-2 validator">
              {{ "LOGIN.name.error" | translate }}
            </span>
          </div>
        </div>
        <div class="col-md-12 flex justify-center">
          <div class="form-group mb-6 col-md-6" [class.has-error]="
              !FormRegister.get('shop_name').valid &&
              FormRegister.get('shop_name').touched
            ">
            <label class="mb-2">{{
              "LOGIN.company_name.label" | translate
              }}</label>
            <div class="input-group flex">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <img src="../../../assets/image/carbon_store.svg" alt="">
                </span>
              </div>
              <input class="form-control  " type="text" placeholder="company_name"
                [placeholder]="'LOGIN.company_name.placeholder' | translate" formControlName="shop_name" required
                required aria-describedby="basic-addon1" />
            </div>

            <span class="text-error size-small mt-2 validator">
              {{ "LOGIN.company_name.error" | translate }}
            </span>
          </div>
        </div>
        <div class="col-md-12 flex justify-center">
          <div class="form-group mb-6 col-md-6" [class.has-error]="
              !FormRegister.get('password').valid &&
              FormRegister.get('password').touched
            ">
            <label class="mb-2">
              {{ "LOGIN.password.label" | translate }}
            </label>

            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <img src="../../../assets/image/clarity_lock-line.svg" alt="">
                </span>
              </div>

              <input class="form-control " [type]="'password'" name="password"
                [placeholder]="'LOGIN.password.placeholder' | translate" formControlName="password"
                autocomplete="password" required aria-describedby="basic-addon1" />
            </div>



            <span class="text-error size-small mt-2 validator">
              {{ "LOGIN.password.error" | translate }}
            </span>
          </div>
        </div>
        <div class="col-md-12 flex justify-center">
          <div class="form-group mb-6 col-md-6">
            <button [disabled]="!FormRegister.valid || submittingRegister || hasPhoneNumberError" type="submit"
              class="btn btn btn-primary btn-block text-center w-full">
              {{ "LOGIN.header.create_account" | translate }}
            </button>

          </div>

        </div>
        <div class="col-md-12 flex justify-center mt-2 text-xl">
          <label>
            {{ "LOGIN.header.text" | translate }}
            <span class="login_span pointer" (click)="goLogin()">
              {{ "LOGIN.header.label" | translate }}
            </span>
          </label>
        </div>
        <div class="col-md-12 flex justify-center mt-2 text-xl">
          <ng-container>
            <label class="mx-1">
              {{ "LOGIN.header.language" | translate }}
            </label>
            <div ngbDropdown>
              <i class='bx bx-world edit pointer' id="dropdownBasic1" ngbDropdownToggle></i>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="menue-open">
                <button type="button" ngbDropdownItem class="sm:text-sm" (click)="changeLanguage()">{{
                  "HEADER.USER.system_language" |
                  translate }}</button>
              </div>
            </div>

          </ng-container>
        </div>
      </div>

    </form>
  </div>
</div>