<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center"> {{ "ZATCA.new_certificate" | translate }}</p>
  </div>
  <div class="modal-content">

    <form>
      <div class="modal-body text-center">
        <div class="flex flex-col justify-between items-center ltr">
          <h1>{{"ZATCA.otp_hint" | translate}}</h1>
          <div class="col-md-12 text-center">
            <button class="btn btn-outline-warning mt-3 mb-6 w-full"
              (click)="openZatcaPortal()">{{'ZATCA.visit_portal_link'|translate}}</button>
          </div>
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:5, allowNumbersOnly:true}">
          </ng-otp-input>

        </div>
      </div>
    </form>

    <div class="modal-footer">
      <button class="btn btn-default mb-2" (click)="closeModal()">
        {{ "COMMON.cancel" | translate }}
      </button>
    </div>
  </div>
</div>