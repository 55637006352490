<div class="table-container">



  <div class="flex flex-col md:flex-row justify-between pl-4 pr-4 pt-4">
    <h2 class="mb-2"> {{ "HEADER.DATA_MANAGEMENT.trips" | translate }}</h2>
    <div class="flex justify-end pl-4 pr-4 pb-4">

      <button class="btn btn-default ml-2 mr-2" (click)="clearFilter()"><i class='bx bx-revision'></i></button>
      <button class="btn btn-default" [class.btn-open-filter]="isFilter" (click)="openFilter()">
        <i class='bx bx-filter-alt'></i>
      </button>
    </div>

  </div>



  <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap "
    [class.open-filter]="isFilter">


    <div class="form-group ml-2 mb-2 relative">
      <input [matDatepicker]="pickId" name="started-date" class="form-control date"
        placeholder="{{ 'SALSE_REPORTS.extras.start_date' | translate }}" (dateInput)="setDateBox($event,'startDate')"
        [max]="max" [(ngModel)]="startDateParam" />
      <mat-datepicker #pickId></mat-datepicker>
      <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId">
      </mat-datepicker-toggle>
    </div>

    <div class="form-group ml-2 mb-2 relative">
      <input [matDatepicker]="pickId2" name="started-date" class="form-control date"
        placeholder="{{ 'SALSE_REPORTS.extras.end_date' | translate }}" (dateInput)="setDateBox($event,'endDate')"
        [min]="min" [(ngModel)]="endDateParam" />
      <mat-datepicker #pickId2></mat-datepicker>
      <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId2">
      </mat-datepicker-toggle>
    </div>
    <div class="form-group ml-2 mb-2 relative">
      <input class="form-control" type="text" id="search-box" (input)="search($event , 'quotation_request_id')"
        [placeholder]="'MARKETPLACES.extras.number'|translate" [(ngModel)]="params.quotation_request_id" />
    </div>
    <select (change)="selectStatusTrips($event.target.value)" class="form-select ml-2  mb-2" [(ngModel)]="status">
      <option selected value="all">{{ "QUOTION.attributes.status.all" | translate }}</option>
      <option value="pending">{{ "DRIVERS.extras.statusTrip.pending" | translate }}</option>
      <option value="arrived_at_source">{{ "DRIVERS.extras.statusTrip.arrived_at_source" | translate }}</option>
      <option value="shipment_loading">{{ "DRIVERS.extras.statusTrip.shipment_loading" | translate }}</option>
      <option value="processing">{{ "DRIVERS.extras.statusTrip.processing" | translate }}</option>
      <option value="on_way">{{ "DRIVERS.extras.statusTrip.on_way" | translate }}</option>
      <option value="arrived_at_destination">{{ "DRIVERS.extras.statusTrip.arrived_at_destination" | translate }}
      </option>
      <option value="shipment_deloading">{{ "DRIVERS.extras.statusTrip.shipment_deloading" | translate }}</option>
      <option value="delivered">{{ "DRIVERS.extras.statusTrip.delivered" | translate }}</option>
      <option value="reschedule_pending">{{ "DRIVERS.extras.statusTrip.reschedule_pending" | translate }}</option>


    </select>
  </div>




  <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
  </app-pagination>



  <div class="component-loader" (errorCallback)="getTrips()" [errorMessage]="'lOADER.errorMessage' | translate "
    component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
    [componentLoading]="loading">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ "TRIPS.trip_number" | translate }}
          </th>

          <th scope="col">
            {{ "TRIPS.contract_number" | translate }}
          </th>
          <th scope="col">
            {{ "WHOLESALE.extras.shipping_agency" | translate }}
          </th>

          <th scope="col" *ngIf="currentUser.user_category.key == 'shipping_company'">
            {{ "TRIPS.customer_name" | translate }}
          </th>

          <!-- <th scope="col" *ngIf="currentUser.user_category.key == 'company'">
            {{ "TRIPS.shipping_company_name" | translate }}
          </th> -->

          <th scope="col">
            {{ "DRIVERS.extras.shipment_date" | translate }}
          </th>

          <th scope="col">
            {{ "DRIVERS.extras.status" | translate }}
          </th>

          <th scope="col" *ngIf="currentUser.user_category.key == 'shipping_company'">
            {{ "TRIPS.assigned_truck" | translate }}
          </th>

        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of trips | paginate: paginationConfig">
          <td class="pointer weight-label" (click)="goToDetails(item.id)">
            {{ item?.tracking_reference }}
          </td>

          <td class=" weight-label">
            {{ item?.quotation_request?.id }}
          </td>
          <td class=" weight-label">
            {{ item?.quotation.user.name}}
          </td>

          <td class=" weight-label" *ngIf="currentUser.user_category.key == 'shipping_company'">
            {{ item?.quotation_request?.user?.name }}
          </td>

          <!-- <td class=" weight-label" *ngIf="currentUser.user_category.key == 'company'">
            {{ item?.quotation_request?.user?.name }}
          </td> -->

          <td>
            {{ item?.shipment_date | date }}
          </td>
          <td>
            {{ ("DRIVERS.extras.statusTrip." + item?.status) | translate }}
          </td>

          <td class="" *ngIf="currentUser.user_category.key == 'shipping_company' ">
            <button class="btn btn-primary ml-2 mr-2 mb-2"
              *ngIf="item?.status != 'cancelled' && item?.status != 'delivered'  && item?.truck == null"
              (click)="assignTruck(item)"> {{
              "DRIVERS.actions.assignTruck" | translate }} </button>
            <!-- <button class="btn btn-primary ml-2 mr-2 mb-2"
              *ngIf="item?.status != 'cancelled' && item?.status != 'delivered'  && item?.truck == null"
              (click)="addComment(item)"> {{
              "DRIVERS.actions.addComment" | translate }} </button> -->

            <p *ngIf="item?.status != 'cancelled' && item?.status != 'delivered'  && item?.truck != null">
              {{item?.truck?.number}} - {{item?.truck?.user_driver?.name}}</p>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
</app-pagination>