<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      <ng-container *ngIf="data == null">
        {{ "DRIVERS.actions.add_driver"| translate }}
      </ng-container>
      <ng-container *ngIf="data != null">
        {{ "DRIVERS.actions.edit_Driver" | translate }}
      </ng-container>

    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <form [formGroup]="driverForm">
          <div class="row mb-2">

            <div class="col-md-6" [class.has-error]="!driverForm.get('name').valid && isSubmit">
              <div class="form-group">
                {{ "DRIVERS.extras.name" | translate }}
                <input class="form-control" type="text" formControlName="name" />
              </div>
            </div>
            <div class="col-md-6" [class.has-error]="!driverForm.get('phone').valid && isSubmit">
              <div class="form-group">
                {{ "DRIVERS.extras.phone" | translate }}
                <input class="form-control" type="text" formControlName="phone" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                {{ "DRIVERS.extras.password" | translate }}
                <input class="form-control" type="text" formControlName="password" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                {{ "DRIVERS.extras.email" | translate }}
                <input class="form-control" type="text" formControlName="email" />
              </div>
            </div>



            <div class="col-md-6">
              <div class="form-group file">
                <label class="mb-2"> {{ "DRIVERS.extras.profile" | translate }}</label>
                <div class="input-group mb-3">
                  <input class="form-control " accept=".png, .jpg, .jpeg, .gif" (change)="OnFileSelectedProfile($event)"
                    type="file" />
                </div>

              </div>
            </div>

            <div class="col-md-6" [class.has-error]="!driverForm.get('license_number').valid && isSubmit">
              <div class="form-group">
                {{ "DRIVERS.extras.license_number" | translate }}
                <input class="form-control" type="text" formControlName="license_number" />
              </div>
            </div>



            <div class="col-md-6">
              <div class="form-group file">
                <label class="mb-2"> {{ "DRIVERS.extras.identity" | translate }}</label>
                <div class="input-group mb-3">
                  <input class="form-control " accept=".png, .jpg, .jpeg, .gif" (change)="OnFileSelected2($event)"
                    type="file" />
                </div>

              </div>
            </div>

            <div class="col-md-6" [class.has-error]="!driverForm.get('license_issue_date').valid && isSubmit">
              <div class="form-group">
                <label class="mb-2"> {{ "DRIVERS.extras.license_issue_date" | translate }}</label>
                <div class="form-group relative">
                  <input [matDatepicker]="pickId" name="started-date" class="form-control h-10 date"
                    placeholder="{{ 'DRIVERS.extras.license_issue_date' | translate }}" [max]="max"
                    (dateInput)="setDateBox($event,'startDate')" formControlName="license_issue_date" />
                  <mat-datepicker #pickId></mat-datepicker>
                  <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId">
                  </mat-datepicker-toggle>

                </div>
              </div>
            </div>

            <div class="col-md-6" [class.has-error]="!driverForm.get('license_expiration_date').valid && isSubmit">
              <div class="form-group">
                <label class="mb-2"> {{ "DRIVERS.extras.license_expiration_date" | translate }}</label>
                <div class="form-group relative">
                  <input [matDatepicker]="pickId2" name="started-date" class="form-control h-10 date"
                    (dateInput)="setDateBox($event,'endDate')"
                    placeholder="{{ 'DRIVERS.extras.license_expiration_date' | translate }}" [min]="min"
                    formControlName="license_expiration_date" />
                  <mat-datepicker #pickId2></mat-datepicker>
                  <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId2">
                  </mat-datepicker-toggle>

                </div>
              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group file">
                <label class="mb-2"> {{ "DRIVERS.extras.front" | translate }}</label>
                <div class="input-group mb-3">
                  <input class="form-control " accept=".png, .jpg, .jpeg, .gif" (change)="OnFileSelected22($event)"
                    type="file" />
                </div>

              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group file">
                <label class="mb-2"> {{ "DRIVERS.extras.back" | translate }}</label>
                <div class="input-group mb-3">
                  <input class="form-control " accept=".png, .jpg, .jpeg, .gif" (change)="OnFileSelectedBack($event)"
                    type="file" />
                </div>

              </div>
            </div>


          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="createDriver()">
        <ng-container *ngIf="data == null">
          {{ "DRIVERS.actions.add_driver"| translate }}
        </ng-container>
        <ng-container *ngIf="data != null">
          {{ "DRIVERS.actions.edit_Driver" | translate }}
        </ng-container>
      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>
