import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoreProfileComponent } from './store-profile.component';


const routes: Routes = [
  { path: '', component: StoreProfileComponent, data: { title: 'Store Profile' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StoreProfileRoutingModule { }
