import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';
import { GetCurrencyService } from 'src/app/core/services/get-currency.service';
import { DriversService } from 'src/app/services/drivers.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';
import { AssignDriverComponent } from '../assign-driver/assign-driver.component';
import { PetrolStationFormComponent } from '../petrol-station-form/petrol-station-form.component';

@Component({
  selector: 'app-petrol-station-details',
  templateUrl: './petrol-station-details.component.html',
  styleUrls: ['./petrol-station-details.component.scss']
})
export class PetrolStationDetailsComponent implements OnInit {

  defaultImage = 'assets/image/uim_image-v.png'
  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'wallet',
  }
  workers: any;
  petrol_station: any;
  url: any = '';
  loadingError = false;
  loading = false;
  massage: any
  Id: any;
  currency: any
  tripId: any = null
  params: any = {}
  page = 1
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private driversService: DriversService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private alertify: AlertifyService,
    private dialog: MatDialog,
    public languageService: ChangeLanguageService,
    private getCurrencyService: GetCurrencyService,


  ) {

    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map
        }
        if (this.params['tripId'] != null) {
          this.tripId = this.params['tripId']
        }
      }

    })

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(prams => {
      this.Id = prams['id'];
    });
    this.getCurrency()
    this.getPetrolStation();
    this.getStationWorkers();
  }


  getPetrolStation() {
    this.loading = true;
    this.driversService.getPetrolStation(this.Id).subscribe((res) => {
      this.petrol_station = res.data;
      this.loading = false;
      this.loadingError = false;
    }, error => {
      this.loadingError = true;
    });
  }


  onClickImageNewTab(event) {
    window.open(event);
  }

  getCurrency() {
    this.currency = this.getCurrencyService.getCurrency()
  }

  backTolist() {
    this.router.navigate(['petrol-stations'], { queryParamsHandling: 'preserve' });
  }
  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'wallets',
    }
  }


  onPageChangeMarketPlaces(pageNo: any) {
    this.page = pageNo
    this.getPetrolStation()
    this.getStationWorkers();
  }


  update() {
    const dialogRef = this.dialog.open(PetrolStationFormComponent, {
      width: '90%',
      disableClose: true,
      data: { petrol_station: this.petrol_station }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.driversService.updatePetrolStation(this.petrol_station.id, date).subscribe((res: any) => {
          this.getPetrolStation()
          this.getStationWorkers();
        })
      }

    }, (error => {
      this.loading = false
      this.loadingError = true
    }));
  }


  assignDriver() {
    const dialogRef = this.dialog.open(AssignDriverComponent, {
      width: '50%',
      disableClose: true,
      data: { petrol_station: this.petrol_station }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.driversService.assignWorker(this.petrol_station.id, date).subscribe((res: any) => {
          this.getPetrolStation()
          this.getStationWorkers();
        })
      }

    }, (error => {
      this.loading = false
      this.loadingError = true
    }));
  }

  openDriverDetails(diverId) {
    this.router.navigate([]).then(result => { window.open(`/drivers/details/${diverId}`, '_blank'); });
  }

  backToDetails() {
    this.router.navigate([`/trips/details/${this.tripId}`], { queryParamsHandling: 'preserve' });
  }

  copyAddress(petrol_station: any) {
    let message = `https://www.google.com/maps/place/${petrol_station.lat},${petrol_station.long}`
    let selBox = this.document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = message;
    this.document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(selBox);

    this.translate
      .get('ORDERS.messages.copy_address', { value: 'copy_address' })
      .subscribe((res: string) => {
        this.alertify.success(res);
      });
  }

  getStationWorkers() {
    const params = {
      all: true,
      petrol_station_id: this.Id
    }
    this.driversService.getStationWorkers(params).subscribe((res: any) => {
      this.workers = res.data.items
    }, (error) => { })
  }
}
