<div class="modal-wrapper">
  <i class='bx bx-x pointer text-3xl flex' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center"></p>
  </div>
  <div class="modal-content">
    <div class="modal-body flex justify-center">
      <div class="row">
        <div class="col-md-12">
          <div class="flex flex-middle justify-center">
            <img class="mr-2" width="40" src=".../.../../../../../../../assets/image/warning.svg">
          </div>
          <h2 class="text-center">{{ message }}</h2>
        </div>
        <div class="col-md-12">
          <div class="form-group"
            *ngIf="component != 'acceptQuotation' && component != 'workShift' && component != 'warehouses' && component !='store-profile' && component != 'assembly' && component != 'transfer-stock' && component != 'catalog' && component != 'asset'">
            <p>{{
              "PURCHASE_ORDER.messages.reason" | translate }}</p>

            <textarea class="form-control" cols="30" rows="20" [(ngModel)]="reason">
            </textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-4">
      <div class="flex flex-middle flex-end">
        <button class="btn btn-default mb-2" (click)="closeModal()">
          {{ "COMMON.close" | translate }}
        </button>
        <button class="btn btn-primary mb-2" [disabled]="reason == ''" (click)="confirm()">
          {{ "COMMON.confirm" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>