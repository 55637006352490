import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetCurrencyService } from 'src/app/core/services/get-currency.service';

@Component({
  selector: 'app-offer-items',
  templateUrl: './offer-items.component.html',
  styleUrls: ['./offer-items.component.scss']
})
export class OfferItemsComponent implements OnInit {

  offer_items: any
  total_after_tax: any
  status: any
  notes: any
  defaultImage = 'assets/image/uim_image-v.png'
  currency: any

  constructor(
    private dialogRef: MatDialogRef<OfferItemsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private getCurrencyService: GetCurrencyService

  ) { }

  ngOnInit() {
    this.getCurrency();
    this.offer_items = this.data.offer_items
    this.total_after_tax = this.data.total_after_tax
    this.status = this.data.status
    this.notes = this.data.notes
  }

  getCurrency(){
    this.currency = this.getCurrencyService.getCurrency()
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  acceptOffer() {
    this.dialogRef.close(true);
  }

}
