<div class="table-container">



  <div class="flex flex-col md:flex-row justify-between pl-4 pr-4 pt-4">
    <h2 class="mb-2">{{ "HEADER.DATA_MANAGEMENT.marketplace_outgoing_history" | translate }}</h2>
    <div class="flex justify-end pl-4 pr-4 pb-4">

      <!-- <button class="btn btn-default ml-2 mr-2" (click)="clearFilter()"><i class='bx bx-revision'></i></button>
      <button class="btn btn-default" [class.btn-open-filter]="isFilter" (click)="openFilter()">
        <i class='bx bx-filter-alt'></i>
      </button> -->
    </div>

  </div>



  <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap "
    [class.open-filter]="isFilter">

  </div>




  <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
  </app-pagination>



  <div class="component-loader" (errorCallback)="getMarketPlaces()" [errorMessage]="'lOADER.errorMessage' | translate "
    component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
    [componentLoading]="loading">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ "MARKETPLACES.extras.marketplace_number" | translate }}
          </th>
          <th scope="col">
            {{
            "QUOTION.attributes.created_at.label"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "MARKETPLACES.extras.weight"
            | translate
            }}
            <span>({{ "MARKETPLACES.extras.kg" | translate}})</span>
          </th>
          <th scope="col">
            {{
            "QUOTION.attributes.status.label"
            | translate
            }}
          </th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let marketplace of marketplaces | paginate: paginationConfig">
          <td class="pointer weight-label" (click)="goToDetails(marketplace.id)">
            {{ marketplace?.id }}
          </td>
          <td class="">
            <div>
              {{ marketplace?.created_at | date :'medium' }}
            </div>
          </td>
          <td>
            {{ marketplace?.weight }}
          </td>
          <td>
            {{ ("QUOTION.attributes.status." + marketplace?.status) | translate }}
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
</app-pagination>