import {Component, NgZone, OnInit} from '@angular/core';
import {Globals} from '../../globals';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  color = '#5cb040';
  mode = 'indeterminate';
  value = 50;
  isLoading = this.loaderService.isLoading;
  loading = false;

  constructor(
    public globals: Globals,
    private loaderService: LoaderService,
    private ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe(
      (value) => {
        this.ngZone.run(() => this.loading = value);
      }
    );
  }

}
