import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { Globals } from 'src/app/core/globals';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';
import { CountriesService } from 'src/app/services/countries.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  FormRegister: FormGroup | any = new FormGroup({
    phone: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
    country_name: new FormControl(null, []),
    name: new FormControl(null, [Validators.required]),
    shop_name: new FormControl(null, [Validators.required]),
    country_id: new FormControl(null, [Validators.required]),
  });
  selectedLanguage = 'ar';
  countries: any
  hasPhoneNumberError: boolean
  initialCountry = 'sa'
  onlyCountries: any[] = []

  submittingRegister = false;


  constructor(public translate: TranslateService,
    public languageService: ChangeLanguageService,
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private dialog: MatDialog,
    public globals: Globals,
    private countriesService: CountriesService,) {
    document.body.classList.add('admin_theme');
    if (localStorage.getItem('lang')) {
      translate.setDefaultLang(localStorage.getItem('lang'));
    } else {
      localStorage.setItem('lang', 'ar')
      document.querySelector('body').setAttribute('dir', 'rtl');
    }
  }


  ngOnInit(): void {
    this.getCountries()
    document.querySelector('body').setAttribute('dir', this.selectedLanguage == 'ar' ? 'rtl' : 'ltr');
  }

  getCountries() {
    const params = {};
    this.countriesService.getCountries(params).subscribe((res: any) => {
      this.countries = res.data.items;
      let index = this.countries.findIndex(item => item.country_code == this.initialCountry)
      this.FormRegister.patchValue({
        country_id: this.countries[index].id
      })
      this.fillOnlyCountries()
    })
  }
  fillOnlyCountries() {
    this.countries.forEach((obj) => {
      const value = `${obj.country_code}`;
      this.onlyCountries.push(value);
    });

  }

  hasError(hasError: any) {
    this.hasPhoneNumberError = !hasError
  }

  getNumber(phoneNumber: any) {
    if (this.hasPhoneNumberError == false) {
      this.FormRegister.patchValue({
        phone: phoneNumber
      })
    }

  }

  onCountryChange(data) {
    let index = this.countries.findIndex(item => item.country_code == data.iso2)
    this.FormRegister.patchValue({
      country_id: this.countries[index].id
    })
  }


  goLogin() {
    this.router.navigate([`/login`])
  }

  changeLanguage() {
    if (localStorage.getItem('lang') == 'ar') {
      localStorage.setItem('lang', 'en')
      this.languageService.langauge.next('en')
      this.translate.use('en')
    } else if (localStorage.getItem('lang') == 'en') {
      localStorage.setItem('lang', 'ar')
      this.languageService.langauge.next('ar')
      this.translate.use('ar')
    }

    document
      .querySelector('body')!
      .setAttribute('dir', localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr')
    document
      .querySelector('html')!
      .setAttribute('lang', localStorage.getItem('lang') == 'ar' ? 'ar' : 'en')
  }

  onSubmit() {
    const registerParam = {
      user: {
        name: this.FormRegister.get('name').value,
        phone: this.FormRegister.get('phone').value,
        password: this.FormRegister.get('password').value,
        country_id: this.FormRegister.get('country_id').value,
        shop_name: this.FormRegister.get('shop_name').value,
        device_type: 'web',
        app_type: 'shopmanagerweb',
        device_token: 'bab7e9e21ec89a51',
        language_id: 1
      }
    };

    this.submittingRegister = true;

    this.auth.register(registerParam).subscribe((res: any) => {

      if (res) {
        this.router.navigate([`/activate-account`])
      }


    }, error => {
      if (error.error.code == 422) {
        const dialogRef = this.dialog.open(MessageFormComponent, {
          width: '500px',
          disableClose: true,
          data: { message: error.error.error.base[0], state: false }
        })
        dialogRef.afterClosed().subscribe(() => {
          window.location.reload()
        });
      } else {
        this.translate.get('LOGIN.messages.login_error', { value: 'login_error' }).subscribe((res: string) => {
          this.alertify.error(res)
        });
      }
      this.submittingRegister = false;

    });
  }

}
