import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { MarketplacesService } from 'src/app/services/marketplaces.service';
import { StoreProfileService } from 'src/app/services/store-profile.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';

@Component({
  selector: 'app-marketplace-outgoing-current',
  templateUrl: './marketplace-outgoing-current.component.html',
  styleUrls: ['./marketplace-outgoing-current.component.scss']
})
export class MarketplaceOutgoingCurrentComponent implements OnInit {

  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'marketplaces',
  }

  isFilter: boolean = false
  hasMarketplacesRole: boolean = false
  currentUser: any
  marketplaces: any
  status: any
  loadingError = false
  loading = false
  params: any = {}
  page = 1
  hide_identity: any = 'all'
  shopCategories: any
  shop_category_ids: any = 'all'
  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private marketplacesService: MarketplacesService,
    private userPermission: UserPermissionService,
    private alertify: AlertifyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storeProfileService: StoreProfileService,
  ) { }

  ngOnInit(): void {
    const splitStringUrl = this.router.url.split("?");
    if (splitStringUrl[0] == '/marketplaces/marketplace_outgoing') {
      this.params.status = 'pending'
      this.status = this.params.status
    }
    if (splitStringUrl[0] == '/marketplaces/marketplace_outgoing_current') {
      this.params.status = 'pending'
      this.status = this.params.status
    }
    if (splitStringUrl[0] == '/marketplaces/marketplace_outgoing_history') {
      this.params.status = 'history'
      this.status = this.params.status
    }
    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map,
        }

        this.page = this.params['page']

      }
      this.getMarketPlaces()
    })
    this.hasMarketplacesRole = this.userPermission.hasOneRole(['admin'])
  }


  getShopCategories() {
    let param = {
      all: true
    }
    this.storeProfileService.getShopCategories(param).subscribe((res: any) => {
      this.shopCategories = res.data.items
    })
  }

  getMarketPlaces() {
    this.loading = true
    const params = {
      ...this.params,
      page: this.page,
    }
    this.addQueryString(params)
    this.marketplacesService.getMarketPlacesOutgoing(params).subscribe((res: any) => {
      this.marketplaces = res.data.items
      this.loading = false
      this.loadingError = false
      this.updatePagination(res.data.pagination)
    })
  }


  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'marketplaces',
    }
  }


  onPageChangeMarketPlaces(pageNo: any) {
    this.page = pageNo
    this.getMarketPlaces()
  }


  goToDetails(id) {


    this.params = {
      ...this.params,
      page: this.page,
    }
    this.router.navigate([`/marketplaces/marketplace_outgoing_current/details/${id}`], {
      queryParams: this.params,
    })
  }


  openFilter() {
    this.isFilter = !this.isFilter
  }

  selectStatusMarketPlaces(statusName: string) {
    this.params.status = statusName
    this.page = 1
    this.getMarketPlaces()
  }

  selectIdentityState(statusName: string) {
    if (statusName == 'all') {
      delete this.params['hide_identity']
      this.hide_identity = 'all'
    } else if (statusName == 'false') {
      this.params.hide_identity = false
    } else {
      this.params.hide_identity = true
    }
    this.page = 1
    this.getMarketPlaces()
  }

  selectShopCategoryIds(data) {
    if (data == 'all') {
      delete this.params.shop_category_ids
    } else {
      this.params.shop_category_ids = data
    }
    this.page = 1
    this.getMarketPlaces()
  }




  addQueryString(param) {

    let queryParams: string = param
    let params = new HttpParams()
    for (const [key, value] of Object.entries(queryParams)) {
      params = params.append(key, value)
    }

    window.history.replaceState({}, '', `/marketplaces/marketplace_outgoing_current?${params.toString()}`)
  }

  clearFilter() {
    window.history.replaceState({}, '', `/marketplaces/marketplace_outgoing_current`)
    this.page = 1
    delete this.params.hide_identity
    this.hide_identity = 'all'
    delete this.params.shop_category_ids
    this.shop_category_ids = 'all'
    this.getMarketPlaces()
  }





}
