import { MapService } from './../../../services/map.service';
import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { MessageFormComponent } from '../../../core/components/message-form/message-form.component';

declare var google: any;

@Component({
  selector: 'app-map-form',
  templateUrl: './map-form.component.html',
  styleUrls: ['./map-form.component.css'],
})
export class MapFormComponent implements OnInit {
  lat: number = 30.9655984;
  lng: number = 30.6210818;
  zoom = 15;
  locationChose = false;
  location: string;
  address: any;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<MapFormComponent>,
    private mapService: MapService,
    public translate: TranslateService,
    private alertify: AlertifyService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (!navigator.geolocation) {
      this.translate
        .get('MAP.messages.location_not_supported', {
          value: 'location_not_supported',
        })
        .subscribe((res: string) => {
          this.dialog.open(MessageFormComponent, {
            width: '400px',
            disableClose: true,
            data: { message: res, state: false },
          });
        });
    }
    if (this.data) {
      this.lat = this.data.lat;
      this.lng = this.data.long;
      this.address = this.data.address_line1;
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }

    this.loadPlacesAutocomplete()
  }

  loadPlacesAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new window['google']['maps']['places']['Autocomplete'](this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: any = google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.address = place?.address_components[0].long_name
        });
      });
    });
  }

  onChoseLocation(event) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.locationChose = true;
    this.location = `${this.lat},${this.lng}`;
    this.mapService.getLocation(this.location).subscribe((data: any) => {
      if (data.results != '') {
        let location = data.results;
        this.address = location[0].formatted_address;
      }
    });
  }

  // searchPlace(data) {
  //   this.mapService.getPlace(data).subscribe((data: any) => {
  //     this.address = data.candidates.formatted_address
  //     this.lat = data.candidates.geometry.location.lat
  //     this.lng = data.candidates.geometry.location.lng
  //   }, err => {

  //   })
  // }

  getInfoMap() {
    this.mapService.getLocation(this.location).subscribe(
      (data: any) => {
        this.address = data.results;
        this.dialogRef.close({
          address: this.address,
          lat: this.lat,
          lng: this.lng,
        });
      },
      (err) => { }
    );
  }

  closeModal() {
    this.dialogRef.close();
  }
}
