import { Inject, Injectable } from '@angular/core';
import { Router, NavigationEnd, RouterState, ActivatedRoute } from '@angular/router';
import { environment, googleAnalytics } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';

declare var gtag: Function;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    constructor(private router: Router,
        private titleService: Title,
        @Inject(DOCUMENT) private document: Document,) { }

    public initialize() {
        this.onRouteChange();

        // dynamically add analytics scripts to document head
        try {
            const url = 'https://www.googletagmanager.com/gtag/js?id=';
            const gTagScript = document.createElement('script');
            gTagScript.async = true;
            gTagScript.src = `${url}${googleAnalytics}`;
            document.head.appendChild(gTagScript);

            const dataLayerScript = document.createElement('script');
            dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleAnalytics}', {'send_page_view': false});`;
            document.head.appendChild(dataLayerScript);
        } catch (e) {
            console.error('Error adding Google Analytics', e);
        }
    }

    // track visited routes
    private onRouteChange() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd | any) => {
            const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
            this.titleService.setTitle(title);
            gtag('event', 'page_view', {
                page_title: title,
                page_path: event.urlAfterRedirects,
                page_location: this.document.location.href
            })
        })
    }



    getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
            data.push(parent.snapshot.data['title']);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getTitle(state, parent.firstChild));
        }
        return data;
    }



}