import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { ProfileUserService } from 'src/app/services/profile-user.service';

@Component({
  selector: 'app-update-my-profile',
  templateUrl: './update-my-profile.component.html',
  styleUrls: ['./update-my-profile.component.scss']
})
export class UpdateMyProfileComponent implements OnInit {

  profileData: any;
  imageSrc: string = '';

  actionName: string;
  selectedfile = null;
  reader: FileReader;

  profileForm: FormGroup | any = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl(null, []),
    phone: new FormControl('', [Validators.required]),
    attachment: new FormControl()
  })
  constructor(
    private dialogRef: MatDialogRef<UpdateMyProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public translate: TranslateService,

  ) { }

  ngOnInit(): void {
    if (this.data != null) {
      this.profileForm.patchValue({
        name: this.data.name,
        email: this.data.email,
        phone: this.data.phone,
        attachment: this.data.attachment
      });
    }
  }

  OnFileSelected(event) {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.translate
        .get('CATEGORIES.messages.mass_file_not_available', {
          value: 'mass_file_not_available',
        })
        .subscribe((res: string) => {
          const dialogRef = this.dialog.open(MessageFormComponent, {
            width: '400px',
            disableClose: true,
            data: { message: res, state: false },
          });
          dialogRef.afterClosed().subscribe(() => {
            this.profileForm.get("attachment").setValue("");
            this.selectedfile = {}
          });
        });
      return;
    }
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  handleReaderLoaded(e) {
    let reader = e.target;
    this.imageSrc = reader.result;
  }

  onSubmit() {
    let attachment = {
      "name": 'profile',
      "file": this.imageSrc
    }
    let profileData = {
      user: {
        ...this.profileForm.value,
        attachment: [attachment],

      }
    }

    if (this.profileForm.valid) {
      this.dialogRef.close(profileData);
    }
  }
  closeModal() {
    this.dialogRef.close();
  }


}
