<div class="component-loader" (errorCallback)="getMarketplace()" [errorMessage]="'lOADER.errorMessage' | translate "
  component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
  [componentLoading]="loading">



  <!--details content-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "MARKETPLACES.extras.marketplace_details" | translate }}</h2>
      <div>

        <!-- <button class="btn btn-primary ml-2 mr-2 mb-2" *ngIf="marketplace.invoice_id != null"
          (click)="downloadInvoice()" [disabled]="printInvoice"> {{ "INVOICES.actions.download_invoice" | translate }}
        </button>

        <button class="btn btn-primary ml-2 mr-2 mb-2" *ngIf="marketplace?.status == 'waiting_review'"
          (click)="confirmDelivery()"> {{ "MARKETPLACES.actions.confirm_delivery" | translate }} </button> -->

        <button class="btn btn-default mb-2" (click)="backTolist()" *ngIf="tripId == null">
          {{ "MARKETPLACES.actions.back_to_marketplace_outgoing" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>

        <button class="btn btn-default mb-2" (click)="backToDetails()" *ngIf="tripId != null">
          {{ "DRIVERS.actions.back_to_trip_details" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>

      </div>
    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{ "MARKETPLACES.extras.marketplace_number" | translate }}</p>
        <span class="tabel-content"> {{ marketplace?.id }}</span>
      </div>
      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "QUOTION.attributes.status.label" | translate
          }}</p>
        <span class="tabel-content"> {{ ("MARKETPLACES.status." + marketplace?.status) | translate }}</span>

      </div>

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "QUOTION.attributes.created_at.label" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.created_at | date }}</span>
      </div>

      <div class="col-span-3 md:col-span-2 row-span-2" *ngIf="marketplace?.expire != null">
        <p> {{
          "QUOTION.attributes.expire_time.label" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.expire | date }} </span>
      </div>


      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.start_date" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.contract_from |date }}</span>
      </div>

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.expires_at" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.contract_to | date }}</span>
      </div>

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.ex_at" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.expires_at | date }}</span>
      </div>

    </div>



  </div>

  <!--details content-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.actions.trip_details" | translate }}</h2>
      <div>

      </div>
    </div>


    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.number_of_shippers" | translate
          }}</p>
        <span class="tabel-content"> {{marketplace?.number_of_shippers}}</span>
      </div>
      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.weight" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.weight }}</span>
      </div>
    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">

      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.repetition" | translate
          }}</p>
        <span class="tabel-content"> {{ ("MARKETPLACES.extras." + marketplace?.repetition) | translate }} </span>
      </div>

      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.repetitionTime" | translate
          }}</p>
        <span class="tabel-content">{{ ("MARKETPLACES.extras." + marketplace?.repetition_time) | translate }} </span>
      </div>

    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">

      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.ride_type" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.ride_type }}</span>
      </div>
      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.package_type" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.package_type }}</span>
      </div>
    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.direction" | translate
          }}</p>
        <span class="tabel-content"> {{ ("MARKETPLACES.extras." + marketplace?.direction) | translate }}</span>
      </div>


      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.pickup_time" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.pickup_time }}</span>
      </div>
      <div class="col-span-3 md:col-span-2 row-span-2" *ngIf="marketplace?.direction == 'two_way'">
        <p> {{
          "MARKETPLACES.extras.return_time" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.delivery_time }}</span>
      </div>
    </div>

    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.address_line1" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.pickup_address?.address_line1 }}</span>
      </div>

      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.city_id" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.pickup_address?.city_name }}</span>
      </div>
    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.address_line1to" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.delivery_address?.address_line1 }}</span>
      </div>

      <div class="col-span-2 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.city_id_to" | translate
          }}</p>
        <span class="tabel-content"> {{ marketplace?.delivery_address?.city_name }}</span>
      </div>
    </div>

  </div>

  <!---tabel product-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "MARKETPLACES.extras.quotations" | translate }}</h2>
    </div>


    <div class="details-container mt-3 tabel-detais">
      <table class="table">
        <thead>
          <tr>
            <th scope="col"> {{ "MARKETPLACES.actions.offer_number" | translate }}</th>
            <th scope="col"> {{ "QUOTATION.attributes.name" | translate }}</th>
            <th scope="col"> {{ "MARKETPLACES.extras.total" | translate }}</th>
            <th scope="col"> {{ "QUOTION.attributes.status.label" | translate }}</th>
            <th scope="col">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let offer of marketplace?.quotations">
            <td> {{ offer?.number }}</td>
            <td> {{ offer?.user?.name }}</td>
            <td> {{ offer?.total }} </td>

            <td *ngIf="offer?.status == 'done'">
              {{ ("MARKETPLACES.status_offer." + offer?.status) | translate }}
            </td>
            <td *ngIf="offer?.status != 'done' && marketplace?.status == 'done'"></td>

            <td>
              <button class="btn btn-primary ml-2 mr-2 mb-2" *ngIf="marketplace?.status != 'done'"
                (click)="acceptQuotation(offer)">{{ "QUOTATION.attributes.accept_offer" | translate }}</button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>



  <div class="details-container" *ngIf="trips.length > 0">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "HEADER.DATA_MANAGEMENT.trips" | translate }}</h2>
    </div>

    <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
    </app-pagination>
    <div class="details-container mt-3 tabel-detais">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">
              {{ "TRIPS.trip_number" | translate }}
            </th>

            <th scope="col">
              {{ "TRIPS.contract_number" | translate }}
            </th>

            <th scope="col" *ngIf="currentUser.user_category.key == 'shipping_company'">
              {{ "TRIPS.customer_name" | translate }}
            </th>

            <th scope="col">
              {{ "DRIVERS.extras.shipment_date" | translate }}
            </th>

            <th scope="col">
              {{ "DRIVERS.extras.status" | translate }}
            </th>

            <th scope="col" *ngIf="currentUser.user_category.key == 'shipping_company'">
              {{ "TRIPS.assigned_truck" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of trips | paginate: paginationConfig">
            <td class="pointer weight-label" (click)="goToDetails(item.id)">
              {{ item?.tracking_reference }}
            </td>

            <td class=" weight-label">
              {{ item?.quotation_request?.id }}
            </td>

            <td class=" weight-label" *ngIf="currentUser.user_category.key == 'shipping_company'">
              {{ item?.quotation_request?.user?.name }}
            </td>

            <!-- <td class=" weight-label" *ngIf="currentUser.user_category.key == 'company'">
              {{ item?.quotation_request?.user?.name }}
            </td> -->

            <td>
              {{ item?.shipment_date | date }}
            </td>
            <td>
              {{ ("DRIVERS.extras.statusTrip." + item?.status) | translate }}
            </td>

            <td class="" *ngIf="currentUser.user_category.key == 'shipping_company' ">
              <button class="btn btn-primary ml-2 mr-2 mb-2"
                *ngIf="item?.status != 'cancelled' && item?.status != 'delivered'  && item?.truck == null"
                (click)="assignTruck(item)"> {{
                "DRIVERS.actions.assignTruck" | translate }} </button>
              <!-- <button class="btn btn-primary ml-2 mr-2 mb-2"
                *ngIf="item?.status != 'cancelled' && item?.status != 'delivered'  && item?.truck == null"
                (click)="addComment(item)"> {{
                "DRIVERS.actions.addComment" | translate }} </button> -->

              <p *ngIf="item?.status != 'cancelled' && item?.status != 'delivered'  && item?.truck != null">
                {{item?.truck?.number}} - {{item?.truck?.user_driver?.name}}</p>
            </td>

          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>