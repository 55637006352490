<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "DRIVERS.actions.updateTrip" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <div class=" mb-2">
          <h2>
            {{ "DRIVERS.actions.updateTripMass" | translate }} <span class="text-red-600 text-xl"> {{
              ("DRIVERS.extras.statusTrip." +
              data.trip?.status) |
              translate }}</span> {{
            "DRIVERS.actions.updateTripMassTo" | translate }} <span class="text-red-600 text-xl">
              {{ ("DRIVERS.extras.statusTrip." + data.trip?.statusNext)
              |
              translate }}
            </span>
          </h2>
        </div>

      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="assignDriver()">
        {{ "COMMON.confirm" | translate }}

      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>