
import { Component, Injectable, Input, OnInit, HostListener } from '@angular/core';

@Injectable()
@Component({
    selector: '[maly-lightbox]',
    template: `
        <span (click)="openLightbox()">
            <ng-content></ng-content>
        </span>
        <div class="malylightbox-container" *ngIf="lightboxOpen">
            <div class="maly-lightbox-wrapper">
                <img [src]="lightboxSrc" />
            </div>
            <div (click)="closeLightbox()" class="maly-lightbox-background"></div>
        </div>
    `,
    styleUrls: ['./maly-light-box.component.scss']
})

@Component({
  selector: 'app-maly-lightbox',
  templateUrl: './maly-light-box.component.html',
  styleUrls: ['./maly-light-box.component.scss']
})
export class MalyLightBoxComponent implements OnInit {

  @Input() lightboxSrc;
    lightboxOpen: boolean = false;
    openLightbox() {
        this.lightboxOpen = true;
        document.body.classList.add('overflow-hidden');
    }
    closeLightbox() {
        this.lightboxOpen = false;
        document.body.classList.remove('overflow-hidden');
    }
    ngOnInit() {

        

    }
    ngOnDestroy() {

    }
    constructor(
    ) { }
}
