import { Injectable } from '@angular/core';
import { Globals } from './../../core/globals/index'

@Injectable({
  providedIn: 'root'
})
export class GetCurrencyService {

  currentUser: any
  constructor(
    private globals: Globals,
  ) { }


  getCurrency() {
    this.currentUser = JSON.parse(this.globals.currentUser || null)

    return this.currentUser?.shop?.country?.currency
  }
}
