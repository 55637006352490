import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TripsService {

  apiUrl = `users/api/v1`;
  constructor(private http: HttpClient) { }

  getTrips(params) {
    return this.http.get(baseUrl + `${this.apiUrl}/contract_shipments`, { params });
  }

  getTrip(id): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrl}/contract_shipments/` + id);
  }

  updateTrip(contractShipmentId, model) {
    return this.http.put(baseUrl + `${this.apiUrl}/contract_shipments/${contractShipmentId}`, model);
  }

  assignTruck(Id, model) {
    return this.http.put(baseUrl + `${this.apiUrl}/contract_shipments/${Id}/assign_truck`, model);
  }

  addComment(Id, model) {
    return this.http.post(baseUrl + `${this.apiUrl}/contract_shipments/${Id}/comment`, model);
  }

  shipmentRescheduleRequests(contractShipmentId, model) {
    return this.http.post(baseUrl + `${this.apiUrl}/contract_shipments/${contractShipmentId}/shipment_reschedule_requests`, model);
  }
  getShipmentRescheduleRequest(id, params): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrl}/contract_shipments/${id}/shipment_reschedule_requests`, { params });
  }
  accept(Id, idContract, model) {
    return this.http.put(baseUrl + `${this.apiUrl}/contract_shipments/${idContract}/shipment_reschedule_requests/${Id}/accept`, model);
  }
  refuse(Id, idContract, model) {
    return this.http.put(baseUrl + `${this.apiUrl}/contract_shipments/${idContract}/shipment_reschedule_requests/${Id}/refuse`, model);
  }
  addExtraCharges(Id, idContract, model) {
    return this.http.put(baseUrl + `${this.apiUrl}/contract_shipments/${idContract}/shipment_reschedule_requests/${Id}/add_extra_charges`, model);
  }
}
