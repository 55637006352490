import { Component, Inject, OnInit } from '@angular/core';
import { baseUrl } from 'src/environments/environment';
import { Globals } from './core/globals';
import { TranslateService } from '@ngx-translate/core';
import { ChangeLanguageService } from './core/services/change-language.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { DOCUMENT } from '@angular/common';
declare const gtag: Function;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'alam';
    constructor(
        private globals: Globals,
        translateService: TranslateService,
        public languageService: ChangeLanguageService,
        private router: Router,
        private titleService: Title,
        @Inject(DOCUMENT) private document: Document,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {

        if (localStorage.getItem('lang') == 'ar') {
            localStorage.setItem('lang', 'ar')
            this.languageService.langauge.next('ar')
        }
        else if (localStorage.getItem('lang') == 'en') {
            localStorage.setItem('lang', 'en')
            this.languageService.langauge.next('en')
        } else {
            localStorage.setItem('lang', 'ar')
            this.languageService.langauge.next('ar')
        }
        translateService.setDefaultLang(localStorage.getItem('lang'));
        document.querySelector('body')!.setAttribute('dir', localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr');
        document.querySelector('html')!.setAttribute('lang', localStorage.getItem('lang') == 'ar' ? 'ar' : 'en');
    }
    ngOnInit() {
        this.googleAnalyticsService.initialize();
        const curentuser = localStorage.getItem('currentUser-b2p');
        if (curentuser) {
            this.globals.currentUser = curentuser;
        }

    }

}
