import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { AgmCoreModule } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { createTranslateLoader } from 'src/app/app.module';
import { CoreModule } from 'src/app/core/core.module';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { apiKeyMap } from 'src/environments/environment';
// import { NgApexchartsModule } from "ng-apexcharts";
import { ChartsModule } from 'ng2-charts';
@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ChartsModule,
    LoaderModule,
    NgSelectModule,
    NgxPaginationModule,
    CoreModule,
    // NgApexchartsModule,
    FormsModule,
    MatProgressSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: apiKeyMap
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

  ]
})
export class HomeModule { }
