import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { UsersService } from 'src/app/services/users.service';
import { WalletFormComponent } from '../../wallet/wallet-form/wallet-form.component';
import { ManegerFormComponent } from '../maneger-form/maneger-form.component';

@Component({
  selector: 'app-manegers',
  templateUrl: './manegers.component.html',
  styleUrls: ['./manegers.component.scss']
})
export class ManegersComponent implements OnInit {

  defaultImage = 'assets/image/uim_image-v.png'
  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'manegers',
  }
  isFilter: boolean = false
  currentUser: any
  users: any
  loadingError = false
  loading = false
  params: any = {}
  page = 1

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private usersService: UsersService,
    private alertify: AlertifyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }


  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map,
        }

        this.page = this.params['page']
      }
      this.getDrivers()
    })
  }

  getDrivers() {
    this.loading = true
    const params = {
      ...this.params,
      page: this.page,
      role: 'admin',
      user_category: 'petrol_station',
    }
    this.addQueryString(params)
    this.usersService.getDrivers(params).subscribe((res: any) => {
      this.users = res.data.items
      this.loading = false
      this.loadingError = false
      this.updatePagination(res.data.pagination)
    }, (error => {
      this.loading = false
      this.loadingError = true
    }))
  }


  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'manegers',
    }
  }


  onPageChangeMarketPlaces(pageNo: any) {
    this.page = pageNo
    this.getDrivers()
  }


  goToDetails(id) {
    this.params = {
      ...this.params,
      page: this.page,
    }
    this.router.navigate([`/manegers/details/${id}`], { queryParams: this.params })
  }


  openFilter() {
    this.isFilter = !this.isFilter
  }


  addQueryString(param) {
    let queryParams: string = param
    let params = new HttpParams()
    for (const [key, value] of Object.entries(queryParams)) {
      params = params.append(key, value)
    }

    window.history.replaceState({}, '', `/manegers?${params.toString()}`)
  }

  clearFilter() {
    window.history.replaceState({}, '', `/manegers`)

    this.page = 1
    this.getDrivers()
  }



  createDrivers() {
    const dialogRef = this.dialog.open(ManegerFormComponent, {
      width: '90%',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.usersService.createDrivers(date).subscribe((res: any) => {
          this.getDrivers()
        })
      }

    }, (error => {
      this.loading = false
      this.loadingError = true
    }));
  }

  update(worker: any) {
    const dialogRef = this.dialog.open(ManegerFormComponent, {
      width: '90%',
      disableClose: true,
      data: { user: worker }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.usersService.updateDriver(worker.id, date).subscribe((res: any) => {
          this.getDrivers()
        })
      }

    }, (error => {
      this.loading = false
      this.loadingError = true
    }));
  }

  releaseToDrivers(marketplaceId: any, state: any) {
    let data = { "user": { "active": state } }
    this.usersService.updateDriver(marketplaceId, data).subscribe((res: any) => {
      this.getDrivers()
    })
  }

}
