export enum googleTitles {
  assetsManagementComponent = 'Assets Management List',
  assetsManagementDetailsComponent = 'Assets Management Details',
  bankAccountsList = 'Bank Accounts List',
  bankAccountDetails = 'Bank Account Details',
  transactionDetails = 'Transaction Details',
  branchesList = 'Branches List',
  brands = 'Brands',
  brandDetails = 'Brand Details',
  categories = 'Categories',
  categoryDetails = 'Category Details',
  couponsList = 'Coupons List',
  couponsDetails = 'Coupon Details',
  coverageZonesList = 'Coverage Zones List',
  coverageZoneDetails = 'Coverage Zone Details',
  customers = 'Customers',
  customerDetails = 'Customer Details',
  customersFinancialList = 'Customers Financial List',
  customersFinancialDetails = 'Customers Financial Details',
  departments = 'Departments List',
  departmentDetails = 'Department Details',
  discounts = 'Discounts',
  discountsDetails = 'Discount Details',
  draftInvoices = 'Draft Invoices List',
  draftInvoicesDetails = 'Draft Invoice Details',
  driversList = 'Drivers List',
  driversDetails = 'Driver Details',
  employeeList = 'Employee List',
  employeeDetails = 'Employee Details',
  endOfServiceList = 'End Of Service Report List',
  endOfServiceDetails = 'End Of Service Report Details',
  expensesList = 'Expenses List',
  financialObligationsList = 'Financial Obligations List',
  collectionGroupList = 'Collection Group List',
  collectionGroupDetails = 'Collection Group Details',
  simpleInvoiceList = 'Simple Invoice List',
  simpleInvoiceDetails = 'Simple Invoice Details',
  invoicesList = 'Invoices List',
  invoicesDetails = 'Invoice Details',
  sharedInvoice = 'Shared Invoice Details',
  laterManagement = 'Later Management',
  machinesList = 'Machines List',
  machinesDetails = 'Machine Details',
  suppliersList = 'Suppliers List',
  suppliersDetails = 'Supplier Details',
  merchantInvoice = 'Merchant Invoice',
  movingInventoryList = 'Moving Inventory  List',
  movingInventoryDetails = 'Moving Inventory  Details',
  ordersList = 'Orders List',
  ordersDetails = 'Order Details',
  physicalInventoriesList = 'Physical Inventories List',
  physicalInventoriesDetails = 'Physical Inventory Details',
  productsDeleteList = 'Products Delete List',
  productsDeleteDetails = 'Product Delete Details',
  productsList = 'Products List',
  productsDetails = 'Product Details',
  supportTicketList = 'Support Ticket List',
  promoCodesList = 'Promo Codes List',
  promoCodesDetails = 'Promo Code Details',
  purchaseOrderDetails = 'Purchase Order Details',
  externalPOList = 'External Purchase Order List',
  externalPODetails = 'External Purchase Order Details',
  quotationsList = 'Quotations List',
  quotationsDetails = 'Quotation Details',
  pendingInvoiceList = 'Pending Invoice List',
  pendingInvoiceDetails = 'Pending Invoice Details',
  reasonsStatistics = 'Statistics',
  stockManage = 'Stock Manage',
  salseReports = 'Sales Report',
  taxesReport = 'Taxes Report',
  operationCost = 'Operation Cost',
  compareBranches = 'Compare Branches',
  repricing = 'Repricing',
  salesManagement = 'Sales Management List',
  userEvaluationDetails = 'User Evaluation Details',
  reportsSummaryList = 'Reports Summary List',
  shiftClosesList = 'Shift Closes List',
  shiftClosesDetails = 'Shift Close Details',
  shippedPurchaseOrdersList = 'Shipped Purchase Orders List',
  shopShipmentsList = 'Shop Shipments List',
  shopShipmentsDetails = 'Shop Shipment Details',
  stockHistoryList = 'Stock History List',
  storeProfile = 'Store Profile',
  supplierList = 'Suppliers List',
  supplierDetails = 'Supplier Details',
  supplierInvoiceDetails = 'Supplier Invoice Details',
  transactionSupplierDetails = 'Transaction Details',
  supplierPaymentDetails = 'Supplier Payment Details',
  supportTicketsList = 'Support Tickets List',
  supportTicketsDetails = 'Support Ticket Details',
  supportTicketsTypesList = 'Support Tickets Types List',
  supportTicketsTypesDetails = 'Support Tickets Type Details',
  taxesList = 'Taxes List',
  taxesDetails = 'Taxe Details',
  warehousesList = 'Warehouses List',
  warehousesDetails = 'Warehouse Details',
  usersList = 'Users List',
  usersDetails = 'User Details',
  warehousesDeliveryList = 'Warehouses Delivery List',
  warehousesDeliveryDetails = 'Warehouses Delivery Details',
  warrantyDocumentsList = 'Warranty Documents List',
  warrantyDocumentsDetails = 'Warranty Document Details',
  wholesaleList = 'Wholesale List',
  wholesaleDetails = 'Wholesale Details',
  workshopFinancesList = 'Workshop Finances List',
  workshopFinancesDetails = 'Workshop Finance Details',
  workshops = 'Workshops List',
  home = 'Home',
  summary = 'Summary',
  quickInvoiceList = 'Quick Invoice List',
  quickInvoiceDetails = 'Quick Invoice Form',
  registration = 'Registration',
  login = 'Login',
  activateAccount = 'Activate Account',
  termsAndConditions = 'Terms And Conditions',
  trackingProductQr = 'Product Tracking',
  ProductTrackingReport = 'Product Tracking Report'
}






