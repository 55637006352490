<div>



  <!--details content-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "MARKETPLACES.actions.create_new_marketplace" | translate }}</h2>
      <div>

      </div>
    </div>

    <div class="p-4">
      <form [formGroup]="quotationForm">
        <div class="row mb-2">



          <div class="col-md-3" [class.has-error]="
          !quotationForm.get('number_of_shippers').valid && isSubmit">
            <div class="form-group">
              {{ "MARKETPLACES.extras.number_of_shippers" | translate }}
              <input class="form-control" type="text" formControlName="number_of_shippers" />
            </div>
          </div>


          <div class="col-md-3" [class.has-error]="!quotationForm.get('weight').valid && isSubmit">
            <div class="form-group">
              {{ "MARKETPLACES.extras.weight" | translate }} <span>({{ "MARKETPLACES.extras.kg" | translate}})</span>
              <input class="form-control" type="text" formControlName="weight" />
            </div>
          </div>
          <div class="col-md-2" [class.has-error]="!quotationForm.get('direction').valid && isSubmit">
            <div class="form-group">
              {{ "MARKETPLACES.extras.direction" | translate }}
              <ng-select [clearable]="true" class="select select-ng-tabel" formControlName="direction"
                (change)="selectDirection($event)">
                <ng-option *ngFor="let item of direction" [value]="item.id">
                  {{item.value}}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="" [class.has-error]="!quotationForm.get('pickup_time').valid && isSubmit"
            [class.col-md-2]="quotationForm.get('direction').value == 'two_way'"
            [class.col-md-4]="quotationForm.get('direction').value != 'two_way'">
            <div class="form-group">

              <label for=""> {{ "MARKETPLACES.extras.pickup_time" | translate }}</label>
              <div class="form-group relative">
                <!-- <input matInput [ngxMatDatetimePicker]="pickId22" name="started22-date" class="form-control h-10 date"
                  placeholder="{{ 'MARKETPLACES.extras.pickup_time' | translate }}" formControlName="pickup_time" />
                <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId22">
                </mat-datepicker-toggle>
                <ngx-mat-datetime-picker #pickId22 [enableMeridian]="true">
                </ngx-mat-datetime-picker> -->
                <input matTimepicker class="form-control date" formControlName="pickup_time">
              </div>
            </div>
          </div>
          <div class="col-md-2" [class.has-error]="!quotationForm.get('delivery_time').valid && isSubmit"
            *ngIf="quotationForm.get('direction').value == 'two_way'">
            <div class="form-group">

              <label for=""> {{ "MARKETPLACES.extras.return_time" | translate }}</label>
              <div class="form-group relative">
                <!-- <input matInput [ngxMatDatetimePicker]="pickId22" name="started22-date" class="form-control h-10 date"
                  placeholder="{{ 'MARKETPLACES.extras.return_time' | translate }}" formControlName="return_time" />
                <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId22">
                </mat-datepicker-toggle>
                <ngx-mat-datetime-picker #pickId22 [enableMeridian]="true">
                </ngx-mat-datetime-picker> -->
                <input matTimepicker class="form-control date" formControlName="delivery_time">
              </div>
            </div>
          </div>

          <!-- REPETITION -->
          <div class="col-md-6" [class.has-error]="!quotationForm.get('repetition').valid && isSubmit">
            <div class="form-group">
              {{ "MARKETPLACES.extras.repetition" | translate }}
              <ng-select [clearable]="true" class="select select-ng-tabel" formControlName="repetition"
                (change)="selectRepetition($event)">
                <ng-option *ngFor="let item of repetition" [value]="item.id">
                  {{item.value}}
                </ng-option>
              </ng-select>
            </div>
          </div>


          <div class="col-md-6" *ngIf="quotationForm.get('repetition').value == 'one_time'"></div>
          <div class="col-md-6" [class.has-error]="!quotationForm.get('repetition_time').valid && isSubmit"
            *ngIf="quotationForm.get('repetition').value != 'one_time'">
            <div class="form-group">
              {{ "MARKETPLACES.extras.repetitionTime" | translate }}
              <ng-select [clearable]="true" class="select select-ng-tabel" formControlName="repetition_time">
                <ng-option *ngFor="let item of repetitionTime" [value]="item.id">
                  {{item.value}}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <!-- /REPETITION -->


          <div class="col-md-6" [class.has-error]="!quotationForm.get('contract_from').valid && isSubmit">
            <div class="form-group">
              <label class="mb-2"> {{ "MARKETPLACES.extras.start_date" | translate }}</label>
              <div class="form-group relative">
                <input [matDatepicker]="pickId" name="started-date" class="form-control h-10 date"
                  placeholder="{{ 'MARKETPLACES.extras.start_date' | translate }}"
                  (dateInput)="setDateBox($event,'contract_from')" [max]="max" />
                <mat-datepicker #pickId></mat-datepicker>
                <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId">
                </mat-datepicker-toggle>

              </div>
            </div>
          </div>

          <div class="col-md-6" *ngIf="quotationForm.get('repetition').value == 'one_time'"></div>
          <div class="col-md-6" [class.has-error]="!quotationForm.get('contract_to').valid && isSubmit"
            *ngIf="quotationForm.get('repetition').value != 'one_time'">
            <div class="form-group">
              <label class="mb-2"> {{ "MARKETPLACES.extras.expires_at" | translate }}</label>
              <div class="form-group relative">
                <input [matDatepicker]="pickId2" name="end-date" class="form-control h-10 date"
                  placeholder="{{ 'MARKETPLACES.extras.expires_at' | translate }}"
                  (dateInput)="setDateBox($event,'contract_to')" [min]="min" />
                <mat-datepicker #pickId2></mat-datepicker>
                <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId2">
                </mat-datepicker-toggle>

              </div>
            </div>
          </div>






          <div class="col-md-6" [class.has-error]="!quotationForm.get('ride_type_id').valid && isSubmit">
            <div class="form-group">
              {{ "MARKETPLACES.extras.ride_type" | translate }}
              <ng-select [clearable]="true" class="select select-ng-tabel" formControlName="ride_type_id">
                <ng-option *ngFor="let item of rides" [value]="item.id">
                  {{item.name}}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-md-6" [class.has-error]="!quotationForm.get('package_type_id').valid && isSubmit">
            <div class="form-group">

              {{ "MARKETPLACES.extras.package_type" | translate }}
              <ng-select [clearable]="true" class="select select-ng-tabel" formControlName="package_type_id">
                <ng-option *ngFor="let item of packageType" [value]="item.id">
                  {{item.name}}
                </ng-option>
              </ng-select>
            </div>
          </div>








          <div class="col-md-6" [class.has-error]="!quotationForm.get('address_line1from').valid && isSubmit">
            <div class="form-group">
              {{ "MARKETPLACES.extras.address_line1" | translate }}
              <input class="form-control" type="text" formControlName="address_line1from" />
            </div>
          </div>

          <div class="col-md-6" [class.has-error]="
          !quotationForm.get('city_id_from').valid && isSubmit
        ">
            <div class="form-group">
              {{ "MARKETPLACES.extras.city_id" | translate }}
              <ng-select [clearable]="true" class="select select-ng-tabel" formControlName="city_id_from">
                <ng-option *ngFor="let item of cities" [value]="item.id">
                  {{item.name}}
                </ng-option>
              </ng-select>
            </div>
          </div>



          <div class="col-md-6" [class.has-error]="
          !quotationForm.get('address_line1to').valid && isSubmit
        ">
            <div class="form-group">
              {{ "MARKETPLACES.extras.address_line1to" | translate }}
              <input class="form-control" type="text" formControlName="address_line1to" />
            </div>
          </div>

          <div class="col-md-6" [class.has-error]="
          !quotationForm.get('city_id_to').valid && isSubmit
        ">
            <div class="form-group">
              {{ "MARKETPLACES.extras.city_id_to" | translate }}
              <ng-select [clearable]="true" class="select select-ng-tabel" formControlName="city_id_to">
                <ng-option *ngFor="let item of cities" [value]="item.id">
                  {{item.name}}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-md-6" [class.has-error]="!quotationForm.get('expires_at').valid && isSubmit">
            <div class="form-group">
              <label class="mb-2"> {{ "MARKETPLACES.extras.ex_at" | translate }}</label>
              <div class="form-group relative">
                <input [matDatepicker]="pickId3" name="expiry-date" class="form-control h-10 date"
                  placeholder="{{ 'MARKETPLACES.extras.ex_at' | translate }}"
                  (dateInput)="setDateBox($event,'expires_at')" [min]="maxAccept" [max]="minAccept" />
                <mat-datepicker #pickId3></mat-datepicker>
                <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId3">
                </mat-datepicker-toggle>

              </div>
            </div>
          </div>

          <div class="col-md-12 my-8"></div>

        </div>
      </form>
    </div>

    <div class="modal-footer p-4">
      <button class="btn btn-primary" (click)="createContract()">
        {{ "COMMON.create" | translate }}
      </button>

    </div>
  </div>

</div>