import { Component, OnInit } from '@angular/core';
import { ChangeLanguageService } from '../core/services/change-language.service';
import { SidebarService } from '../core/services/sidebar.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  title = 'sidebar';
  constructor(
    public sideBarService: SidebarService,
    public languageService: ChangeLanguageService
  ) {
    if (localStorage.getItem('lang') == 'ar') {
      localStorage.setItem('lang', 'ar')
      this.languageService.langauge.next('ar')
    }
    else if (localStorage.getItem('lang') == 'en') {
      localStorage.setItem('lang', 'en')
      this.languageService.langauge.next('en')
    } else {
      localStorage.setItem('lang', 'ar')
      this.languageService.langauge.next('ar')
    }

    document.querySelector('body')!.setAttribute('dir', localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr');
    document.querySelector('html')!.setAttribute('lang', localStorage.getItem('lang') == 'ar' ? 'ar' : 'en');
  }

}
