import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileUserService {


  constructor(private http: HttpClient,) { }

  getProfile() {
    return this.http.get(baseUrl + `users/api/v1/profile`);
  }

  updateProfile(model: any) {
    return this.http.put<any>(baseUrl + 'users/api/v1/profile', model);
  }

}
