import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';
import { SidebarService } from 'src/app/core/services/sidebar.service';
import { control_board } from 'src/environments/environment';
import { Globals } from '../../../core/globals/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  defaultImage = 'assets/image/store-placeholder.png';
  labelItem: string = ''
  show: string = 'inActive'
  currentUser: any
  bankInfos: any;
  selectedLanguage = 'ar';
  hasTicketsPermissionOnly = false;
  profile: any;
  isWholesale: boolean = false;
  isMarketplaceOnly: boolean = false;
  isStoreOnline: boolean = false;
  isSimpleInvoice: boolean = false;
  isAgent: boolean = false;
  storePageLink: string;
  profileSubscription: Subscription;
  controlBoard: any

  constructor(
    public sideBarService: SidebarService,
    public languageService: ChangeLanguageService,
    private globals: Globals,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnDestroy() {
    // this.profileSubscription.unsubscribe();
  }

  ngOnInit() {
    this.controlBoard = control_board
    this.currentUser = JSON.parse(this.globals.currentUser || null)
    this.hasTicketsPermissionOnly = this.checkIfUserHasOnlyTicketsPermission();

    this.isWholesale = this.currentUser?.shop?.is_wholesale;
    this.isAgent = this.currentUser?.shop?.is_agent;
    this.isMarketplaceOnly = this.currentUser?.shop?.is_marketplace_only;
    this.isStoreOnline = this.currentUser?.shop?.online;
    this.isSimpleInvoice = this.currentUser?.shop?.simple_invoicing
    if (localStorage.getItem('lang') == 'ar') {
      this.translate.use('ar')
    }
    else if (localStorage.getItem('lang') == 'en') {
      this.translate.use('en')
    }
    document.querySelector('body').setAttribute('dir', localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr');
  }

  onToggleSideBar() {
    this.sideBarService.changeSideBar()
  }

  openMenu(label: string) {
    if (this.show == 'active' && this.labelItem == label) {
      if (this.sideBarService.getState() == 'open') {
        this.show = 'inActive'
      } else {
        this.onOpenSideBar()
      }
    } else {
      this.onOpenSideBar()
      this.show = 'active'
    }
    this.labelItem = label
  }

  onOpenSideBar() {
    this.sideBarService.openSideBar()
  }

  closeMenu() {
    this.show = 'inActive'
  }


  private checkIfUserHasOnlyTicketsPermission() {
    const user_roles = this.currentUser?.shop?.roles;
    return user_roles?.length == 1 && user_roles[0].key === 'tickets';
  }

  userHasRoles(roles) {
    const userRoles = this.currentUser?.shop?.roles.map(role => role.key);
    return userRoles?.some(userRole => roles.includes(userRole));
  }


  openStorePage() {
    window.open(this.storePageLink, "_blank");
  }

}
