import { Injectable } from '@angular/core';
import { Globals } from '../core/globals';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {

  role: boolean = false;
  userRoles: [] = [];

  private currentUser: any

  constructor(
    private globals: Globals,
  ) {


    this.currentUser = JSON.parse(this.globals.currentUser || null);
    if (this.currentUser) {
      this.userRoles = this.currentUser?.roles.map(element => element.key);
    }


  }

  hasOneRole(roles: string[] = []) {
    return this.userRoles.some(role => roles.includes(role))
  }
  hasRolesOnly(roles: string[] = []) {
    return this.userRoles.every(role => roles.includes(role)) && roles.length == this.userRoles.length
  }
  areRolesExcluded(roles: string[] = []) {
    return this.userRoles.every(role => !roles.includes(role))
  }

}
