import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sales-block-form',
  templateUrl: './sales-block-form.component.html',
  styleUrls: ['./sales-block-form.component.css']
})
export class SalesBlockFormComponent implements OnInit {

  quotation_limit_count: any = ''
  constructor(private dialogRef: MatDialogRef<SalesBlockFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {
    if (this.data) {
      this.quotation_limit_count = this.data.quotation_limit_count
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  submit() {
    let data = {
      "shop": { "quotation_limit_count": this.quotation_limit_count }
    }

    this.dialogRef.close(data);

  }

}
