import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StoreProfileService } from 'src/app/services/store-profile.service';

@Component({
  selector: 'app-shop-category-form',
  templateUrl: './shop-category-form.component.html',
  styleUrls: ['./shop-category-form.component.css']
})
export class ShopCategoryFormComponent implements OnInit {
  actionName: string;
  shop_category_ids = []
  shopeProfileForm = new FormGroup({
    shop_category_ids: new FormControl([]),

  })
  shopCategories: any
  constructor(
    private dialogRef: MatDialogRef<ShopCategoryFormComponent>,
    public translate: TranslateService,
    private storeProfileService: StoreProfileService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getShopCategories()
    if (this.data != null) {
      this.data.shop_categories.forEach(item => {
        this.shop_category_ids.push(item.id)
      });
      this.shopeProfileForm.patchValue({
        shop_category_ids: this.shop_category_ids,
      })
    }
  }

  getShopCategories() {
    let param = {
      all: true
    }
    this.storeProfileService.getShopCategories(param).subscribe((res: any) => {
      this.shopCategories = res.data.items
    })
  }

  changeCategory(category, status) {
    if (status == false) {
      const index: number = this.shop_category_ids.indexOf(category.id)
      this.shop_category_ids.splice(index, 1)
    } else {
      this.shop_category_ids.push(category.id)
    }
    this.shopeProfileForm.patchValue({
      shop_category_ids: this.shop_category_ids,
    })
  }


  onSubmit() {
    let storeProfile = {
      shop: {
        ...this.shopeProfileForm.value,
      }
    }

    if (this.shopeProfileForm.valid) {
      this.dialogRef.close(storeProfile);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

}
