import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { DriversService } from 'src/app/services/drivers.service';
import { WalletFormComponent } from '../../wallet/wallet-form/wallet-form.component';
import { DriverFormComponent } from '../driver-form/driver-form.component';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit {

  defaultImage = 'assets/image/uim_image-v.png'
  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'drivers',
  }
  isFilter: boolean = false
  currentUser: any
  drivers: any
  loadingError = false
  loading = false
  params: any = {}
  page = 1

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private driversService: DriversService,
    private alertify: AlertifyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }


  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map,
        }

        this.page = this.params['page']
      }
      this.getDrivers()
    })
  }

  getDrivers() {
    this.loading = true
    const params = {
      ...this.params,
      page: this.page,
    }
    this.addQueryString(params)
    this.driversService.getDrivers(params).subscribe((res: any) => {
      this.drivers = res.data.items
      this.loading = false
      this.loadingError = false
      this.updatePagination(res.data.pagination)
    }, (error => {
      this.loading = false
      this.loadingError = true
    }))
  }


  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'drivers',
    }
  }


  onPageChangeMarketPlaces(pageNo: any) {
    this.page = pageNo
    this.getDrivers()
  }


  goToDetails(id) {
    this.params = {
      ...this.params,
      page: this.page,
    }
    this.router.navigate([`/drivers/details/${id}`], { queryParams: this.params })
  }


  openFilter() {
    this.isFilter = !this.isFilter
  }


  addQueryString(param) {
    let queryParams: string = param
    let params = new HttpParams()
    for (const [key, value] of Object.entries(queryParams)) {
      params = params.append(key, value)
    }

    window.history.replaceState({}, '', `/drivers?${params.toString()}`)
  }

  clearFilter() {
    window.history.replaceState({}, '', `/drivers`)

    this.page = 1
    this.getDrivers()
  }



  createDrivers() {
    const dialogRef = this.dialog.open(DriverFormComponent, {
      width: '90%',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.driversService.createDrivers(date).subscribe((res: any) => {
          this.getDrivers()
        })
      }

    }, (error => {
      this.loading = false
      this.loadingError = true
    }));
  }

  update(driver: any) {
    const dialogRef = this.dialog.open(DriverFormComponent, {
      width: '90%',
      disableClose: true,
      data: { user: driver }
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.loading = true;
        this.driversService.updateDriver(driver.id, date).subscribe((res: any) => {
          this.getDrivers()
        })
      }

    }, (error => {
      this.loading = false
      this.loadingError = true
    }));
  }

  releaseToDrivers(marketplaceId: any, state: any) {
    let data = { "user": { "active": state } }
    this.driversService.updateDriver(marketplaceId, data).subscribe((res: any) => {
      this.getDrivers()
    })
  }

}
