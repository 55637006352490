import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DriversService } from 'src/app/services/drivers.service';

@Component({
  selector: 'app-assign-driver',
  templateUrl: './assign-driver.component.html',
  styleUrls: ['./assign-driver.component.scss']
})
export class AssignDriverComponent implements OnInit {


  drivers: any;
  truckForm: any = this.fb.group({
    driver_id: ['', Validators.required],
  });

  constructor(private dialogRef: MatDialogRef<AssignDriverComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private driversService: DriversService,
    private fb: FormBuilder,
    public translate: TranslateService,) {

  }



  closeModal() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getDrivers()

  }
  getDrivers() {
    const params = {
      all: true
    }
    this.driversService.getDrivers(params).subscribe((res: any) => {
      this.drivers = res.data.items
    }, (error) => { })
  }





  assignDriver() {
    if (this.truckForm.valid) {
      let data = {
        ride: {
          ...this.truckForm.value
        }
      }

      this.dialogRef.close(data);
    }
  }


}
