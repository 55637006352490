import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { QuotationsService } from 'src/app/services/quotations.service';

@Component({
  selector: 'app-balance-request-form',
  templateUrl: './balance-request-form.component.html',
  styleUrls: ['./balance-request-form.component.scss']
})
export class BalanceRequestFormComponent implements OnInit {

  selectedfile = null;
  reader: FileReader;
  walletForm: any = this.fb.group({
    note: [],
    amount: [, Validators.required],
    attachment_attributes: [],
  });

  constructor(private dialogRef: MatDialogRef<BalanceRequestFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private quotationsService: QuotationsService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public translate: TranslateService,) {
    this.reader = new FileReader();
  }


  closeModal() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  OnFileSelected(event) {
    this.selectedfile = <File>event.target.files[0];
    if (this.selectedfile) {
      const fileTypes = ['jpg', 'png', 'jpeg', 'gif'];
      let name = this.selectedfile.name;
      let array = name.split('.');
      let fileType = array[array.length - 1];
      if (fileTypes.includes(fileType)) {
        this.reader.readAsDataURL(this.selectedfile);
        this.reader.onload = () => { };
      } else {
        this.translate
          .get('MARKETPLACES.messages.mass_file_not_available', {
            value: 'mass_file_not_available',
          })
          .subscribe((res: string) => {
            const dialogRef = this.dialog.open(MessageFormComponent, {
              width: '400px',
              disableClose: true,
              data: { message: res, state: false },

            });
            dialogRef.afterClosed().subscribe(() => {
              this.walletForm.get("attachment_attributes").setValue("");
              this.selectedfile = {}
            });
          });
      }
    }
  }

  createQuotation() {

    if (this.walletForm.valid) {
      let data = {
        balance_request: {
          ...this.walletForm.value
        }
      }
      if (this.walletForm.get('amount').value) {
        data.balance_request.amount = parseFloat(this.walletForm.get('amount').value);
      }

      if (this.selectedfile != null) {
        data.balance_request.attachment_attributes = {
          name: new Date(),
          file: this.reader.result
        };
      }
      this.dialogRef.close(data);
    }
  }


}
