<div class="logo-details">
  <img [defaultImage]="defaultImage" [lazyLoad]="currentUser?.image_url" alt="" class="hidden">
  <div class="logo_name">{{currentUser?.name}}</div>
  <i class='bx bx-menu' id="btn" *ngIf="sideBarService.sideBarSubject.value == 'close'" (click)="onToggleSideBar()"></i>
  <i class='bx bx-menu-alt-right' id="btn" *ngIf="sideBarService.sideBarSubject.value == 'open'"
    (click)="onToggleSideBar()"></i>
</div>

<!-- company: +201015933440
shipping_company: +201221420658
-->

<ul class="nav-list">
  <!-- HOME -->
  <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
    *ngIf="currentUser.user_category == 'super_admin'">
    <a routerLink="/home" class="link" (click)="closeMenu()">
      <i class='bx bx-home'></i>
      <span class="links_name"> {{ "HEADER.HOME.label" | translate }}</span>
    </a>
  </li>
  <!-- /HOME -->
  <!-- <li routerLinkActive="active" *ngIf="currentUser.role == 'admin' && currentUser.type == 'company'">
    <a routerLink="/trucks">
      <i class='bx bxs-truck'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.trucks" | translate }}</span>
    </a>
  </li>

  <li routerLinkActive="active" *ngIf="currentUser.role == 'admin' && currentUser.type == 'company'">
    <a routerLink="/drivers">
      <i class='bx bxs-user-badge'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.drivers" | translate }}</span>
    </a>
  </li> -->

  <li routerLinkActive="active" *ngIf="currentUser.user_category == 'super_admin'">
    <a routerLink="/users">
      <i class='bx bxs-user-badge'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.company" | translate }}</span>
    </a>
  </li>

  <li routerLinkActive="active" *ngIf="currentUser.user_category == 'super_admin'">
    <a routerLink="/manegers">
      <i class='bx bxs-user-badge'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.petrol_station" | translate }}</span>
    </a>
  </li>


  <li routerLinkActive="active" *ngIf="currentUser.user_category == 'super_admin'">
    <a routerLink="/petrol-stations">
      <i class='bx bxs-gas-pump'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.petrol_stations" | translate }}</span>
    </a>
  </li>

  <li routerLinkActive="active" *ngIf="currentUser.user_category == 'super_admin'">
    <a routerLink="/workers">
      <i class='bx bxs-user-badge'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.workers" | translate }}</span>
    </a>
  </li>

  <li routerLinkActive="active" *ngIf="currentUser.user_category == 'super_admin'">
    <a routerLink="/balance-request">
      <i class='bx bx-wallet'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.balance_requests" | translate }}</span>
    </a>
  </li>

  <li routerLinkActive="active" *ngIf="currentUser.user_category == 'super_admin'">
    <a routerLink="/claim">
      <i class='bx bx-wallet'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.claim" | translate }}</span>
    </a>
  </li>

  <li routerLinkActive="active" *ngIf="currentUser.user_category == 'super_admin'">
    <a routerLink="/join">
      <i class='bx bxs-user-badge'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.join_requests" | translate }}</span>
    </a>
  </li>


  <!-- <li routerLinkActive="active" *ngIf="currentUser.user_category == 'super_admin'">
    <a routerLink="/wallet">
      <i class='bx bx-wallet'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.wallet" | translate }}</span>
    </a>
  </li> -->
  <!-- TRIPS -->
  <!-- <li routerLinkActive="active" *ngIf="currentUser.role == 'admin'">
    <a routerLink="/trips">
      <i class='bx bxs-bus-school'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.trips" | translate }}</span>
    </a>
  </li> -->
  <!-- /TRIPS -->

  <!-- +966555221234 factory/company-->
  <!-- <li routerLinkActive="active" *ngIf="currentUser.role == 'admin'">
    <a routerLink="/marketplaces/marketplace_incoming">
      <i class='bx bx-transfer-alt'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.marketplace_incoming" | translate }}</span>
    </a>
  </li> -->

  <!-- +966555111234 shipping company -->
  <!-- <li routerLinkActive="active" *ngIf="currentUser.role == 'admin'">
    <a routerLink="/marketplaces/marketplace_outgoing">
      <i class='bx bx-transfer-alt'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.marketplace_outgoing" | translate }}</span>
    </a>
  </li>
  <li routerLinkActive="active" *ngIf="currentUser.role == 'admin'">
    <a routerLink="/marketplaces/marketplace_outgoing_current">
      <i class='bx bx-doughnut-chart'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.marketplace_outgoing_current" | translate }}</span>
    </a>
  </li>

  <li routerLinkActive="active" *ngIf="currentUser.role == 'admin'">
    <a routerLink="/marketplaces/marketplace_outgoing_history">
      <i class='bx bx-history'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.marketplace_outgoing_history" | translate }}</span>
    </a>
  </li> -->

  <!-- <li routerLinkActive="active" *ngIf="currentUser.user_category.key == 'shipping_company'">
    <a routerLink="/marketplaces/shipping_management">
      <i class='bx bx-bus'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.delivery_management" | translate }}</span>
    </a>
  </li> -->





  <!-- <li routerLinkActive="active">
    <a routerLink="/store-profile">
      <i class='bx bxs-store'></i>
      <span class="links_name"> {{ "HEADER.DATA_MANAGEMENT.store_profile" | translate }}</span>
    </a>
      
  </li> -->
</ul>
