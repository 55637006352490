import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';
import { GetCurrencyService } from 'src/app/core/services/get-currency.service';
import { MarketplacesService } from 'src/app/services/marketplaces.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';

@Component({
  selector: 'app-delivery-management-details',
  templateUrl: './delivery-management-details.component.html',
  styleUrls: ['./delivery-management-details.component.scss']
})
export class DeliveryManagementDetailsComponent implements OnInit {

  defaultImage = 'assets/image/uim_image-v.png'
  marketplace: any;
  loadingError = false;
  loading = false;
  hasAdminRole: boolean = false;
  marketplaceId: any;
  constructor(
    private marketplacesService: MarketplacesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userPermission: UserPermissionService,
    public translate: TranslateService,
    public languageService: ChangeLanguageService,
  ) {

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(prams => {
      this.marketplaceId = prams['id'];
    });
    this.getMarketplace();
    this.hasAdminRole = this.userPermission.hasOneRole(['admin']);
  }



  getMarketplace() {
    this.loading = false;
    this.marketplacesService.getDeliveryManagement(this.marketplaceId).subscribe((res) => {
      this.marketplace = res.data;
      this.loading = false;
      this.loadingError = false;
    }, error => {
      this.loadingError = true;
    });
  }

  backTolist() {
    this.router.navigate(['/marketplaces/shipping_management'], { queryParamsHandling: 'preserve' });
  }







}
