import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateToTimestampService } from 'src/app/services/date-to-timestamp.service';
import { MarketplacesService } from 'src/app/services/marketplaces.service';
import { QuotationsService } from 'src/app/services/quotations.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';

@Component({
  selector: 'app-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss']
})
export class ContractFormComponent implements OnInit {

  startDateFormat: any = null
  endDateFormat: any = null
  exFormat: any = null
  pickupTime: any = null
  returnTime: any = null
  max: any = null
  min: any = null
  minAccept: any = null
  maxAccept: any = null
  currentUser: any
  status = [
    { id: 'pending', value: '' },
    { id: 'done', value: '' },
    { id: 'expired', value: '' },
  ]
  direction = [
    { id: 'one_way', value: '' },
    { id: 'two_way', value: '' },
  ]
  repetition = [
    { id: 'multiple', value: '' },
    { id: 'one_time', value: '' },
  ]
  repetitionTime = [
    { id: 'daily', value: '' },
    { id: 'weekly', value: '' },
    { id: 'monthly', value: '' },
  ]
  packageType: any;
  rides: any;
  cities: any;
  isSubmit: boolean;
  quotationForm: any = this.fb.group({
    contract_to: [, Validators.required],
    contract_from: [, Validators.required],
    expires_at: [, Validators.required],
    direction: [, Validators.required],
    weight: [, Validators.required],
    pickup_time: [, Validators.required],
    delivery_time: [, Validators.required],
    ride_type_id: [, Validators.required],
    package_type_id: [, Validators.required],
    repetition: [, Validators.required],
    repetition_time: [, Validators.required],
    number_of_shippers: [, Validators.required],
    address_line1from: [, Validators.required],
    city_id_from: [, Validators.required],
    address_line1to: [, Validators.required],
    city_id_to: [, Validators.required],
  });

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private marketplacesService: MarketplacesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userPermission: UserPermissionService,
    private quotationsService: QuotationsService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private dateToTimestamp: DateToTimestampService,
    public translate: TranslateService,) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser-b2p'));




  }


  selectRepetition(value): void {
    if (value == 'one_time') {
      this.quotationForm.get('repetition_time').setValue('');
      this.quotationForm.get('contract_to').setValue('');
      this.quotationForm.get('repetition_time').clearValidators();
      this.quotationForm.get('repetition_time').updateValueAndValidity();
      this.quotationForm.get('contract_to').clearValidators();
      this.quotationForm.get('contract_to').updateValueAndValidity();
      this.quotationForm.updateValueAndValidity()

    } else {
      this.quotationForm.get('contract_to').setValidators(Validators.required);
      this.quotationForm.get('repetition_time').setValidators(Validators.required);
      this.quotationForm.updateValueAndValidity()

    }
  }

  selectDirection(value): void {
    if (value == 'one_way') {
      this.quotationForm.get('delivery_time').setValue('');
      this.quotationForm.get('delivery_time').clearValidators();
      this.quotationForm.get('delivery_time').updateValueAndValidity();
      this.quotationForm.updateValueAndValidity()
    } else {
      this.quotationForm.get('delivery_time').setValidators(Validators.required);
      this.quotationForm.updateValueAndValidity()
    }
  }


  ngOnInit() {
    this.maxAccept = new Date()
    this.min = new Date()
    this.getCities()
    this.getRide()
    this.getPackageType()


    setTimeout(() => {
      this.repetition.map((item: any) => {
        this.translate.get('MARKETPLACES.extras.' + item.id, { value: item.id }).subscribe((res: string) => {
          item.value = res
        });
      });
      this.direction.map((item: any) => {
        this.translate.get('MARKETPLACES.extras.' + item.id, { value: item.id }).subscribe((res: string) => {
          item.value = res
        });
      });
      this.repetitionTime.map((item: any) => {
        this.translate.get('MARKETPLACES.extras.' + item.id, { value: item.id }).subscribe((res: string) => {
          item.value = res
        });
      });
    })

  }


  getRide() {
    const params = {
      all: true
    }
    this.quotationsService.getRide(params).subscribe((res: any) => {
      this.rides = res.data.items
    }, (error) => { })
  }
  getCities() {
    const params = {
      all: true,
      country_id: this.currentUser.country_id
    }
    this.quotationsService.getCities(params).subscribe((res: any) => {
      this.cities = res.data.items
    }, (error) => { })
  }
  getPackageType() {
    const params = {
      all: true
    }
    this.quotationsService.getPackageType(params).subscribe((res: any) => {
      this.packageType = res.data.items
    }, (error) => { })
  }
  ngOnDestroy() {
  }
  setDateBox(event, name) {
    console.log(event);

    if (name == 'contract_from') {
      this.startDateFormat = this.dateToTimestamp.convertDateToTimestamp(event)
      this.min = event.target.value


      this.minAccept = this.minOneDay(new Date(event.target.value))
    } else if (name == 'contract_to') {
      this.endDateFormat = this.dateToTimestamp.convertDateToTimestamp(event)
      this.max = event.target.value
    } else {
      this.exFormat = this.dateToTimestamp.convertDateToTimestamp(event)
    }
    if (name === 'contract_from') {
      this.quotationForm.get('contract_from').clearValidators();
      this.quotationForm.get('contract_from').updateValueAndValidity();
      this.quotationForm.updateValueAndValidity()
    } else if (name === 'contract_to') {
      this.quotationForm.get('contract_to').clearValidators();
      this.quotationForm.get('contract_to').updateValueAndValidity();
      this.quotationForm.updateValueAndValidity()
    } else if (name === 'expires_at') {
      this.quotationForm.get('expires_at').clearValidators();
      this.quotationForm.get('expires_at').updateValueAndValidity();
      this.quotationForm.updateValueAndValidity()
    }
  }

  addOneDay(date = new Date()) {
    new Date(date.setDate(date.getDate() + 1));
    return date;
  }
  minOneDay(date = new Date()) {
    new Date(date.setDate(date.getDate() - 1));
    return date;
  }


  infoFormFormatted() {
    this.quotationForm.patchValue({
      contract_from: this.startDateFormat,
      contract_to: this.endDateFormat,
      expires_at: this.exFormat
    })

    if (this.quotationForm.get('pickup_time').value != '') {
      this.pickupTime = (this.quotationForm.get('pickup_time').value.getTime() - this.quotationForm.get('pickup_time').value.getTimezoneOffset() * 60000) / 1000
    }
    if (this.quotationForm.get('delivery_time').value) {
      this.returnTime = (this.quotationForm.get('delivery_time').value.getTime() - this.quotationForm.get('delivery_time').value.getTimezoneOffset() * 60000) / 1000
    }

  }


  createContract() {
    this.isSubmit = true;
    if (this.quotationForm.valid) {
      this.infoFormFormatted();

      let data = {
        quotation_request: {
          from_address: {
            address_line1: this.quotationForm.get('address_line1from').value,
            city_id: this.quotationForm.get('city_id_from').value,
          },
          to_address: {
            address_line1: this.quotationForm.get('address_line1to').value,
            city_id: this.quotationForm.get('city_id_to').value,
          },
          contract_to: this.quotationForm.get('contract_to').value,
          contract_from: this.quotationForm.get('contract_from').value,
          expires_at: this.quotationForm.get('expires_at').value,
          direction: this.quotationForm.get('direction').value,
          weight: this.quotationForm.get('weight').value,
          pickup_time: this.pickupTime,
          delivery_time: this.returnTime,
          ride_type_id: this.quotationForm.get('ride_type_id').value,
          number_of_shippers: this.quotationForm.get('number_of_shippers').value,
          package_type_id: this.quotationForm.get('package_type_id').value,
          repetition: this.quotationForm.get('repetition').value,
          repetition_time: this.quotationForm.get('repetition_time').value,
        }
      }
      console.log(data);

      this.marketplacesService.createContract(data).subscribe((res: any) => {
        this.router.navigate(['/marketplaces/marketplace_outgoing'], { queryParamsHandling: 'preserve' });
      })
    }
  }


}
