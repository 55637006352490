// globals.ts
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Globals {
  static phonePattern = '^((\\+91-?)|0)?[0-9]{10}$';
  static oneDriveUrl = 'https://view.officeapps.live.com/op/view.aspx?src=';
  // static readonly V1Header: any = {
  //   'accept': 'application/vnd.skolera.v1'
  // };
  currentUser: any
  sessionHeaders: any = null;
  siteHasMessage: boolean = false;
  siteMessage: string = '';
  siteMessageType: string = '';
  componentLoader: boolean = false;
  unreadMessagesCount = 0;

  applyGradingSystemCourses = null;
  updateUserLanguage: Subject<string> = new Subject<string>();
  viewAs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  viewCourseAs = null;
  currentCourseParams: Subject<any> = new Subject<any>();
  systemAlerts = {
    noConnection: false,
    newVersion: false
  };
  screenType = "desktop";


  showMessage(type: string, message: string) {
    this.siteHasMessage = true;
    this.siteMessageType = type;
    this.siteMessage = message;
  }

  hideMessage() {
    this.siteHasMessage = false;
    this.siteMessageType = '';
    this.siteMessage = '';
  }
}

