<div class="modal-wrapper">
    <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
    <div class="modal-header">
        <p class="w-full text-center">
            {{ "COVERAGEZONE.extras.location" | translate }}
        </p>
    </div>
    <div class="modal-content">
        <div class="modal-body">
            <div class="form-group mlr-3 mt-3">
                <input class="form-control" [placeholder]="'COVERAGEZONE.extras.search'| translate" autocorrect="on"
                    autocapitalize="off" spellcheck="off" (keydown.enter)="$event.preventDefault()" #search type="text">
            </div>
            <agm-map [latitude]="lat" [longitude]="lng" (mapClick)="onChoseLocation($event)" [zoom]="zoom">
                <agm-marker [latitude]="lat" [longitude]="lng">
                    <agm-info-window>
                        <strong>{{ address?.LongLabel }}</strong>
                    </agm-info-window>
                </agm-marker>
            </agm-map>

            <div class="modal-footer border-top text-left">
                {{address}}
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary mr-2 spec-selected_location" (click)="getInfoMap()">{{
                "COMMON.confirmed" | translate }}</button>
            <button class="btn btn-default" (click)="closeModal()">{{ "COMMON.cancel" | translate }}</button>
        </div>
    </div>
</div>