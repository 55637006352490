import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiKeyMap } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  lang: string = 'ar';
  constructor(private http: HttpClient) {
    this.lang = localStorage.getItem('lang');
  }
  key = apiKeyMap;
  urlLocation = 'https://maps.googleapis.com/maps/api/geocode/json';
  // urlPlace = 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json'

  getLocation(latlng) {
    return this.http.get(
      `${this.urlLocation}?latlng=${latlng}&language=${this.lang}&key=${this.key}`
    );
  }

  // getPlace(address) {
  //   let fields = ['photos', 'formatted_address', 'name', 'rating', 'opening_hours', 'geometry']
  //   return this.http.get(`${this.urlPlace}?input=${address}&inputtype=textquery&fields=${fields}&key=${this.key}`)
  // }
}
