<div class="component-loader" (errorCallback)="getDriver()" [errorMessage]="'lOADER.errorMessage' | translate "
  component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
  [componentLoading]="loading">



  <!--details content-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.actions.details_driver" | translate }}</h2>
      <div>
        <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="update()"> {{
          "COMMON.edit" | translate }} </button>
        <button class="btn btn-default mb-2" (click)="backTolist()" *ngIf="tripId == null">
          {{ "DRIVERS.actions.back_drivers" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>
        <button class="btn btn-default mb-2" (click)="backToDetails()" *ngIf="tripId != null">
          {{ "DRIVERS.actions.back_to_trip_details" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>


      </div>
    </div>
    <div class=" grid grid-cols-4 gap-3 p-4">
      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.name" | translate }}</p>
        <span class="tabel-content"> {{ maneger?.name }}</span>
      </div>


      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.phone" | translate }}</p>
        <span class="tabel-content">
          {{ maneger?.phone }}
        </span>
      </div>

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.email" | translate }}</p>
        <span class="tabel-content">
          {{ maneger?.email }}
        </span>
      </div>


      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.user_category" | translate }}</p>
        <span class="tabel-content">
          {{ ("DRIVERS.extras." + maneger?.user_category) | translate }}
        </span>
      </div>



      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{
          "DRIVERS.extras.profile"
          | translate
          }}</p>
        <span class="tabel-content">
          <img class="w-16 rounded-md pointer" (click)="onClickImageNewTab($event.target.src)"
            [defaultImage]="defaultImage" [lazyLoad]="maneger?.image_url" />
        </span>
      </div>








    </div>

  </div>




</div>
