<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "DRIVERS.actions.addExtraCharges" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <form [formGroup]="truckForm">
          <div class="row mb-2">



            <div class="col-md-6 " [class.has-error]="!truckForm.get('extra_charges').valid && isSubmit">
              <div class="form-group">
                <label class="mb-2"> {{ "DRIVERS.extras.extra_charges" | translate }} </label>
                <input class="form-control" type="text" formControlName="extra_charges" appFloatNumber="$event" />
              </div>
            </div>





          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="addExtraCharges()" [disabled]="truckForm.invalid">
        {{ "DRIVERS.actions.addExtraCharges" | translate }}

      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>