import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManegersComponent } from './manegers/manegers.component';
import { ManegerDetailsComponent } from './maneger-details/maneger-details.component';

const routes: Routes = [
  { path: '', component: ManegersComponent, data: { title: 'Manegers' } },
  { path: 'details/:id', component: ManegerDetailsComponent, data: { title: 'Manegers Details' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManegerRoutingModule { }
