import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';
import { MarketplacesService } from 'src/app/services/marketplaces.service';
import { GetCurrencyService } from 'src/app/core/services/get-currency.service';
import { saveAs } from 'file-saver';
import { ConfirmationFormComponent } from 'src/app/core/components/confirmation-form/confirmation-form.component';
import { MarketplaceOfferFormComponent } from '../marketplace-offer-form/marketplace-offer-form.component';
import { TripsService } from 'src/app/services/trips.service';
import { Globals } from 'src/app/core/globals';

@Component({
  selector: 'app-marketplace-details',
  templateUrl: './marketplace-details.component.html',
  styleUrls: ['./marketplace-details.component.scss']
})
export class MarketplaceDetailsComponent implements OnInit {
  defaultImage = 'assets/image/uim_image-v.png'

  marketplace: any;
  url: any = '';
  isSalseReports = false;
  printInvoice = false;
  loadingError = false;
  loading = false;
  hasAdminRole: boolean = false;
  massage: any
  pathTo = ''
  marketplaceId: any;
  currency: any
  page = 1
  trips: any[] = []
  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'trips',
  }
  params: any = {}
  currentUser: any
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private marketplacesService: MarketplacesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userPermission: UserPermissionService,
    public translate: TranslateService,
    private alertify: AlertifyService,
    private dialog: MatDialog,
    public languageService: ChangeLanguageService,
    private getCurrencyService: GetCurrencyService,
    private tripsService: TripsService,
    private globals: Globals,


  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(prams => {
      this.marketplaceId = prams['id'];
    });
    this.getCurrency()
    this.getMarketplace();
    this.getTrips()
    this.hasAdminRole = this.userPermission.hasOneRole(['admin']);
  }



  getMarketplace() {
    this.loading = false;
    this.marketplacesService.getMarketplace(this.marketplaceId).subscribe((res) => {
      this.marketplace = res.data;
      this.loading = false;
      this.loadingError = false;
    }, error => {
      this.loadingError = true;
    });
  }

  getTrips() {
    this.loading = true
    const params = {
      quotation_request_id: this.marketplaceId,
      page: this.page,
    }
    this.tripsService.getTrips(params).subscribe((res: any) => {
      this.trips = res.data.items
      this.loading = false
      this.loadingError = false
      this.updatePagination(res.data.pagination)
    })
  }
  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'trips',
    }
  }

  onPageChangeMarketPlaces(pageNo: any) {
    this.page = pageNo
    this.getTrips()
  }

  getCurrency() {
    this.currency = this.getCurrencyService.getCurrency()
  }

  backTolist() {
    this.router.navigate(['/marketplaces/marketplace_incoming'], { queryParamsHandling: 'preserve' });
  }

  completeProcedures(number) {
    const massage = `نرجوا التواصل بخصوص الطلب رقم : ${number}`
    this.router.navigate([]).then(result => { window.open(`https://wa.me/966582320772?text=${massage}%20`, '_blank'); });
  }

  createMarketplaceForm() {
    const dialogRef = this.dialog.open(MarketplaceOfferFormComponent, {
      width: '500px',
      disableClose: true,
      data: { quotation_request_id: this.marketplaceId }
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data != null) {
        this.marketplacesService.createOffer(data).subscribe((res) => {
          this.translate.get('MARKETPLACES.messages.add_offer', { value: 'add_offer' }).subscribe((res: string) => {
            this.alertify.success(res);
          });
          this.getMarketplace();
        }, (error) => {
          this.translate.get('MARKETPLACES.messages.error_message', { value: 'error_message' }).subscribe((res: string) => {
            this.alertify.error(res);
          });
        });
      }
    });
  }


  copyAddress(address: any) {
    let message = address
    let selBox = this.document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = message;
    this.document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(selBox);

    this.translate
      .get('ORDERS.messages.copy_address', { value: 'copy_address' })
      .subscribe((res: string) => {
        this.alertify.success(res);
      });
  }

  goToDetails(id) {
    this.params = {
      ...this.params,
      page: this.page,
    }
    this.router.navigate([`/trips/details/${id}`], { queryParams: this.params })
  }


}
