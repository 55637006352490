import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-extra-charges',
  templateUrl: './add-extra-charges.component.html',
  styleUrls: ['./add-extra-charges.component.scss']
})
export class AddExtraChargesComponent implements OnInit {

  isSubmit: boolean = false;
  startDateFormat: any = null
  max: any = null
  min: any = null
  truckForm: any = this.fb.group({
    extra_charges: ['', Validators.required],
  });

  constructor(private dialogRef: MatDialogRef<AddExtraChargesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public translate: TranslateService,) {
  }





  closeModal() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  addExtraCharges() {
    this.isSubmit = true;
    if (this.truckForm.valid) {
      let data = {
        shipment_reschedule_request: {
          ...this.truckForm.value
        }
      }


      this.dialogRef.close(data);
    }
  }


}
