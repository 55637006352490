import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from 'src/app/core/globals';
import { AlertifyService } from 'src/app/core/services/alertify.services';

@Component({
  selector: 'app-re-activat-acounts',
  templateUrl: './re-activat-acounts.component.html',
  styleUrls: ['./re-activat-acounts.component.scss']
})
export class ReActivatAcountsComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private dialog: MatDialog,
    public globals: Globals,
  ) { }

  ngOnInit(): void {
  }
  goLogin() {
    this.router.navigate([`/login`])
  }

  openWhatsApp(){
    window.open("https://wa.me/966582320772", "_blank")
  }

}
