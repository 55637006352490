import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss'],
})
export class MessageFormComponent implements OnInit {
  state = false;
  message: string;
  constructor(
    private dialogRef: MatDialogRef<MessageFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.message = this.data.message;
    this.state = this.data.state;
  }

  closeModal() {
    this.dialogRef.close(this.state);
  }

  done() {
    this.dialogRef.close(true);
  }
}
