<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "STOREPROFILE.action.No_of_expired_quotations_to_block_sales" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">

        <div class="col-md-12">
          <div class="form-group">
            <input class="form-control " type="number" appNumberOnly="$event" [(ngModel)]="quotation_limit_count">
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary mb-2" (click)="submit()">
        {{ 'COMMON.confirm' | translate }}
      </button>
    </div>
  </div>