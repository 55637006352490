import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ReActivatAcountsComponent } from './re-activat-acounts/re-activat-acounts.component';
import { googleTitles } from '../main/googleTitle';


const routes: Routes = [
  {
    path: 'activate-account',
    component: ReActivatAcountsComponent
    , data: { title: googleTitles.activateAccount }
  },
  {
    path: 'registration',
    component: RegistrationComponent
    , data: { title: googleTitles.registration }
  },
  {
    path: 'login',
    component: LoginComponent
    , data: { title: googleTitles.login }
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
    , data: { title: googleTitles.termsAndConditions }
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
