<div class="component-loader" (errorCallback)="getHomePageData()" [errorMessage]="'lOADER.errorMessage' | translate "
  component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
  [componentLoading]="loading">


  <ng-container>
    <h1 class="my-4">
      {{ "HOME.extra.collections" | translate }}
    </h1>
    <div class="wrapper grid grid-cols-1 md:grid-cols-6  grid-rows-2 gap-3 mt-3 ">


      <div class=" col-span-3  md:col-span-6  lg:col-span-3 md:row-span-2 lg:row-span-2 row-span-1" *ngIf="!isloading">
        <div class=" col-span-6 lg:col-span-3  mg:col-span-1 row-span-1 mt-2" *ngIf="!isloading">
          <div class="wrapper grid grid-cols-4 md:grid-cols-6 grid-rows-1 gap-1 ">
            <div class="card card-non-shadow col-span-2 md:col-span-3 lg:col-span-3 row-span-1 " *ngIf="!isloading">
              <p> {{ "HOME.extra.costomers" | translate }} </p>
              <div class="flex justify-end items-center">
                <div>
                  <p class="p-red block text-3xl">{{homeDataInfo?.customers_count}}</p>
                  <span class="block text-black text-sm text-center">{{ "HOME.extra.costomer" | translate
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="card card-non-shadow col-span-2 md:col-span-3 lg:col-span-3 row-span-1 " *ngIf="!isloading">
              <p> {{ "HOME.extra.new_requests" | translate }}</p>
              <div class="flex justify-end items-center">
                <div>
                  <p class="p-red block text-3xl">{{homeDataInfo?.join_requests_count}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>`
        <div class=" col-span-6 lg:col-span-3  mg:col-span-1 row-span-1 mt-2" *ngIf="!isloading">
          <div class="wrapper grid grid-cols-4 md:grid-cols-6 grid-rows-1 gap-1 ">
            <div class="card card-non-shadow col-span-2 md:col-span-3 lg:col-span-3 row-span-1 " *ngIf="!isloading">
              <p> {{ "HOME.extra.transactions" | translate }} </p>
              <div class="flex justify-end items-center">
                <div>
                  <p class="p-red block text-3xl">{{homeDataInfo?.total_transaction}}</p>
                  <span class="block text-black text-sm text-center">{{ "CURRENCY.sr" | translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="card card-non-shadow col-span-2 md:col-span-3 lg:col-span-3 row-span-1 " *ngIf="!isloading">
              <p> {{ "HOME.extra.balance_requests_accepted" | translate }}</p>
              <div class="flex justify-end items-center">
                <div>
                  <p class="p-red block text-3xl">{{homeDataInfo?.total_balance_requests}}</p>
                  <span class="block text-black text-sm text-center">{{ "CURRENCY.sr" | translate }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-span-6 lg:col-span-3  mg:col-span-1 row-span-1 mt-2" *ngIf="!isloading">
          <div class="wrapper grid grid-cols-4 md:grid-cols-6 grid-rows-1 gap-1 ">
            <div class="card card-non-shadow col-span-2 md:col-span-3 lg:col-span-3 row-span-1 " *ngIf="!isloading">
              <p> {{ "HOME.extra.drivers" | translate }} </p>
              <div class="flex justify-end items-center">
                <div>
                  <p class="p-red block text-3xl">{{homeDataInfo?.company_drivers}}</p>

                </div>
              </div>
            </div>
            <div class="card card-non-shadow col-span-2 md:col-span-3 lg:col-span-3 row-span-1 " *ngIf="!isloading">
              <p> {{ "HOME.extra.station_workers" | translate }}</p>
              <div class="flex justify-end items-center">
                <div>
                  <p class="p-red block text-3xl">{{homeDataInfo?.petrol_station_workers}}</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="card card-non-shadow col-span-2 md:col-span-6 lg:col-span-1  row-span-2  lg:row-span-2"
        *ngIf="!isloading">
        <p> {{ "HOME.extra.new_requests" | translate }} </p>
        <canvas baseChart [data]="chartDatasetsPie1" [labels]="chartLabelsPie1" [chartType]="chartTypePie1"
          [colors]="chartColorsPie1" [options]="chartOptions" [legend]="true">
        </canvas>
        <div class="flex justify-between items-center mt-12 flex-wrap">
          <div>
            <div class="text-center flex flex-wrap">
              <i class='bx bxs-circle p-red text-2xl mx-1'></i>
              <p>{{ "HOME.extra.pending" | translate }}</p>
            </div>
            <p class="text-3xl p-red">{{homeDataInfo.invoce_cash_ratio}}%</p>
          </div>
          <div>
            <div class="text-center flex flex-wrap">
              <i class='bx bxs-circle p-red2 text-2xl mx-1'></i>
              <p>{{ "HOME.extra.finished" | translate }}</p>
            </div>
            <p class="text-3xl p-red2">{{homeDataInfo.invoce_later_ratio}}%</p>
          </div>

        </div>
      </div>
      <div class="card card-non-shadow col-span-2 md:col-span-6 lg:col-span-1  row-span-2  lg:row-span-2 "
        *ngIf="!isloading">
        <p> {{ "HOME.extra.balance_requests" | translate }} </p>
        <!-- <apx-chart [series]="chartOptions3.series" [chart]="chartOptions3.chart" [fill]="chartOptions2.fill"
          [dataLabels]="chartOptions3.dataLabels" [colors]="chartOptions3?.colors"
          [responsive]="chartOptions3.responsive"></apx-chart> -->
        <canvas baseChart [data]="chartDatasetsPie2" [labels]="chartLabelsPie2" [chartType]="chartTypePie2"
          [colors]="chartColorsPie2" [options]="chartOptions" [legend]="true">
        </canvas>
        <div class="flex justify-between items-center mt-12 flex-wrap ">
          <div>
            <div class="text-center flex ">
              <i class='bx bxs-circle p-blue text-2xl mx-1 flex-wrap'></i>
              <p>{{ "HOME.extra.finished" | translate }}</p>
            </div>
            <p class="text-3xl p-blue">{{homeDataInfo.pending_orders_ratio}}%</p>
          </div>
          <div>
            <div class="text-center flex">
              <i class='bx bxs-circle p-blue2 text-2xl mx-1 flex-wrap'></i>
              <p>{{ "HOME.extra.pending" | translate }}</p>
            </div>
            <p class="text-3xl p-blue2">{{homeDataInfo.delivered_orders_ratio}}%</p>
          </div>

        </div>
      </div>
      <div class="card card-non-shadow col-span-2 md:col-span-6 lg:col-span-1   row-span-2 lg:row-span-2  "
        *ngIf="!isloading">
        <p> {{ "HOME.extra.claim_requests" | translate }} </p>
        <canvas baseChart [data]="chartDatasetsPie3" [labels]="chartLabelsPie3" [chartType]="chartTypePie3"
          [colors]="chartColorsPie3" [options]="chartOptions" [legend]="true">
        </canvas>
        <div class="flex justify-between items-center mt-12 flex-wrap ">
          <div>
            <div class="text-center flex ">
              <i class='bx bxs-circle p-green text-2xl mx-1 flex-wrap'></i>
              <p>{{ "HOME.extra.pending" | translate }}</p>
            </div>
            <p class="text-3xl p-green">{{homeDataInfo.invoice_sell_ratio}}%</p>
          </div>
          <div>
            <div class="text-center flex">
              <i class='bx bxs-circle p-green2 text-2xl mx-1 flex-wrap'></i>
              <p>{{ "HOME.extra.finished" | translate }}</p>
            </div>
            <p class="text-3xl p-green2">{{homeDataInfo.invoce_refund_ratio}}%</p>
          </div>

        </div>
      </div>





    </div>

    <!-- <h1 class="my-4">
      {{ "HOME.extra.buying" | translate }}
    </h1>
    <div class="wrapper grid grid-cols-2 md:grid-cols-2 lg:grid-span-1 grid-rows-1 gap-3 ">

      <div class="card card-non-shadow col-span-2 md:col-span-6 lg:col-span-1  row-span-1">
        <p> {{ "HOME.extra.buying_daly" | translate }} </p>
        <p class="flex justify-end  px-4"> {{ "HOME.extra.buying" | translate }}</p>
        <div class="flex">
          <p class="flex items-end mb-14"> {{ "HOME.extra.days" | translate }}</p>
          <div class="w-full">
            <canvas baseChart [chartType]="chartOptions2.chat.type" [datasets]="chartOptions2.series"
              [labels]="chartOptions2.xaxis.categories">
            </canvas>
            <canvas baseChart [data]="chartOptions2.series" [labels]="chartLabelsPie2"
              [chartType]="chartOptions2.char.type" [colors]="chartColorsPie2" [options]="chartOptions" [legend]="true">
            </canvas>
            <apx-chart [series]="chartOptions2.series" [chart]="chartOptions2.chart" id="chart"
              [dataLabels]="chartOptions2.dataLabels" [plotOptions]="chartOptions2.plotOptions"
              [yaxis]="chartOptions2.yaxis" [legend]="chartOptions2.legend" [fill]="chartOptions2.fill"
              [stroke]="chartOptions2.stroke" [tooltip]="chartOptions2.tooltip" [colors]="chartOptions2?.colors"
              [xaxis]="chartOptions2.xaxis"></apx-chart>

          </div>
        </div>
      </div>

      <div class="card card-non-shadow col-span-2 md:col-span-6 lg:col-span-1  row-span-1">
        <p> {{ "HOME.extra.buying_monthly" | translate }} </p>
        <p class="flex justify-end px-20"> {{ "HOME.extra.buying" | translate }} </p>
        <div class="flex">
          <p class="flex items-end mb-14"> {{ "HOME.extra.months" | translate }}</p>
          <div class="w-full">
            <apx-chart [series]="chartOptions4.series" [chart]="chartOptions4.chart" [xaxis]="chartOptions4.xaxis"
              [colors]="chartOptions4?.colors" [dataLabels]="chartOptions4.dataLabels" [grid]="chartOptions4.grid"
              [stroke]="chartOptions4.stroke"></apx-chart>
          </div>
        </div>

      </div>

      <div class="card card-non-shadow col-span-2 md:col-span-6 lg:col-span-1  row-span-1" *ngIf="!isloading">
        <p> {{ "HOME.extra.grouth_monthly" | translate }} </p>
        <div class="flex justify-end items-center">
          <div>
            <p class="p-green block text-3xl"> % {{homeDataInfo?.percentage_increase_sales_monthly}}</p>
          </div>
        </div>
      </div>
      <div class="card card-non-shadow col-span-2 md:col-span-6 lg:col-span-1  row-span-1" *ngIf="!isloading">
        <p> {{ "HOME.extra.grouth_yearly" | translate }}</p>
        <div class="flex justify-end items-center">
          <div>
            <p class="p-green block text-3xl"> % {{homeDataInfo?.percentage_increase_sales_yearly}}</p>
          </div>
        </div>
      </div>

    </div> -->

    <!-- <h1 class="my-4">
      {{ "HOME.extra.store_branches_revenues_monthly" | translate }}
    </h1>
    <div class="wrapper grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8  grid-rows-2 gap-3  ">

      <ng-container *ngFor="let item of homeDataInfo?.store_branches_revenues?.items">
        <div class="card card-non-shadow col-span-2 sm:col-span-4 md:col-span-2 row-span-2" *ngIf="!isloading">
          <p> {{item?.name}} </p>

          <div class="flex justify-around items-center px-4">
            <div class="block ">
              <circle-progress [percent]="item?.monthly_branch_revenue_ratio" [radius]="100"
                [backgroundGradient]="false" [backgroundColor]="'#CCF8FE'" [backgroundGradientStopColor]="'#02A0FC'"
                [backgroundPadding]="-10" [maxPercent]="100" [titleFontSize]="28" [unitsFontSize]="28" class="w-40"
                [outerStrokeWidth]="10" [outerStrokeColor]="'#61A9DC'" [innerStrokeWidth]="0" [showInnerStroke]="false"
                [startFromZero]="false" [animationDuration]="300"></circle-progress>
              <div class="flex justify-center items-center">
                <h2 class="">
                  {{ "HOME.extra.monthly" | translate }}
                </h2>
              </div>
            </div>
            <div class="block ">
              <circle-progress [percent]="item?.yearly_branch_revenue_ratio" [radius]="100" [backgroundGradient]="false"
                [backgroundColor]="'#FFF5CC'" [backgroundGradientStopColor]="'#02A0FC'" [backgroundPadding]="-10"
                [maxPercent]="100" [unitsFontSize]="28" [titleFontSize]="28" [outerStrokeWidth]="10" class="w-40"
                [outerStrokeColor]="'#FFB200'" [innerStrokeWidth]="0" [showInnerStroke]="false" [startFromZero]="false"
                [animationDuration]="300"></circle-progress>
              <div class="flex justify-center items-center">
                <h2 class="">
                  {{ "HOME.extra.yearly" | translate }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div> -->
  </ng-container>
