<div class="component-loader" (errorCallback)="geWallet()" [errorMessage]="'lOADER.errorMessage' | translate "
  component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
  [componentLoading]="loading">



  <!--details content-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "MARKETPLACES.extras.marketplace_details" | translate }}</h2>
      <div>
        <button class="btn btn-default mb-2" (click)="backTolist()">
          {{ "MARKETPLACES.actions.back_to_marketplace_outgoing" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>


      </div>
    </div>
    <div class=" grid grid-cols-4 gap-3 p-4">
      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "MARKETPLACES.extras.marketplace_number" | translate }}</p>
        <span class="tabel-content"> {{ wallet?.number }}</span>
      </div>


      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.amount"
          | translate
          }}</p>
        <span class="tabel-content">
          {{ wallet?.amount }}
        </span>
      </div>

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{
          "QUOTION.attributes.status.label"
          | translate
          }}</p>
        <span class="tabel-content">
          {{ ("QUOTION.attributes.status." + wallet?.status) | translate }}
        </span>
      </div>


      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "MARKETPLACES.extras.image" | translate }} </p>
        <span class="tabel-content">
          <img class="w-10 rounded-md pointer" (click)="onClickImageNewTab($event.target.src)"
            [defaultImage]="defaultImage" [lazyLoad]="wallet?.image_url" />
        </span>
      </div>
      <div class="col-span-8 md:col-span-2 row-span-2">
        <p>{{
          "MARKETPLACES.extras.note"
          | translate
          }}</p>
        <span class="tabel-content">
          {{ wallet?.note }}
        </span>
      </div>


    </div>

  </div>