import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('superadmin-token') || '';
    const lang = localStorage.getItem('lang') || '';
    const uuid = localStorage.getItem('uuid') || '';
    const isGoogleApi = request.url.includes('https://maps.googleapis.com');
    if (!isGoogleApi) {
      const requestWithAuth = request.clone({
        // url: baseUrl + request.urlWithParams,
        headers: request.headers
          .set('Authorization', token)
          .set('lang', lang)
          .set('uuid', uuid)
          .set('Accept', '*/*')
          .set('superadminuser', "true")
      });
      return next.handle(requestWithAuth);
    } else {
      const requestWithAuth = request.clone({
        headers: request.headers
      });
      return next.handle(requestWithAuth);
    }

  }
}

export const ApiInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiInterceptor,
  multi: true
};
