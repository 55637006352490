import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationFormComponent } from 'src/app/core/components/confirmation-form/confirmation-form.component';
import { Globals } from 'src/app/core/globals';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';
import { GetCurrencyService } from 'src/app/core/services/get-currency.service';
import { MarketplacesService } from 'src/app/services/marketplaces.service';
import { TripsService } from 'src/app/services/trips.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';


@Component({
  selector: 'app-marketplace-outgoing-details',
  templateUrl: './marketplace-outgoing-details.component.html',
  styleUrls: ['./marketplace-outgoing-details.component.css']
})
export class MarketplaceOutgoingDetailsComponent implements OnInit {
  defaultImage = 'assets/image/uim_image-v.png'
  marketplace: any;
  url: any = '';
  isSalseReports = false;
  printInvoice = false;
  loadingError = false;
  loading = false;
  hasAdminRole: boolean = false;
  massage: any
  marketplaceId: any;
  currency: any
  params: any = {}
  page = 1
  trips: any[] = []
  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'trips',
  }
  currentUser: any
  tripId: any = null
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private marketplacesService: MarketplacesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userPermission: UserPermissionService,
    public translate: TranslateService,
    private alertify: AlertifyService,
    private dialog: MatDialog,
    public languageService: ChangeLanguageService,
    private tripsService: TripsService,
    private getCurrencyService: GetCurrencyService,
    private globals: Globals,


  ) {

    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map
        }
        if (this.params['tripId'] != null) {
          this.tripId = this.params['tripId']
        }
      }

    })
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.globals.currentUser || null)
    this.activatedRoute.params.subscribe(prams => {
      this.marketplaceId = prams['id'];
      this.params.marketplaceId = this.marketplaceId
    });
    this.getCurrency()
    this.getMarketplace();
    this.getTrips()
    this.hasAdminRole = this.userPermission.hasOneRole(['admin']);
  }


  getMarketplace() {
    this.loading = true;
    this.marketplacesService.getMarketplace(this.marketplaceId).subscribe((res) => {
      this.marketplace = res.data;
      // this.getBankAccounts(this.marketplace.bank_account_shop_id)
      this.loading = false;
      this.loadingError = false;
    }, error => {
      this.loadingError = true;
    });
  }

  getTrips() {
    this.loading = true
    const params = {
      quotation_request_id: this.marketplaceId,
      page: this.page,
    }
    this.tripsService.getTrips(params).subscribe((res: any) => {
      this.trips = res.data.items
      this.loading = false
      this.loadingError = false
      this.updatePagination(res.data.pagination)
    })
  }
  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'trips',
    }
  }

  onPageChangeMarketPlaces(pageNo: any) {
    this.page = pageNo
    this.getTrips()
  }


  getCurrency() {
    this.currency = this.getCurrencyService.getCurrency()
  }

  backTolist() {
    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map,
        }

        if (this.params['status'] == 'pending') {
          this.router.navigate(['/marketplaces/marketplace_outgoing_current'], { queryParamsHandling: 'preserve' });

        }
        else if (this.params['status'] == 'history') {
          this.router.navigate(['/marketplaces/marketplace_outgoing_history'], { queryParamsHandling: 'preserve' });

        } else {
          this.router.navigate(['/marketplaces/marketplace_outgoing'], { queryParamsHandling: 'preserve' });
        }
      }
    })


  }

  acceptQuotation(offer) {
    this.translate
      .get('MARKETPLACES.messages.offer_total', {
        value: 'offer_total',
      })
      .subscribe((res2: string) => {
        this.translate
          .get('MARKETPLACES.messages.acceptQuotation', {
            value: 'acceptQuotation',
          })
          .subscribe((res: string) => {
            const massageAll = `${res} ${offer.user?.name}  ${res2}  ${offer.total} `
            const dialogRef = this.dialog.open(ConfirmationFormComponent, {
              width: '50%',
              disableClose: true,
              data: { component: 'acceptQuotation', message: massageAll }
            });
            dialogRef.afterClosed().subscribe((mood) => {
              if (mood != false) {
                const data = {
                  "quotation_id": offer.id
                }
                this.loading = true;
                this.marketplacesService.acceptQuotation(data).subscribe((res: any) => {
                  this.getMarketplace()
                }, (error: any) => {
                  this.loading = false;
                  if (error.error.code == 400) {
                    this.alertify.error(error.error.error);
                  }
                })
              }

            });
          });
      });

  }

  backToDetails() {
    this.router.navigate([`/trips/details/${this.tripId}`], { queryParamsHandling: 'preserve' });
  }

  goToDetails(id) {
    this.params = {
      ...this.params,
      page: this.page,
    }
    this.router.navigate([`/trips/details/${id}`], { queryParams: this.params })
  }


}
