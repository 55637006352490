<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      <ng-container *ngIf="data == null">
        {{ "DRIVERS.actions.add_new_station"| translate }}
      </ng-container>
      <ng-container *ngIf="data != null">
        {{ "DRIVERS.actions.edit_station" | translate }}
      </ng-container>

    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <form [formGroup]="petrolStationForm">
          <div class="row mb-2">

            <div class="col-md-6" [class.has-error]="!petrolStationForm.get('name').valid && isSubmit">
              <div class="form-group">
                {{ "DRIVERS.extras.name" | translate }}
                <input class="form-control" type="text" formControlName="name" />
              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group" [class.has-error]="
                              !petrolStationForm.get('address_line1').valid && isSubmit
                            ">
                <label class="mb-2">{{ "WAREHOUSES.attributes.address.label" | translate }}</label>
                <input class="form-control spec-address_line1" type="text" formControlName="address_line1" />
              </div>
            </div>



            <div class="col-md-6">
              <div class="form-group" [class.has-error]="
                              !petrolStationForm.get('lat').valid && isSubmit
                            ">
                <label class="mb-2">{{ "WAREHOUSES.attributes.latitude.label" | translate }}</label>
                <input class="form-control spec-lat" type="name" formControlName="lat" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" [class.has-error]="
                              !petrolStationForm.get('long').valid && isSubmit
                            ">
                <label class="mb-2">{{ "WAREHOUSES.attributes.longitude.label" | translate }}</label>
                <input class="form-control spec-long" type="name" formControlName="long" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group file">
                <label class="mb-2"> {{ "DRIVERS.extras.station_img" | translate }}</label>
                <div class="input-group mb-3">
                  <input class="form-control " accept=".png, .jpg, .jpeg, .gif" (change)="OnFileSelected($event)"
                    type="file" />
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <label class="mb-2">{{"DRIVERS.extras.fuels" | translate}}</label>
              <ng-select [items]="petrolTypes" [clearable]="true" [multiple]="true" bindLabel="name" bindValue="id"
                formControlName="petrol_types" class="select select-ng-tabel" data-cy="countries">
              </ng-select>
            </div>

            <div class="col-md-6" *ngIf="data == null || data?.user_id == null">
              <div class="form-group">
                {{ "DRIVERS.extras.petrol_station" | translate }}
                <ng-select [clearable]="true" class="select select-ng-tabel" formControlName="user_id">
                  <ng-option *ngFor="let item of petrolMangers" [value]="item?.id">
                    {{ item?.name}}
                  </ng-option>
                </ng-select>
              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group mt-7">
                <button class="btn btn-primary mr-2 spec-get_location" (click)="openMapForm()">
                  {{ "MAP.attributes.get_location.label" | translate }}
                </button>
              </div>
            </div>













          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="createPetrolStation()">
        <ng-container *ngIf="data == null">
          {{ "DRIVERS.actions.add_new_station" | translate }}
        </ng-container>
        <ng-container *ngIf="data != null">
          {{ "DRIVERS.actions.edit_station" | translate }}
        </ng-container>
      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>
