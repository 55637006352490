import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from '../core/services/authentication.service';
import { CoreModule } from '../core/core.module';
import { ErrorInterceptorProvider } from '../core/interceptors/Error.interceptor';
import { ApiInterceptorProvider } from '../core/interceptors/api.interceptor';
import { LoaderInterceptorProvider } from '../core/interceptors/loader.interceptor';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../app.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReActivatAcountsComponent } from './re-activat-acounts/re-activat-acounts.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2TelInputModule } from 'ng2-tel-input';

@NgModule({
  declarations: [RegistrationComponent, LoginComponent, TermsAndConditionsComponent, ReActivatAcountsComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatDialogModule,
    NgbModule,
    NgSelectModule,
    Ng2TelInputModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

  ],
  providers: [
    AuthenticationService,
    LoaderInterceptorProvider,
    ApiInterceptorProvider,
    ErrorInterceptorProvider
  ]

})
export class AuthModule {
}
