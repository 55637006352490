import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateToTimestampService {
  constructor() { }

  convertDateToTimestamp(event: any) {
    console.log(new Date(event.target.value).getTimezoneOffset() * 6000);
    console.log(new Date(event.target.value).getTime());

    return (new Date(event.target.value).getTime() - new Date(event.target.value).getTimezoneOffset() * 60000) / 1000
  }
}

