<header class=" navbar border-b flex items-center justify-between ">
  <!-- Logo text or image -->
  <div class="h-full flex items-center justify-between">
    <img class="mr-2" width="50" src="../assets/image/logo_eng.cba39ed1.svg" />
  </div>
  <!-- END Logo text or image -->

  <!-- Global navigation -->

  <ul class="list-reset flex flex-row items-center p-0">


    <!-- SETTINGS -->
    <li class="ml-4">
      <div ngbDropdown>
        <i class="bx bx-cog pointer" id="dropdownBasic1" ngbDropdownToggle></i>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="menue-open">

          <button ngbDropdownItem (click)="changeLanguage()" class="sm:text-sm">
            <i class='bx bx-globe mr-2 ml-2'></i>
            {{ "HEADER.USER.system_language" | translate }}
          </button>

        </div>

      </div>
    </li>
    <!-- /SETTINGS -->

    <!-- PROFILE -->
    <li class="ml-4 bell">
      <div class="relative w-10 h-10 pointer" ngbDropdown id="dropdownBasic1" ngbDropdownToggle>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="menue-open">
          <button ngbDropdownItem routerLink="/my-profile" class="sm:text-sm">{{ "HEADER.USER.my_profile" | translate
            }}</button>

          <button ngbDropdownItem (click)="openUserLogOut()" class="sm:text-sm text-red-600"> {{ "HEADER.USER.log_out" |
            translate
            }}</button>
        </div>


        <img class="rounded-full border  border-gray-100 shadow-sm w-10 h-10" [defaultImage]="defaultImage"
          *ngIf="currentUser?.image_url != null" [lazyLoad]="currentUser?.image_url" />

        <img *ngIf="currentUser?.image_url == null" class="rounded-full border w-10 h-10 border-gray-100 shadow-sm"
          src="../../../assets/image/user.png" />

      </div>
    </li>
    <!-- /PROFILE -->

  </ul>

  <!-- END Global navigation -->

</header>
