import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletsService {

  constructor(private http: HttpClient) { }

  apiUrl = `superadmins/api/v1`;
  apiUrlcompany = `superadmins/api/v1`;

  geWallets(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrlcompany + '/balance_requests', { params: params });
  }

  getJoinRequests(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrlcompany + '/registration_requests', { params: params });
  }

  getClaims(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrlcompany + '/claim_requests', { params: params });
  }

  geTransactions(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrl + '/transactions', { params: params });
  }
  geWallet(id): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrl}/balance_requests/` + id);
  }
  getClaim(id): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrl}/claim_requests/` + id);
  }
  geWalletInfo(): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrl}/wallets/my_wallet`);
  }
  createWallet(model: any) {
    return this.http.post(baseUrl + `${this.apiUrl}/credit_requests`, model);
  }
  accept(driverId, model) {
    return this.http.put(baseUrl + `${this.apiUrlcompany}/balance_requests/${driverId}/accept`, model);
  }
  acceptClaim(driverId, model) {
    return this.http.put(baseUrl + `${this.apiUrlcompany}/claim_requests/${driverId}/accept`, model);
  }
}
