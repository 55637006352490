import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {
  apiUrl = 'shops/api/v1/payment_methods';
  constructor(private http: HttpClient) { }

  getPaymentMethods() {
    return this.http.get(baseUrl + this.apiUrl);
  }

  getShopPaymentMethods() {
    return this.http.get(baseUrl + this.apiUrl + '/shop_payment_methods');
  }

  updateShopPaymentMethods(model: any) {
    return this.http.post(baseUrl + this.apiUrl + '/update_shop_payment_methods', model);
  }

}
