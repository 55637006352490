<div class="auth flex items-center justify-center w-full ">
  <div class="mt-7 content">
    <div class="text-center">
      <a class="navbar-brand m-0" href="#">
        <img width="150" src="../assets/image/logo_eng.cba39ed1.svg" />
      </a>
    </div>
    <div class="ptb-10 mt-5 text-center">

    </div>
    <form [formGroup]="FormLogin" (ngSubmit)="onSubmit()">
      <div class="row m-2">
        <div class="col-md-12 flex justify-center">
          <div class="form-group mb-6" [class.has-error]="
              !FormLogin.get('phone').valid &&
              FormLogin.get('phone').touched">
            <label class="mb-2">{{ "LOGIN.phone.label" | translate }}</label>
            <div class="input-group " *ngIf="onlyCountries.length > 0">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <img src="../../../assets/image/uiw_mobile.svg" alt="">
                </span>
              </div>
              <!-- <input class="form-control force-ltr spec-phone" type="text" placeholder="05XXXXXXX"
                formControlName="phone" required required aria-describedby="basic-addon1" /> -->
              <input class="form-control" type="text" appNumberPhone="$event" ng2TelInput
                [ng2TelInputOptions]="{initialCountry: initialCountry ,separateDialCode:true ,onlyCountries:onlyCountries }"
                (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event)"
                (countryChange)="onCountryChange($event)" required />
            </div>

            <span class="text-error size-small mt-2 validator">
              {{ "LOGIN.phone.error" | translate }}
            </span>
          </div>
        </div>
        <div class="col-md-12 flex justify-center">
          <div class="form-group mb-6" [class.has-error]="
              !FormLogin.get('password').valid &&
              FormLogin.get('password').touched
            ">
            <label class="mb-2">
              {{ "LOGIN.password.label" | translate }}
            </label>

            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <img src="../../../assets/image/clarity_lock-line.svg" alt="">
                </span>
              </div>

              <input class="form-control spec-password" [type]="'password'" name="password"
                [placeholder]="'LOGIN.password.placeholder' | translate" formControlName="password"
                autocomplete="password" required aria-describedby="basic-addon1" />
            </div>



            <span class="text-error size-small mt-2 validator">
              {{ "LOGIN.password.error" | translate }}
            </span>
          </div>
        </div>

        <div class="col-md-12 flex justify-center">
          <div class="form-group mb-6">
            <button [disabled]="!FormLogin.valid || submittingLogin" type="submit"
              class="btn btn btn-primary btn-block text-center spec-submit w-full">
              {{ "LOGIN.header.label" | translate }}
            </button>

            <!-- <button class="btn btn btn-primary-t2 btn-block text-center  w-full mt-4" type="button"
              (click)="goRegistration()">
              {{ "LOGIN.header.registration" | translate }}
            </button> -->
          </div>

        </div>

        <div class="col-md-12 flex justify-center mt-2 text-xl">
          <ng-container>
            <label class="mx-1">
              {{ "LOGIN.header.language" | translate }}
            </label>
            <div ngbDropdown>
              <i class='bx bx-world edit pointer' id="dropdownBasic1" ngbDropdownToggle></i>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="menue-open">
                <button ngbDropdownItem class="sm:text-sm" type="button" (click)="changeLanguage()">{{
                  "HEADER.USER.system_language" |
                  translate }}</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </form>
  </div>
</div>
