<div class="table-container">

  <div class="flex flex-col md:flex-row justify-between pl-4 pr-4 pt-4">
    <h2 class="mb-2"> {{ "SUPPLIER.extras.wallet_list" | translate }}</h2>
    <div class="flex justify-end pl-4 pr-4 pb-4" *ngIf="tabs[0].state">
      <button class="btn btn-default ml-2 mr-2" (click)="clearFilter()"><i class='bx bx-revision'></i></button>
      <button class="btn btn-default" [class.btn-open-filter]="isFilter" (click)="openFilter()">
        <i class='bx bx-filter-alt'></i>
      </button>
    </div>

  </div>



  <ng-container *ngIf="tabs[0].state ">
    <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap "
      [class.open-filter]="isFilter">

      <select (change)="selectStatus($event.target.value)" class="form-select ml-2  mb-2" [(ngModel)]="status">
        <option selected value="">{{ "QUOTION.attributes.status.all" | translate }}
        </option>

        <option value="pending">{{ "QUOTION.attributes.status.current" | translate }}
        </option>

        <option value="accepted">{{ "QUOTION.attributes.status.history" | translate }}
        </option>

      </select>


    </div>
    <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
    </app-pagination>
    <div class="component-loader" (errorCallback)="getWallets()" [errorMessage]="'lOADER.errorMessage' | translate "
      component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
      [componentLoading]="loading">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">
              {{ "MARKETPLACES.extras.request_number" | translate }}
            </th>

            <!-- <th scope="col">
              {{ "DRIVERS.extras.number_ride" | translate }}
            </th> -->

            <th scope="col">
              {{ "DRIVERS.extras.driver" | translate }}
            </th>

            <th scope="col">
              {{
              "MARKETPLACES.extras.amount"
              | translate
              }}
            </th>

            <th scope="col">
              {{
              "MARKETPLACES.extras.image"
              | translate
              }}
            </th>

            <th scope="col">
              {{
              "QUOTION.attributes.status.label"
              | translate
              }}
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of wallets | paginate: paginationConfig">
            <td class="pointer weight-label" (click)="goToDetails(item.id)">
              {{ item?.id }}
            </td>

            <!-- <td class="pointer weight-label">
              {{ item?.ride?.number }}
            </td> -->

            <td class="pointer weight-label">
              {{ item?.user?.name }}
            </td>

            <td class="">
              <strong>{{ item?.amount }}</strong> {{ "CURRENCY.sr" | translate }}
            </td>

            <td>
              <img class="w-10" [defaultImage]="defaultImage" [lazyLoad]="item?.image_url" />
            </td>

            <td>
              {{ ("QUOTION.attributes.status." + item?.status) | translate }}
            </td>
            <td>
              <button class="btn btn-primary ml-2 mr-2 mb-2" *ngIf="item?.status == 'pending' " (click)="accept(item)">
                {{
                "DRIVERS.actions.agree" | translate }} </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>




</div>
