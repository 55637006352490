import { LogoutComponent } from './logout/logout.component'
import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { ProfileService } from 'src/app/services/profile-change.service'
import { ChangeLanguageService } from 'src/app/core/services/change-language.service'
// import { userWebAppLink } from 'src/environments/environment';
import { Globals } from 'src/app/core/globals'


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  defaultImage = 'assets/image/user.png'
  currentUser: any
  hasTicketsPermissionOnly = false
  bankInfos: any
  selectedLanguage = 'ar'
  profile: any
  profileSubscription: Subscription | any
  isWholesale: boolean = false
  isMarketplaceOnly: boolean = false
  isStoreOnline: boolean = false;
  isSimpleInvoice: boolean = false;
  isAgent: boolean = false;
  storePageLink: string = '';

  constructor(
    public languageService: ChangeLanguageService,
    private globals: Globals,
    private translate: TranslateService,
    private dialog: MatDialog,
    private profileChangeService: ProfileService,
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(this.globals.currentUser || null)
    this.updateProfile()
    if (localStorage.getItem('lang') == 'ar') {
      this.translate.use('ar')
    } else if (localStorage.getItem('lang') == 'en') {
      this.translate.use('en')
    }
    document
      .querySelector('body')
      .setAttribute('dir', localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr')
  }

  ngOnDestroy() {
    this.profileSubscription.unsubscribe()
  }

  updateProfile() {
    this.profileSubscription = this.profileChangeService
      .onProfilePicChange()
      .subscribe((res) => {
        this.currentUser.image_url = res
      })
  }


  userHasRoles(roles: any) {
    const userRoles = this.currentUser?.shop?.roles.map((role: any) => role.key)
    return userRoles?.some((userRole: any) => roles.includes(userRole))
  }

  openUserLogOut() {
    const dialogRef = this.dialog.open(LogoutComponent, {
      width: '500px',
    })
  }

  changeLanguage() {
    if (localStorage.getItem('lang') == 'ar') {
      localStorage.setItem('lang', 'en')
      this.languageService.langauge.next('en')
      this.translate.use('en')
    } else if (localStorage.getItem('lang') == 'en') {
      localStorage.setItem('lang', 'ar')
      this.languageService.langauge.next('ar')
      this.translate.use('ar')
    }

    document
      .querySelector('body')!
      .setAttribute('dir', localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr')
    document
      .querySelector('html')!
      .setAttribute('lang', localStorage.getItem('lang') == 'ar' ? 'ar' : 'en')
  }


  //fast quiq


}
