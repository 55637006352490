import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JoinRequestComponent } from './balance-request/balance-request.component';

const routes: Routes = [
  { path: '', component: JoinRequestComponent, data: { title: 'claim' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClaimRoutingModule { }
