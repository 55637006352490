import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberPhone]'
})
export class NumberPhoneDirective {

  constructor() { }

  @HostListener('keypress', ['$event'])
  numberPhone(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && charCode != 43 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
