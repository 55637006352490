<div class="component-loader" (errorCallback)="getTrip()" [errorMessage]="'lOADER.errorMessage' | translate "
  component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
  [componentLoading]="loading">



  <!--details content-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.actions.trip_details" | translate }}</h2>
      <div>
        <ng-container *ngIf="trip?.status != 'reschedule_pending'">
          <ng-container *ngIf="currentUser.user_category.key == 'shipping_company'">
            <button class="btn btn-primary ml-2 mr-2 mb-2"
              *ngIf="trip?.status != 'cancelled' && trip?.status != 'delivered'" (click)="updateTrip()"> {{
              "DRIVERS.actions.updateTrip" | translate }} </button>

            <!-- <button class="btn btn-primary ml-2 mr-2 mb-2"
              *ngIf="trip?.status != 'cancelled' && trip?.status != 'delivered'" (click)="updateTrip('cancelled')"> {{
              "DRIVERS.actions.cancelled" | translate }} </button> -->

            <button class="btn btn-primary ml-2 mr-2 mb-2"
              *ngIf="trip?.status != 'cancelled' && trip?.status != 'delivered' && trip?.truck == null"
              (click)="assignTruck()"> {{
              "DRIVERS.actions.assignTruck" | translate }} </button>

          </ng-container>
        </ng-container>

        <ng-container *ngIf="currentUser.user_category.key == 'company'">
          <button class="btn btn-primary ml-2 mr-2 mb-2"
            *ngIf="trip?.status != 'cancelled' && trip?.status != 'delivered'" (click)="shipmentRescheduleRequests()">
            {{
            "DRIVERS.actions.shipmentRescheduleRequests" | translate }} </button>
        </ng-container>


        <button class="btn btn-default mb-2" (click)="backTolist()" *ngIf="marketplaceId == null">
          {{ "DRIVERS.actions.back_to_trips" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>
        <button class="btn btn-default mb-2"
          (click)="goToDetails(marketplaceId , 'marketplaces/marketplace_outgoing_current/details')"
          *ngIf="marketplaceId != null">
          {{ "MARKETPLACES.extras.back_to_marketplace_details" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>


      </div>
    </div>
    <div class="details-header py-4">
      <div class="stepper-container max-400 max-center flex flex-middle mtb-2">

        <ng-container *ngIf="trip?.status != 'reschedule_pending'">

          <div class="stepper-point" [class.stepper-active]=" pendingStatus.includes(trip?.status)">
            <i class='bx bx-check text-xl'></i>
            <p class="no-wrap stepper-title text-black pointer">
              <ng-container>
                {{ ("DRIVERS.extras.statusTrip.pending" ) | translate }}
              </ng-container>

            </p>
          </div>

          <div class="stepper-line" [class.stepper-active]=" arrivedAtSourceStatus.includes(trip?.status)">
          </div>
          <div class="stepper-point" [class.stepper-active]=" arrivedAtSourceStatus.includes(trip?.status)">
            <i class='bx bx-check text-xl'></i>
            <p class="no-wrap stepper-title text-black pointer">
              <ng-container>
                {{ ("DRIVERS.extras.statusTrip.arrived_at_source" ) | translate }}
              </ng-container>
            </p>
          </div>

          <div class="stepper-line" [class.stepper-active]=" shipmentLoadingStatus.includes(trip?.status)">
          </div>
          <div class="stepper-point" [class.stepper-active]=" shipmentLoadingStatus.includes(trip?.status)">
            <i class='bx bx-check text-xl'></i>
            <p class="no-wrap stepper-title text-black pointer">
              <ng-container>
                {{ ("DRIVERS.extras.statusTrip.shipment_loading" ) | translate }}
              </ng-container>
            </p>
          </div>
          <div class="stepper-line" [class.stepper-active]=" processingStatus.includes(trip?.status)">
          </div>
          <div class="stepper-point" [class.stepper-active]=" processingStatus.includes(trip?.status)">
            <i class='bx bx-check text-xl'></i>
            <p class="no-wrap stepper-title text-black pointer">
              <ng-container>
                {{ ("DRIVERS.extras.statusTrip.processing" ) | translate }}
              </ng-container>
            </p>
          </div>
          <div class="stepper-line" [class.stepper-active]=" onWayStatus.includes(trip?.status)">
          </div>
          <div class="stepper-point" [class.stepper-active]=" onWayStatus.includes(trip?.status)">
            <i class='bx bx-check text-xl'></i>
            <p class="no-wrap stepper-title text-black pointer">
              <ng-container>
                {{ ("DRIVERS.extras.statusTrip.on_way" ) | translate }}
              </ng-container>
            </p>
          </div>
          <div class="stepper-line" [class.stepper-active]=" arrivedAtDestinationStatus.includes(trip?.status)">
          </div>
          <div class="stepper-point" [class.stepper-active]=" arrivedAtDestinationStatus.includes(trip?.status)">
            <i class='bx bx-check text-xl'></i>
            <p class="no-wrap stepper-title text-black pointer">
              <ng-container>
                {{ ("DRIVERS.extras.statusTrip.arrived_at_destination" ) | translate }}
              </ng-container>
            </p>
          </div>
          <div class="stepper-line" [class.stepper-active]=" shipmentDeloadingStatus.includes(trip?.status)">
          </div>
          <div class="stepper-point" [class.stepper-active]=" shipmentDeloadingStatus.includes(trip?.status)">
            <i class='bx bx-check text-xl'></i>
            <p class="no-wrap stepper-title text-black pointer">
              <ng-container>
                {{ ("DRIVERS.extras.statusTrip.shipment_deloading" ) | translate }}
              </ng-container>
            </p>
          </div>
          <div class="stepper-line" [class.stepper-active]=" deliveredStatus.includes(trip?.status)">
          </div>
          <div class="stepper-point" [class.stepper-active]=" deliveredStatus.includes(trip?.status)">
            <i class='bx bx-check text-xl'></i>
            <p class="no-wrap stepper-title text-black pointer">
              <ng-container>
                {{ ("DRIVERS.extras.statusTrip.delivered" ) | translate }}
              </ng-container>
            </p>
          </div>





        </ng-container>
        <ng-container *ngIf="trip?.status == 'reschedule_pending'">
          <div class="stepper-point" [class.stepper-active]="trip?.status == 'reschedule_pending'  ">
            <i class='bx bx-check text-xl'></i>
            <p class="no-wrap stepper-title text-black pointer">
              <ng-container>
                {{ ("DRIVERS.extras.statusTrip.reschedule_pending" ) | translate }}
              </ng-container>

            </p>
          </div>
        </ng-container>

      </div>
    </div>

    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.tracking_reference" | translate }}</p>
        <span class="tabel-content"> {{ trip?.tracking_reference }}</span>
      </div>


      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "DRIVERS.extras.shipment_date"
          | translate
          }}</p>
        <span class="tabel-content">
          {{ trip?.shipment_date | date:'medium' }}
        </span>
      </div>

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "DRIVERS.extras.status"
          | translate
          }}</p>
        <span class="tabel-content">
          {{ ("DRIVERS.extras.statusTrip." + trip?.status) | translate }}
        </span>
      </div>

      <div class="col-span-3 md:col-span-2 row-span-2 pointer"
        (click)="goToDetails(trip?.quotation_request?.id , 'marketplaces/marketplace_outgoing_current/details')">
        <p> {{ "TRIPS.contract_number" | translate }}</p>
        <span class="tabel-content"> {{ trip?.quotation_request?.id }}</span>
      </div>
      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.start_date" | translate
          }}</p>
        <span class="tabel-content"> {{ trip?.quotation_request?.contract_from | date }}</span>
      </div>

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.expires_at" | translate
          }}</p>
        <span class="tabel-content"> {{ trip?.quotation_request?.contract_to | date }}</span>
      </div>
    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.address_line1" | translate
          }}</p>
        <span class="tabel-content"> {{ trip?.pickup_address?.address_line1 }}</span>
      </div>

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.address_line1to" | translate
          }}</p>
        <span class="tabel-content"> {{ trip?.delivery_address?.address_line1 }}</span>
      </div>
    </div>

    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.city_id" | translate
          }}</p>
        <span class="tabel-content"> {{ trip?.quotation_request?.pickup_address?.city_name }}</span>
      </div>
      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{
          "MARKETPLACES.extras.city_id_to" | translate
          }}</p>
        <span class="tabel-content"> {{ trip?.quotation_request?.delivery_address?.city_name }}</span>
      </div>
    </div>
  </div>

  <div class="details-container" *ngIf="trip?.contract_shipment_histories?.length > 0">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.extras.contract_shipment_histories" | translate }}</h2>
      <div>

      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ "DRIVERS.extras.status" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.assign_by" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.phone" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.created_at" | translate }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="" *ngFor="let item of trip?.contract_shipment_histories">
          <td class="">
            {{ ("DRIVERS.extras.statusTrip." + item?.status) | translate }}
          </td>
          <td class="">
            {{ item?.user.name }}
          </td>
          <td class="">
            {{ item?.user.phone }}
          </td>
          <td class="">
            {{ item?.created_at | date: 'medium' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="details-container" *ngIf="trip?.truck != null">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.actions.details_trucks" | translate }}</h2>
      <div>
      </div>
    </div>
    <div class=" grid grid-cols-6 gap-3 p-4">
      <div class="col-span-3 md:col-span-2 row-span-2 pointer"
        *ngIf="currentUser?.user_category.key == 'shipping_company'"
        (click)="goToDetails(trip?.truck?.id , 'trucks/details')">
        <p> {{"DRIVERS.extras.number_ride"| translate}}</p>
        <span class="tabel-content">
          {{ trip?.truck?.number }}
        </span>
      </div>
      <div class="col-span-3 md:col-span-2 row-span-2" *ngIf="currentUser?.user_category.key == 'company'">
        <p> {{"DRIVERS.extras.number_ride"| translate}}</p>
        <span class="tabel-content">
          {{ trip?.truck?.number }}
        </span>
      </div>
      <div class="col-span-3 md:col-span-2 row-span-2 pointer"
        *ngIf="currentUser?.user_category.key == 'shipping_company'"
        (click)="goToDetails(trip?.truck?.user_driver?.id , 'drivers/details')">
        <p> {{
          "DRIVERS.extras.user_driver"
          | translate
          }}</p>
        <span class="tabel-content">
          {{ trip?.truck?.user_driver?.name }}
        </span>
      </div>
      <div class="col-span-3 md:col-span-2 row-span-2" *ngIf="currentUser?.user_category.key == 'company'">
        <p> {{
          "DRIVERS.extras.user_driver"
          | translate
          }}</p>
        <span class="tabel-content">
          {{ trip?.truck?.user_driver?.name }}
        </span>
      </div>

      <div class="col-span-3 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.phone" | translate }}</p>
        <span class="tabel-content">
          {{ trip?.truck?.user_driver?.phone }}
        </span>
      </div>
    </div>
  </div>


  <div class="details-container" *ngIf="shipmentRescheduleRequest.length > 0">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.extras.shipmentRescheduleRequest" | translate }}</h2>
      <div>

      </div>
    </div>
    <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
    </app-pagination>
    <table class="table">
      <thead>
        <tr>


          <th scope="col">
            {{ "INVOICES.extras.order_number" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.name" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.extra_charges" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.status" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.old_shipment_date" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.new_shipment_date" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.notes" | translate }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="" *ngFor="let item of shipmentRescheduleRequest">
          <td class="">
            {{ item?.id }}
          </td>
          <td class="">
            {{ item?.user?.name }}
          </td>
          <td class="">
            {{ item?.extra_charges }}
          </td>
          <td class="">
            {{ ("DRIVERS.extras.shipmentRescheduleRequestStatus." + item?.status) | translate }}
          </td>
          <td class="">
            {{ item?.old_shipment_date |date }} , {{ item?.old_shipment_time }}
          </td>
          <td class="">
            {{ item?.new_shipment_date |date }} , {{ item?.new_shipment_time }}
          </td>
          <td class="">
            {{ item?.notes }}
          </td>
          <td class="">
            <ng-container *ngIf="currentUser?.user_category.key == 'company'">
              <button class="btn btn-primary ml-2 mr-2 mb-2"
                *ngIf="trip?.status == 'reschedule_pending' &&item?.status == 'extra_charges' " (click)="accept(item)">
                {{
                "DRIVERS.actions.agree" | translate }} </button>

              <button class="btn btn-primary ml-2 mr-2 mb-2"
                *ngIf="trip?.status == 'reschedule_pending' &&item?.status == 'extra_charges'" (click)="refuse(item)">
                {{
                "DRIVERS.actions.refuse" | translate }} </button>
            </ng-container>

            <ng-container *ngIf="currentUser?.user_category.key == 'shipping_company'">
              <button class="btn btn-primary ml-2 mr-2 mb-2"
                *ngIf="trip?.status == 'reschedule_pending' && item?.status == 'pending'" (click)="accept(item)"> {{
                "DRIVERS.actions.agree" | translate }} </button>

              <button class="btn btn-primary ml-2 mr-2 mb-2"
                *ngIf="trip?.status == 'reschedule_pending' && item?.status == 'pending'" (click)="refuse(item)"> {{
                "DRIVERS.actions.refuse" | translate }} </button>
              <button class="btn btn-primary ml-2 mr-2 mb-2"
                *ngIf="trip?.status == 'reschedule_pending' && item?.status == 'pending'"
                (click)="addExtraCharges(item)">
                {{
                "DRIVERS.actions.addExtraCharges" | translate }} </button>
            </ng-container>
          </td>

        </tr>
      </tbody>
    </table>
    <!-- /REGULAR EVALUATION -->
  </div>



  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.extras.comments" | translate }}</h2>
      <div>
        <button class="btn btn-primary ml-2 mr-2 mb-2"
          *ngIf="trip?.status != 'cancelled' && trip?.status != 'delivered'" (click)="addComment()"> {{
          "DRIVERS.actions.addComment" | translate }} </button>
      </div>
    </div>

    <table class="table" *ngIf="trip?.comments?.length > 0">
      <thead>
        <tr>

          <th scope="col">
            {{ "DRIVERS.extras.comment" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.commenterName" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.commenterPhone" | translate }}
          </th>

          <th scope="col">
            {{ "DRIVERS.extras.created_at" | translate }}
          </th>

          <th scope="col">
            {{ "DRIVERS.extras.image" | translate }}
          </th>

        </tr>
      </thead>
      <tbody>
        <tr class="" *ngFor="let item of trip?.comments">

          <td class="">
            {{ item?.comment }}
          </td>
          <td class="">
            {{ item?.commenter?.name }}
          </td>
          <td class="">
            {{ item?.commenter?.phone }}
          </td>

          <td class="">
            {{ item?.created_at | date: 'medium' }}
          </td>

          <td class="pointer" (click)="onClickImageNewTab(item?.attachments[0]?.url)">
            <img class="pointer" width="50" [defaultImage]="defaultImage" [lazyLoad]="item?.attachments[0]?.url" />

          </td>

        </tr>
      </tbody>
    </table>
    <!-- /REGULAR EVALUATION -->
  </div>


</div>