import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {
    if (localStorage.getItem('lang')) {
      translate.setDefaultLang(localStorage.getItem('lang'));
    } else {
      localStorage.setItem('lang', 'ar')
      document.querySelector('body').setAttribute('dir', 'rtl');
    }

    document.body.classList.add('admin_theme');
  }

  selectedLanguage = 'ar';
  Lang: string = null;

  ngOnInit(): void {
    document.querySelector('body').setAttribute('dir', this.selectedLanguage === 'ar' ? 'rtl' : 'ltr');
  }

}
