<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "DRIVERS.actions.shipmentRescheduleRequests" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <form [formGroup]="truckForm">
          <div class="row mb-2">


            <div class="col-md-6" [class.has-error]="!truckForm.get('new_shipment_date').valid && isSubmit">
              <div class="form-group">
                <label class="mb-2"> {{ "DRIVERS.extras.new_shipment_date" | translate }}</label>
                <div class="form-group relative">
                  <input [matDatepicker]="pickId" name="started-date" class="form-control h-10 date"
                    placeholder="{{ 'DRIVERS.extras.new_shipment_date' | translate }}"
                    (dateInput)="setDateBox($event,'new_shipment_date')" [min]="min" [max]="max"
                    formControlName="new_shipment_date" />
                  <mat-datepicker #pickId></mat-datepicker>
                  <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId">
                  </mat-datepicker-toggle>

                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="mb-2"> {{ "MARKETPLACES.extras.pickup_time" | translate }}</label>
                <div class="form-group relative">
                  <input matTimepicker class="form-control h-10 date" formControlName="pickup_time">
                </div>
              </div>
            </div>

            <div class="col-md-12 ">
              <div class="form-group">
                <label class="mb-2"> {{ "DRIVERS.extras.notes" | translate }} </label>
                <textarea class="form-control" formControlName="notes"></textarea>
              </div>
            </div>





          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="addShipmentRescheduleRequests()" [disabled]="truckForm.invalid">
        {{ "DRIVERS.actions.shipmentRescheduleRequests" | translate }}

      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>