<div class="table-container">



  <div class="flex flex-col md:flex-row justify-between pl-4 pr-4 pt-4">
    <h2 class="mb-2">{{ "HEADER.DATA_MANAGEMENT.marketplace_outgoing" | translate }}</h2>
    <div class="flex justify-end pl-4 pr-4 pb-4">
      <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="createContractForm()"> {{
        "MARKETPLACES.actions.create_new_marketplace" | translate }} </button>
      <!-- <button class="btn btn-default ml-2 mr-2" (click)="clearFilter()"><i class='bx bx-revision'></i></button>
      <button class="btn btn-default" [class.btn-open-filter]="isFilter" (click)="openFilter()">
        <i class='bx bx-filter-alt'></i>
      </button> -->
    </div>

  </div>



  <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap "
    [class.open-filter]="isFilter">

    <!-- <select (change)="selectStatusMarketPlaces($event.target.value)" class="form-select ml-2  mb-2"
      [(ngModel)]="status">
      <option selected value="all">{{ "QUOTION.attributes.status.all" | translate }}
      </option>

      <option selected value="pending">{{ "QUOTION.attributes.status.pending" | translate }}
      </option>

      <option value="waiting_offers">{{ "QUOTION.attributes.status.waiting_offers" | translate }}
      </option>
      <option value="offer_sent">{{ "QUOTION.attributes.status.offer_sent" | translate }}</option>
      <option value="offer_not_sent">{{ "QUOTION.attributes.status.offer_not_sent" | translate }} </option>

      <option value="accepted_offer">{{ "QUOTION.attributes.status.accepted_offer" | translate }}
      </option>

      <option value="waiting_payment_confirmation">{{ "QUOTION.attributes.status.waiting_payment_confirmation" |
        translate }}</option>
      <option value="payment_confirmed">{{ "QUOTION.attributes.status.payment_confirmed" | translate }}</option>
      <option value="waiting_review">{{ "QUOTION.attributes.status.waiting_review" | translate }}
      </option>
      <option value="done">{{ "QUOTION.attributes.status.done" | translate }}</option>
    </select>

    <select (change)="selectIdentityState($event.target.value)" class="form-select ml-2  mb-2"
      [(ngModel)]="hide_identity">
      <option selected value="all">{{ "QUOTION.attributes.status.all" | translate }}
      </option>
      <option value="true">{{ "QUOTION.attributes.hide_identity.label" | translate }}
      </option>
      <option value="false">{{ "QUOTION.attributes.show_identity.label" | translate }}</option>

    </select>
    <select (change)="selectShopCategoryIds($event.target.value)" class="form-select ml-2  mb-2"
      [(ngModel)]="shop_category_ids">
      <option selected value="all">{{ "QUOTION.attributes.status.all" | translate }}
      </option>
      <ng-container *ngFor="let shopCategory of shopCategories">
        <option [value]="shopCategory.id">{{ shopCategory.name }}
        </option>
      </ng-container>
    </select> -->





  </div>




  <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
  </app-pagination>



  <div class="component-loader" (errorCallback)="getMarketPlaces()" [errorMessage]="'lOADER.errorMessage' | translate "
    component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
    [componentLoading]="loading">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ "MARKETPLACES.extras.marketplace_number" | translate }}
          </th>
          <th scope="col">
            {{
            "QUOTION.attributes.created_at.label"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "MARKETPLACES.extras.weight"
            | translate
            }}
            <span>({{ "MARKETPLACES.extras.kg" | translate}})</span>
          </th>
          <th scope="col">
            {{
            "QUOTION.attributes.status.label"
            | translate
            }}
          </th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let marketplace of marketplaces | paginate: paginationConfig">
          <td class="pointer weight-label" (click)="goToDetails(marketplace.id)">
            {{ marketplace?.id }}
          </td>
          <td class="">
            <div>
              {{ marketplace?.created_at | date :'medium' }}
            </div>
          </td>
          <td>
            {{ marketplace?.weight }}
          </td>
          <td>
            {{ ("QUOTION.attributes.status." + marketplace?.status) | translate }}
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
</app-pagination>