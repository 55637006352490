import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { Globals } from 'src/app/core/globals';
import { ProfileService } from 'src/app/services/profile-change.service';
import { ProfileUserService } from 'src/app/services/profile-user.service';
import { UpdateMyProfileComponent } from './update-my-profile/update-my-profile.component';
import { ChangePasswordComponent } from '../../layout/header/change-password/change-password.component';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  defaultImage = 'assets/image/user_placeholder.svg'

  profile: any;
  constructor(
    private profileUserService: ProfileUserService,
    private dialog: MatDialog,
    private globals: Globals,
    private profileChangeService: ProfileService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.profile = {};
    this.getProfile();
  }

  getProfile() {
    this.profileUserService.getProfile().subscribe((res: any) => {
      this.profile = res.data;
    });
  }
  openUserChangePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '900px',
      disableClose: true,
    })
  }
  updateProfileData() {
    const dialogRef = this.dialog.open(UpdateMyProfileComponent, {
      width: '1000px',
      disableClose: true,
      data: this.profile
    });
    dialogRef.afterClosed().subscribe((profileData) => {
      if (profileData != undefined) {
        this.profileUserService.updateProfile(profileData).subscribe((res) => {
          this.profile = res.data
          localStorage.setItem('currentUser-b2p', JSON.stringify(res.data));
          this.globals.currentUser = localStorage.getItem('currentUser-b2p');
          this.profileChangeService.updateProfilePicUrl(this.profile?.image_url)
          this.getProfile()
        }, (error) => {
          if (error.status == 422 && error?.error?.error['email'][0]) {
            this.translate
              .get('PROFILE.messages.error_message_email', {
                value: 'error_message_email',
              })
              .subscribe((res: string) => {
                this.dialog.open(MessageFormComponent, {
                  width: '400px',
                  disableClose: true,
                  data: { message: res, state: false },
                })
              })
          }
        });
      }
    });
  }

  showImage(event) {
    window.open(event);
  }

}
