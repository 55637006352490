import { map } from 'rxjs/operators';
import { PaymentMethodsService } from './../../../services/payment-methods.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { forkJoin } from 'rxjs';
import { UserPermissionService } from 'src/app/services/user-permission.service';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from '../../../core/components/message-form/message-form.component';
import { Globals } from '../../../core/globals/index';


@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css'],
})
export class PaymentMethodsComponent implements OnInit {
  shopPaymentMethods: any;
  currentUser: any

  paymentMethods: any;
  hasPaymentMethodRole: boolean = false;

  paymentMethodsIds: any[] = [];
  constructor(
    private dialog: MatDialog,
    private globals: Globals,
    private dialogRef: MatDialogRef<PaymentMethodsComponent>,

    private paymentMethodsService: PaymentMethodsService,
    private userPermission: UserPermissionService,
    private alertify: AlertifyService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(this.globals.currentUser || null)
    this.getPaymentMethodsData();
    this.hasPaymentMethodRole = this.userPermission.hasOneRole([
      'payment_methods',
      'admin',
    ]);
  }
  closeModal() {
    this.dialogRef.close();
  }

  getPaymentMethodsData() {
    forkJoin(
      this.paymentMethodsService.getPaymentMethods(),
      this.paymentMethodsService.getShopPaymentMethods()
    ).subscribe((responses: any[]) => {
      this.paymentMethods = responses[0].data.items;
      this.shopPaymentMethods = responses[1].data.items;
      this.setSelectedMethods();
    });
  }

  setSelectedMethods() {
    //getting selected ids from the user
    this.paymentMethodsIds = this.shopPaymentMethods.map((shop) => shop.id);

    this.paymentMethods = this.paymentMethods.map((method) => {
      method.selected = false;
      return method;
    });

    this.paymentMethodsIds.forEach((id) => {
      const selectedMethodIndex = this.paymentMethods.findIndex(
        (method) => method.id === id
      );
      this.paymentMethods[selectedMethodIndex].selected = true;
    });
  }

  changeShopPayment(id, status) {
    if (status == false) {
      const index: number = this.paymentMethodsIds.indexOf(id);
      this.paymentMethodsIds.splice(index, 1);
    } else {
      this.paymentMethodsIds.push(id);
    }
  }

  updateShopPaymentMethods() {
    this.paymentMethodsService
      .updateShopPaymentMethods({ payment_method_ids: this.paymentMethodsIds })
      .subscribe(
        () => {
          this.translate
            .get('PAYMENT_METHODS.messages.edit_payment_method.edit', {
              value: 'edit',
            })
            .subscribe((res: string) => {
              this.alertify.success(res);
            });
          this.closeModal();
        },
        (error) => {
          this.translate
            .get('PAYMENT_METHODS.messages.edit_payment_method.error', {
              value: 'error',
            })
            .subscribe((res: string) => {
              this.dialog.open(MessageFormComponent, {
                width: '400px',
                disableClose: true,
                data: { message: res, state: false },
              });
            });
        }
      );
  }
}
