import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';

@Component({
  selector: 'app-log-out',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  loader = false
  loadingError = false

  constructor(private dialogRef: MatDialogRef<LogoutComponent>,
    private auth: AuthenticationService,
    public translate: TranslateService,
    private alertify: AlertifyService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {

  }
  logout() {
    this.auth.logout();
    this.auth.windowLocation()
  }
  closeModal() {
    this.dialogRef.close();
  }

}
