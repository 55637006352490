import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgmCoreModule } from '@agm/core';
import { baseUrl, googleAnalytics } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgAudioRecorderModule } from 'ng-audio-recorder';
import { FormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { GoogleAnalyticsService, NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { BrowserTracing } from "@sentry/tracing";
import { LoaderInterceptorProvider } from './core/interceptors/loader.interceptor';
import { ApiInterceptorProvider } from './core/interceptors/api.interceptor';
import { ErrorInterceptorProvider } from './core/interceptors/Error.interceptor';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Sentry.init({
//   dsn: sentryDsn,
//   environment: sentryEnvName,
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ['localhost', 'https://yourserver.io/api'],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
// });

const userData = JSON.parse(localStorage.getItem('currentUser-b2p'));
// Sentry.configureScope((scope) => {
//   scope.setUser({
//     'id?': userData?.id
//   });

//   scope.setTag('language', 'pt-BR');
//   scope.setExtra(
//     'important-information',
//     'a very important information to help!'
//   );
// });

@Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   handleError(error: any) {
//     Sentry.captureException(error.originalError || error);
//   }
// }
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    HttpClientModule,
    NgxPaginationModule,
    AgmCoreModule.forRoot({
      libraries: ['places'],
    }),
    CoreModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgbModule,
    NgAudioRecorderModule,
    NgxGoogleAnalyticsModule.forRoot(googleAnalytics),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    //hide sentry pops
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    LoaderInterceptorProvider,
    ApiInterceptorProvider,
    ErrorInterceptorProvider,
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
