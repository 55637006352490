<div class="modal-wrapper">
  <i class='bx bx-x pointer text-3xl flex' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "PROFILE.extra.profile_info" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <form [formGroup]="profileForm">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-2">
                {{ "PROFILE.extra.name" | translate }}
              </label>
              <input class="form-control" type="text" readonly formControlName="name" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-2">
                {{ "PROFILE.extra.email" | translate }}
              </label>
              <input class="form-control spec-email" type="text" formControlName="email" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-2">
                {{ "PROFILE.extra.phone" | translate }}
              </label>
              <input class="form-control" type="text" readonly formControlName="phone" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group file">
              <label class="mb-2"> {{ "PROFILE.extra.image" | translate }}</label>
              <div class="input-group mb-3">
                <input class="form-control" accept=".png, .jpg, .jpeg, .gif" (change)="OnFileSelected($event)"
                  type="file" formControlName="attachment" />
              </div>

            </div>
          </div>

        </div>
      </div>
    </form>

    <div class="modal-footer mt-4">
      <button class="btn btn-primary mb-2" (click)="onSubmit()">
        {{ "COMMON.edit" | translate }}
      </button>
      <button class="btn btn-default mb-2" (click)="closeModal()">
        {{ "COMMON.cancel" | translate }}
      </button>
    </div>
  </div>
</div>
