import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BalanceRequestComponent } from './balance-request/balance-request.component';
import { BalanceRequestDetailsComponent } from './balance-request-details/balance-request-details.component';

const routes: Routes = [
  { path: '', component: BalanceRequestComponent, data: { title: 'wallet' } },
  { path: 'details/:id', component: BalanceRequestDetailsComponent, data: { title: 'wallet Details' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BalanceRequestRoutingModule { }
