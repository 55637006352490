import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirmation-form',
  templateUrl: './confirmation-form.component.html',
  styleUrls: ['./confirmation-form.component.css']
})
export class ConfirmationFormComponent implements OnInit {
  state = false;
  message: string;
  reason: string = '';
  component: any = ''
  constructor(
    private dialogRef: MatDialogRef<ConfirmationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {
    this.message = this.data.message
    this.component = this.data?.component
    if (this.component) {
      this.reason = null
    }
  }

  confirm() {

    this.state = true;

    let data = { state: this.state, reason: this.reason }
    this.dialogRef.close(data);

  }
  closeModal() {
    this.dialogRef.close(false);
  }

}
