<div class="registration-section">
  <div class="row m-0">
    <div class="col-md-12">
      <div class="mlr-10 pr-10">
        <div class="mtb-10 mlr-10">
          <div class="flex flex-middle flex-space-between">
            <div>
              <a class="navbar-brand m-0" routerLink="/">
                <img width="120" src="assets/image/logo.png" />
              </a>
            </div>
          </div>
          <div class="text-center">
            <h1 class="weight-bold mb-3 size-10 text-gray">الشروط والاحكام</h1>
          </div>
          <div class="mt-10">
            <h2 class="weight-bold size-3 mb-3">التمهيد:</h2>
            <p class="mb-3 size-2 text-gray">
              نحن منصة فارمر المملوكة لشركة تحفيز لتقنية المعلومات بالسجل
              التجاري رقم 1010592051 المسجل بالمملكة العربية السعودية ، وتجدون
              أدناه الشروط والأحكام المنضمة لاستخدام منصة فارمر وكافة المعلومات
              القانونية حيث أن استخدام أي شخصٍ كان لمنصة فامر سواءً كان مستهلكاً
              أو تاجراً أو غير ذلك فإن هذا بمثابة موافقة وقبول منه وهو بكامل
              أهليته المعتبرة شرعاً ونظاماً وقانوناً لكافة مواد وبنود وأحكام هذه
              الاتفاقية وهو تأكيد للالتزام بالبنود الواردة وكافة الانظمة الحالية
              والتعديلات اللاحقة وتعتبر هذه الاتفاقية سارية المفعول ونافذة بمجرد
              قيامكم بالموافقة عليها والبدء في التسجيل بمنصة وتطبيقات فارمر .
            </p>
            <h2 class="weight-bold size-3 mb-3">التعريفات:</h2>
            <ul>
              <li class="mb-3 size-2 text-gray">
                يعتبر التمهيد أعلاه جزءاً لا يتجزأ من هذه الاتفاقية، كما تجدون
                أدناه تعريف للعبارات الرئيسية المستخدمة:
              </li>
              <ol>
                <li class="mb-3 size-2 text-gray">
                  (منصة او المنصة او تطبيق او تطبيقات فارمر او الإدارة) يقصَد
                  بهذه العبارة شركة تحفيز لتقنية المعلومات، ويشمل هذا التعريف
                  كافة أشكال التطبيقات الالكترونية ولوحات التحكم والمنصات
                  الكترونية على الانترنت والتي على حساب شركة تحفيز لتقنية
                  المعلومات والمسماة فارمر.
                </li>
                <li class="mb-3 size-2 text-gray">
                  (التاجر) يقصَد بهذه العبارة كل تاجر يسجّل في منصة فارمر لإنشاء
                  متجر الكتروني، سواءً كان فرد عادي أو شركة، ويشمل هذا التعريف
                  كافة أنواع المتاجر طالما أنه يعرض منتجاته عن طريق منصة فارمر .
                </li>
                <li class="mb-3 size-2 text-gray">
                  (المتجر) تعني هذه العبارة المتجر المفعل الكترونياً من قبل
                  التاجر في منصة فارمر كتطبيق او موقع الكتروني او لوحة تحكم .
                </li>
                <li class="mb-3 size-2 text-gray">
                  ( المستهلك او المستهلكين او العملاء او المستخدمين ) تعني هذه
                  العبارة كل مستهلك يقوم بشراء المنتج أو الخدمة من التاجر او
                  المتاجر الأخرى المتوفرة على التطبيق او الموقع الالكتروني .
                </li>
                <li class="mb-3 size-2 text-gray">
                  ( الاتفاقية ) تعني هذه العبارة قواعد وشروط وأحكام استخدام منصة
                  فارمر والتي تؤطر وتنظّم العلاقة فيما بين الأطراف جميعاً
                  بالمنصة .
                </li>
                <li class="mb-3 size-2 text-gray">
                  ( مزودي الخدمة او الخدمات اللوجستية او التوصيل او المستودعات
                  المركزية ) تعني هذه العبارة الخدمات التي تقوم منصة فارمر
                  بتوفيرها للعملاء او التجار عن طريق أطراف ثالثة ، وبدون أي
                  مسؤولية على منصة فارمر وحدود المسؤولية تنتهي بمجرد توفير الربط
                  بين التاجر ومزود الخدمة ويتم الاتفاق بعقد او اتفاقية مستقلة
                  بتراضي الطرفين .
                </li>
              </ol>
            </ul>
            <h2 class="weight-bold size-3 mb-3">
              المادة الأولى / الاهلية القانونية:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                يقر التاجر بأهليته القانونية وأنها معتبرة شرعاً، كما نصت عليه
                القوانين والأنظمة بالمملكة العربية السعودية، وأنه على علم ومعرفة
                مسبقة بأبعاد ومسؤولية إنشاء وتأسيس وإدارة متجر عبر منصة فارمر
              </li>
              <li class="mb-3 size-2 text-gray">
                في حال قيام التاجر بالتسجيل كمؤسسة أو شركة او باسم تجاري مختلف
                عن اسمه، أو أي شكل تجاري آخر، فإن هذه المؤسسة أو الشركة أو الفرد
                العادي او الشكل النظامي المسجّل عن طريقه يجب أن تتوافر لديه
                الصفة القانونية والنظامية والشرعية اللازمة للقيام بأعمال التجارة
                عبر منصة فارمر وان يكون اسم المتجر المسجل بالمنصة مملوك للتاجر
                ويتحمل التاجر او الفرد العادي لوحده أي تبعات عند مخالفة ذلك.
              </li>
              <li class="mb-3 size-2 text-gray">
                يلتزم التاجر او الفرد العادي بأنه في حال مخالفته لهذه المادة او
                أيا من المواد الواردة بهذه الاتفاقية فإنه يتحمّل تبعات هذه
                المخالفة أمام المستهلكين أو المستخدمين او مزودي الخدمات أو
                الجهات المختصة الاخرى ، حيث أن منصة فارمر لا علاقة لها بتعاملات
                التاجر مع المستهلك أو الغير من الناحية القانونية والنظامية
                والشرعية، كما أنه يحق لمنصة فارمر الرجوع والمطالبة لكل من يخالف
                أيا من بنود او مواد هذه الاتفاقية حتى لو لم يطالب الغير بذلك ،
                ويحق لنا بمنصة فارمر المطالبة بالتعويض عن الأضرار التي قد تنشأ
                نتيجة هذه المخالفة او التصرف ، ومن هذه الأضرار على سبيل المثال
                لا الحصر ما قد يتسبب لمنصة فارمر في السمعة أمام التجار الآخرين
                أو المستهلكين أو المستخدمين او مزودي الخدمات أو الجهات الرسمية.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة الثانية / علاقة المنصة مع الغير:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر مجرد منصة للربط التقني او اللوجستي لتقديم الخدمات
                الإلكترونية، وذلك من خلال تمكين الغير من تأسيس متجر الكتروني
              </li>
              <li class="mb-3 size-2 text-gray">
                التزام منصة فارمر في هذه الاتفاقية تنحصر فقط في توفير الأدوات
                الكترونية بحسب الامكانيات والخدمات المتاحة ليستطيع التاجر من عرض
                منتجاته امام المستهلكين والعملاء ولا تتحمل المنصة أي مسؤولية
                ماليه تتم بين الأطراف المتعاملين من المستهلكين او مزودي الخدمات.
              </li>
              <li class="mb-3 size-2 text-gray">
                توفّر منصة فارمر خدمات أخرى للتاجر أو المتجر كخدمات الدعم
                التسويقي أو خدمات التوجيه والإرشاد.
              </li>
              <li class="mb-3 size-2 text-gray">
                توفّر منصة فارمر بوابات دفع الكترونية من خلال الشركات المعتمدة
                لبوابات الدفع وتتم هذه باتفاقيات مستقلة بحسب رغبة الطرفين.
              </li>
              <li class="mb-3 size-2 text-gray">
                إن جميع التعاملات التي تتم بين التاجر ومزودي الخدمات ( خدمات
                الطرف الثالث ) الذين توفر منصة فارمر الربط مع خدماتهم أو عرض
                خدماتهم ليستفيد منها التاجر والمستهلك لا علاقة لها بمنصة فارمر،
                حيث أن هذا التعامل هو علاقة تعاقدية مستقلة عن منصة فارمر وخاضعة
                للاتفاق المبرم بين التاجر ومزودي الخدمة ، وبناءً عليه فإذا تخلّف
                أو امتنع أو لم يلتزم أحد الاطراف في تنفيذ التزاماته التي جرى
                الاتفاق عليها أو لم ينفذها على الوجه المطلوب فإن منصة فارمر غير
                مسئولة عن ما ينتج عن هذه التصرفات، كما ان منصة فارمر غير مسئولة
                عن أي مخالفات تحدث أو يتم ارتكابها بين التاجر ومزود الخدمة.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة الثالثة / التزامات التاجر:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                أن يكون المتجر الذي تم إنشائه على منصة فارمر غير مخالف للأنظمة
                والقوانين في المملكة العربية السعودية.
              </li>
              <li class="mb-3 size-2 text-gray">
                يلتزم التاجر بتوضيح الخدمات أو المنتجات التي يقدّمها أو يبيعها،
                وتخلي منصة فارمر مسئوليتها عن مخالفة التاجر او المتجر لأحكام
                الأنظمة في المملكة العربية السعودية.
              </li>
              <li class="mb-3 size-2 text-gray">
                يبقى لمنصة فارمر دائماً الحق في رفض او شطب تسجيل أي متجر
                الكتروني لا يتوافق مع القوانين والأنظمة في المملكة العربية
                السعودية عند اكتشاف المخالفة وبشكل فوري وعليه فإن التاجر يقر
                بموجب أحكام هذه الاتفاقية أن متجره لا يخالف النظام العام في
                المملكة العربية السعودية أو يحتوي على صور غير واقعية او تغرر
                بالمستخدمين.
              </li>
              <li class="mb-3 size-2 text-gray">
                لا يحق لأي فرد او تاجر او شركة او عميل استخدام منصة فارمر إذا
                ألغيت عضويته أو متجره من قبل الإدارة.
              </li>
              <li class="mb-3 size-2 text-gray">
                في حال قيام أي مستخدم بالتسجيل كمؤسسة أو شركة، أو جهة خيرية
                فتكون ملزمة بكافة القواعد والأحكام المذكورة في اتفاقية الاستخدام
                الخاصة بمنصة فارمر ويلزم تزويد المنصة بكافة المعلومات مثل السجل
                التجاري وأي وثائق أخرى للمتجر تطلبها منصة فارمر للتسجيل بصفة
                قانونية ويكتفى بالتسجيل في معروف في حالة كان حساب موثق.
              </li>
              <li class="mb-3 size-2 text-gray">
                ينبغي على كافة المتاجر والتُجّار الالتزام بكافة القوانين المعمول
                بها لتنظيم التجارة عبر الانترنت وكذلك الالتزام بنظام الجرائم
                المعلوماتية وأنظمة وزارة التجارة والاستثمار واي أنظمة متممة
                ومعتمدة من قبل السلطات السعودية.
              </li>
              <li class="mb-3 size-2 text-gray">
                يقر التاجر بأنه قبل تسجيله في منصة فارمر قد تحقق من توافر جميع
                الاشتراطات والإجراءات المطلوبة من قبل الجهات الرسمية في المملكة
                العربية السعودية وقد استوفى جميع هذه الإجراءات والاشتراطات
                لممارسة نشاطه في التجارة الالكترونية.
              </li>
              <li class="mb-3 size-2 text-gray">
                التاجر الفرد يلتزم بالتحقق من الاشتراطات المطلوبة لدى الجهات
                الرسمية وتوفيرها بحسب طبيعة نشاط الفرد التاجر، حيث أن التاجر
                الفرد يقر بأنه مسؤول وملتزم بهذه الاشتراطات وتجهيزها، كما يلتزم
                بتوفير رقم هويته الوطنية وغير ذلك من المعلومات اللازمة والوثائق
                التي تطلبها المنصة ويكفي عن ذلك اثبات تسجيله في منصة معروف كعضو
                موثق وتزويدنا بما يثبت ذلك.
              </li>
              <li class="mb-3 size-2 text-gray">
                عند التقدم بطلب الانضمام إلى عضوية منصة فارمر أو طلب إنشاء
                المتجر الالكتروني يلزم توفير رقم هاتف جوال ليربط الحساب الرئيسي
                به وبعد تنشيط حسابك سوف تصبح مستخدمًا لخدمات منصة فارمر وتكون قد
                وافقت على التالي:

                <ul>
                  <li class="mb-3 size-2 text-gray">
                    المحافظة على سرية معلومات حسابك وسرية كلمة المرور، وإعلام
                    منصة فارمر بشكل فوري بأي استخدام غير مفوض به لمعلومات حسابك
                    لدى منصة فارمر أو أي اختراق آخر لمعلوماتك السرية.
                  </li>
                  <li class="mb-3 size-2 text-gray">
                    ان المنصة وبأي حال من الأحوال غير مسؤولًة عن أي خسارة قد
                    تلحق بك بشكل مباشر أو غير مباشر معنوياً أو مادياً نتيجة كشف
                    معلومات اسم المستخدم أو كلمة المرور وستكون حدود مسؤولية منصة
                    فارمر هو إيقاف الخدمة عن الحساب في نفس وصول الطلب منكم
                    رسمياً على ايميل المنصة الرسمي.
                  </li>
                  <li class="mb-3 size-2 text-gray">
                    الالتزام باستخدام الحساب من خلال صاحب الحساب وفي حال استخدام
                    شخص آخر له فهذه يعني أنه مفوض باستخدام الحساب وانه يعمل
                    لحساب الشركة او الفرد مالم يتم إبلاغ إدارة المنصة بعكس ذلك.
                  </li>
                  <li class="mb-3 size-2 text-gray">
                    عند استخدام منصة فارمر فانت تلتزم بكل الجدية والمصداقية تجاه
                    الانظمة والعملاء ومزودي الخدمات، وتعتبر ملزماً بتعويض منصة
                    فارمر عن أي خسائر مباشرة أو غير مباشرة قد تلحق بالمنصة نتيجة
                    أي استخدام غير شرعي أو غير حقيقي أو غير جدي او غير مفوض
                    لحسابك من طرفك أو من طرف أي شخص آخر حصل على حسابك بالمنصة
                    سواء كان لإنجاز الخدمات باستعمال اسم المستخدم وكلمة المرور
                    أو نتيجة لإهمالك بالمحافظة على سرية اسم المستخدم وكلمة
                    المرور، وسواء بتفويض منك أو بدون تفويض.
                  </li>
                  <li class="mb-3 size-2 text-gray">
                    تلتزم بتزويدنا بمعلومات حقيقية وصحيحة ومحدثة وكاملة وقانونية
                    عن نفسك ونشاطك حسبما هو مطلوب أثناء التسجيل لدى منصة فارمر
                    وتلتزم بتحديث بياناتك في حال تغييرها في الواقع أو في حال
                    الحاجة إلى ذلك ويحق للمنصة إيقاف او تجميد حسابك عند مخالفة
                    ذلك وبدون الحق في المطالبة باي تعويض.
                  </li>
                  <li class="mb-3 size-2 text-gray">
                    أن لا يتضمن تفاصيل الاتصال بك كعناوين البريد الإلكتروني أو
                    المنشورات الخ، أو أي عبارة باي شكل تُشير إلى علاقة شخصية أو
                    تجارية بينك وبين منصة فامر أو منسوبيها أو مُلّاكها.
                  </li>
                  <li class="mb-3 size-2 text-gray">
                    يجب ان يكون واضح للعملاء ان منصة فارمر لا علاقة لها بما تقوم
                    به في متجرك وهي غير مسئولة عن نشاطك وأنها مجرد وسيلة للربط
                    بينك وبين المستهلكين فقط.
                  </li>
                  <li class="mb-3 size-2 text-gray">
                    نلتزم بالتعامل مع معلوماتك الشخصية وعناوين الاتصال بك بسريّة
                    وفقاً لأحكام سياسة الخصوصية وسرية المعلومات المعمول بها
                    لدينا.
                  </li>
                  <li class="mb-3 size-2 text-gray">
                    لمنصة فارمر الحق في أي وقت أن تجري أي تحقيقات تراها ضرورية
                    سواء كانت مباشرة أو عبر طرف ثالث وتطالبك بالإفصاح عن أي
                    معلومات إضافية أو وثائق مهما كان حجمها لإثبات هويتك أو
                    ملكيتك للأشياء التي تبيعها من خلال المتجر وفي حالة مخالفتك
                    لأنظمة العلامات التجارية او الغش او تضليل المستهلكين ويحق
                    للمنصة رفع دعوى بالنيابة عن المستهلكين إذا وجدت أي مخالفة.
                  </li>
                  <li class="mb-3 size-2 text-gray">
                    في حالة عدم الالتزام بأي مما ورد أعلاه فإن لإدارة منصة فارمر
                    الحق الكامل في إيقاف أو إلغاء متجرك أو حسابك وتحتفظ كذلك
                    بالحق في إلغاء أي حسابات غير مؤكدة وغير مثبتة أو عمليات أو
                    حسابات مرعيها مدة طويلة دون نشاط.
                  </li>
                </ul>
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">المادة الرابعة / التواصل:</h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                توافق أن يتم التواصل معك عبر البريد الإلكتروني المسجل ان وجد و
                من خلال الرسائل النصية او الواتس اب على الجوال المسجل وهي حجة
                قائمة بذاتها في تلبية الاحتياجات القانونية.
              </li>
              <li class="mb-3 size-2 text-gray">
                سوف تقوم منصة فارمر خلال فترة عضويتك وتجارتك لدى المنصة بإرسال
                رسائل إلكترونية ترويجية لتعريفك وتعريف عملائك بأي تغيرات أو
                إجراءات أو نشاطات دعائية جديدة قد تضاف.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">المادة الخامسة / الرسوم:</h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                ستقوم منصة فارمر بإعلامك عن أي تعديل على هذه الاتفاقية وبموجبه
                تتضاعف التزاماتك أو تتضاءل حقوقك وفقاً لأي تعديلات قد تجري.
              </li>
              <li class="mb-3 size-2 text-gray">
                أنت توافق على أن منصة فارمر تملك وبمطلق حريتها أن تجري أي
                تعديلات أساسية أو فرعية على هذه الاتفاقية وبدون أي مسؤولية تجاه
                الغير، ويتم إعلام المستخدمين والتجار عن هذا التعديل بأي وسيلة
                تقنية يتم توفيرها سوائاً بريد الكتروني رسالة نصية او واتس اب او
                اشعار داخل التطبيق وقد توفر منصة فارمر وسائل تقنية تضهر قبول
                المتجر لهذا التعديل، ويكون هذا القبول قانوني ونافي للجهالة وملزم
                للمتجر باي تعديل او اضافة او تنظيم جديد .
              </li>
              <li class="mb-3 size-2 text-gray">
                في حال الاعتراض على أي تعديل في اتفاقية الاستخدام لاحقاً يجب
                التوقف عن استخدام الحساب وأن مجرد الدخول إلى حسابكم في منصة
                فارمر أو استخدامكم لأي خدمة يعد قبولًا منكم للتعديلات وموافقة
                كاملة تامة نافية للجهالة.
              </li>
              <li class="mb-3 size-2 text-gray">
                الرسوم تحتسب بالريال، وعلى التاجر دفع كافة الرسوم المستحقة
                بالمنصة مضافاً إليها أي نفقات أخرى تضيفها المنصة، على أن يتم
                السداد بواسطة الوسائل المعتمدة والمحددة والمتاحة.
              </li>
              <li class="mb-3 size-2 text-gray">
                ليست كل عروض منصة فارمر مجانية للتجار حيث أن بعض هذه العروض تخضع
                لرسوم متفاوتة وذلك بحسب الملحق المالي لهذه الاتفاقية ان وجد.
              </li>
              <li class="mb-3 size-2 text-gray">
                قد تفرض منصة فارمر رسومًا على التجار وذلك يعتمد على نوع المتجر
                ونطاق التغطية .
              </li>
              <li class="mb-3 size-2 text-gray">
                تحتفظ منصة فارمر بحقها في إضافة أو زيادة أو خفض أو خصم أي رسوم
                أو نفقات لاحقاً وبعد علم جميع الأطراف باي تعديل يطرأ.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة السادسة / طرق الدفع والسداد:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                توفّر منصة فارمر عبر شريك او مجموعة شركاء لأنظمة الدفع والسداد
                الالكتروني خدمة استقبال الدفع من العملاء لمشترياتهم من المتاجر
                ويلتزم التاجر باستقبال كافة أنواع البطاقات المعتمدة للشبكة
                السعودية او أي طريقة دفع توفرها منصة فارمر من حين لآخر.
              </li>
              <li class="mb-3 size-2 text-gray">
                ليس هناك أي علاقة لمنصة فارمر بطريقة الدفع عند الاستلام فطريقة
                الدفع هذه تخضع للعلاقة فيما بين المستهلك والتاجر ومزود الخدمة.
              </li>
              <li class="mb-3 size-2 text-gray">
                يجوز أن تشترط منصة فارمر في أي وقت وأي حالٍ من الأحوال أن تتم
                عمليات الدفع مباشرةً بين التاجر والمستهلك عبر حساباتهم البنكية
                الخاصة او الربط المباشر بالدفع الالكتروني لكل متجر على حده وبذلك
                لن تكون المنصة وسيط للدفع.
              </li>
              <li class="mb-3 size-2 text-gray">
                إن توفير منصة فارمر لخدمة الدفع الالكتروني هو بغرض التسهيل
                والمحافظة على حقوق التجار والمستهلكين.
              </li>
              <li class="mb-3 size-2 text-gray">
                يلتزم التاجر بتحديد سعر الخدمة أو البضاعة التي يقوم بعرضها في
                متجره وفقاً للقيمة السوقية المتعارف عليها، ولا علاقة لمنصة فارمر
                بأي حال من الأحوال بسوء تقدير تكلفة المنتجات أو الخدمات المعروضة
                في المتاجر على المنصة حيث أن تقديرها بالشكل المتعارف عليه
                تجارياً هو من اختصاص التاجر وحده.
              </li>
              <li class="mb-3 size-2 text-gray">
                يلتزم التاجر بتوفير فواتير وسندات قبض وسندات استلام لجميع
                المبالغ والأرباح التي تنشأ في متجره، ويلتزم أن يبيّن في جميع هذه
                الفواتير نوع البضاعة أو الخدمة وكمياتها وأوصافها وقيمتها، وعليه
                فإن التاجر يلتزم بتوفير المواصفات المحاسبية المطلوبة في متجره
                الالكتروني، تطبيقاً لأحكام هذه الاتفاقية، ولما في هذا التنظيم من
                مصالح قانونية واقتصادية وتجارية للتجار، وفي حالة مخالفة التاجر
                لأحكام هذا البند فإنه يُعَد مسئولاً عن أي أضرار قد تنشأ نتيجة
                هذه المخالفة.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر لها الحق في منع استكمال إجراءات أي عملية دفع مخالفة
                لقواعد وأحكام اتفاقية الاستخدام أو إلغاء أي طلب شراء أو بيع
                نتيجة خطأ فني أو تقني في المنصة أدى إلى اختلاف الأسعار المعروضة
                على المنصة عن قيمة المنتج السوقية.
              </li>
              <li class="mb-3 size-2 text-gray">
                لإدارة منصة فارمر الحق في إلغاء أو تعديل أو تغيير أي من وسائل
                الدفع التي قامت بإتاحتها سابقاً وبدون الرجوع لاحد.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة السابعة / احقية الاستخدام:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                تُمنح منصة فارمر حقاً غير محدود، وعالمي، ودائم وغير قابل
                للإلغاء، ومعفي من المصاريف، ومرخص باستخدام معلومات أو مواد شخصية
                أو غير ذلك مما وفرتها أو زودت بها المنصة أو أعلنتها على المنصة
                من خلال انضمامك إليها أو تأسيسك لمتجرك، وذلك عبر النماذج المخصصة
                للتواصل والتسجيل، أو عبر أية رسالة إلكترونية أو أي من قنوات
                الاتصال المتاحة بالمنصة. وذلك بهدف تحقيق أي من المصالح التي
                تراها المنصة.
              </li>
              <li class="mb-3 size-2 text-gray">
                أنت الوحيد المسؤول عن المعلومات التي قمت بإرسالها أو نشرها
                وينحصر دور منصة فارمر بالسماح لك بعرض هذه المعلومات عن طريق
                المنصة ومن خلال قنواتها الإعلانية.
              </li>
              <li class="mb-3 size-2 text-gray">
                تخضع سرية معلومات المتاجر والتجار لقواعد “سياسة الخصوصية وسرية
                المعلومات” الخاصة بمنصة فارمر.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة الثامنة / الحقوق الفكرية:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                كافة محتويات منصة فارمر سوائاً التقنية او المكتوبة او الشكل او
                تسلسل الاستخدام او التصاميم الجرافيكية او واجهة المستخدم وترابط
                الاعمال وطريقة تقسيمها هي ملك لمنصة فارمر وحقوقها محفوظة للمنصة
                ويلتزم المستخدم والتاجر باحترام ذلك والمحافظة عليه ويتحمل أي
                تبعات تنتج عن مخالفة ذلك حتى وان كان عن طريق طرف ثالث مرتبط بأحد
                الأطراف المستخدمين لخدمات المنصة.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر تمتلك الحق في اتخاذ اللازم حيال التجاوز والتعدي على
                أي من حقوق ملكيتها الفكرية.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر غير مسؤولة في حال التعدي على حقوق الملكية الفكرية
                للتجار في المنصة ويبقى الحق خاصاً بالتاجر يتولى اتخاذ ما يراه
                مناسباً تجاه الجهة نفسها.
              </li>
              <li class="mb-3 size-2 text-gray">
                تحترم منصة فارمر حقوق الملكية الفكرية الخاصة بالتجار والمسجلة
                بالمنصة وتحافظ قدر المستطاع على عدم تكرارها في حالة تقديم ما
                يثبت هذه الملكية وفي حالة وجود أي خلاف فإن الجهات المختصة هي من
                يبت فيه للأطراف صاحبة النزاع ولا تتحمل منصة فارمر أي تبعات
                قانونية تجاه أيا من الطرفين بل تكون المسؤولية قائمة تجاه أطراف
                النزاع بصفتهم الشخصية.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة التاسعة / التزامات المستهلك:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                يلتزم المستهلك عند تسجيله في منصة فارمر بالمعلومات التالية
                (الاسم – البريد الالكتروني – الهاتف واي معلومات أخرى تطلبها
                المنصة.
              </li>
              <li class="mb-3 size-2 text-gray">
                تتعهد بأنك غير منافس او تنوى المنافسة لعمل المنصة او اياً من
                عملائها وعند ثبوت ذلك فهو تعدى صريح على حقوق الملكية الفكرية
                وسعي للحصول على معلومات غير مرخص او مسموح لك بالحصول عليها ويحق
                لادارة المنصة المطالبة بأي اضرار ناتجة عن ذلك.
              </li>
              <li class="mb-3 size-2 text-gray">
                تحتفظ المنصة ببيانات المستهلكين وذلك من اجل توثيق وتسهيل دخولهم
                إلى المنصة للاستفادة من الخدمات المطروحة بها .
              </li>
              <li class="mb-3 size-2 text-gray">
                عدم استخدام المنصة او خدمات أيا من التجار بشكل سيئ او عمل طلبات
                وهمية او غير جاده او كان المقصود منها الاضرار بالمنصة او التجار
                وعند فعل ذلك فيحق للأطراف المتضررة المطالبة بالتعويض اللازم.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة العاشرة / حقوق المستهلك
            </h2>
            <ul>
              <li class="mb-3 size-2 text-gray">
                عند قيام المستهلك بشراء بضاعة من منصة فارمر فتكون حقوقه تجاه
                المنصة كما يلي:
              </li>
              <ol>
                <li class="mb-3 size-2 text-gray">
                  اذا لم يتسلم بضاعته أو أنه تسلم بضاعة تختلف عن ما عرض من
                  مواصفات بالمتجر حينها يكون من حق المستهلك إبلاغ منصة فارمر
                  وللمنصة الحق في اتخاذ ما تراه مناسب حيال المتجر الذي خالف
                  قواعد هذه الاتفاقية والمبنية على التقيد بالأنظمة بالمملكة
                  العربية السعودية غير أن منصة فارمر غير مسئولة قانوناً أو شرعاً
                  عن هذا الإخلال الحاصل من قبل التاجر تجاه المستهلك، حيث أن
                  المنصة ليست طرفاً في العلاقة بين المستهلك والتاجر، ولكنها تقوم
                  بذلك لدواعي تحسين جودة منصة فارمر والتجار المتواجدين على
                  المنصة وقد نتخذ اجراء حجب المتجر عن الضهور ونكتفى بذلك ويقوم
                  المستهلك بالمطالبة من خلال الجهات المختصة وبحيث تتجه دعواه
                  للتاجر بصفته الشخصية او الاعتبارية بدون ادنى مسؤولية على منصة
                  فارمر .
                </li>
                <li class="mb-3 size-2 text-gray">
                  للمستهلك مراجعة مشترياته قبل الاستلام للتأكد من أنها سليمة
                  ومطابقة لما تم شرائه، حيث أن منصة فارمر غير مسئولة عن العلاقة
                  التي بين المستهلك والتاجر عند وجود أي خلاف لا قدر الله وفي
                  حالة الاستلام فإن ذلك يعتبر موافقه بان البضاعة مطابقة.
                </li>
                <li class="mb-3 size-2 text-gray">
                  إن منصة فارمر غير ملزمة بأي حال من الأحوال باتخاذ ردّة فعل
                  تجاه شكوى المستهلك وإنما تخضع ردّة فعل المنصة تجاه شكوى
                  المستهلك إلى محض رغبة منصة فارمر ووفق ما تراه المنصة مناسبًا
                  وبما يحافظ على مصلحتها وسمعتها التجارية والاقتصادية، وعلى كل
                  حال فإن منصة فارمر قدّ تتخذ بعض الإجراءات تجاه المتاجر التي
                  تتعمّد خداع أو تظليل أو غش المستهلك ، مثل : تجميد عضوية المتجر
                  أو لفت نظر المتجر إلى عدم تكرار هذا الأمر أو إلزام المتجر
                  بتعويض المستهلك ، وذلك انطلاقًا من رغبة منصة فارمر في حفظ
                  وحماية حقوق كافة المستخدمين والمسجّلين لديها وبدون الزام لها
                  بذلك من قبل المستهلكين .
                </li>
              </ol>
            </ul>
            <h2 class="weight-bold size-3 mb-3">
              المادة الحادية عشر / مسئولية المنصة:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر غير مسؤولة بأي شكل من الأشكال عن أي أداء غير مرض أو
                متأخر من قبل المتاجر أو شركات الشحن أو بوابات الدفع الإلكتروني
                أو البنوك أو عدم التزام المستهلكين بالسداد، ولا عن أي خسائر، أو
                أعطال أو تأخير، بسبب بضاعة غير متوفرة أو تأخر تسليمها أو عدم
                جودة الخدمة المقدمة من قبل المتاجر.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر لا تتحمل أي مطالبات تنشأ عن أخطاء أو إهمال، سواء كانت
                ناتجة بشكل مباشر أو غير مباشر أو عرضي أو عن طريق طرف ثالث.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر لا تتحمل أي مطالبات أو مسئوليات نشأت نتيجة خسائر
                مالية أو تشويه سمعة أو قذف أو أي أضرار تنشأ نتيجة لسوء استخدام
                أو إساءة استخدام أو عدم قدرة على استخدام منصة فارمر ولا تتحمل أي
                مسؤوليات أو مطالبات في مثل هذه الحالات.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر أو مسؤوليها أو منسوبيها أو مُلّاكها غير مسئولين عن أي
                مطالبة أو نزاع أو تكاليف أو أضرار أو أي مسئولية أو أي خسارة
                مباشرة أو غير مباشرة لأي طرف نشأت عن فعل قام به أحد المستخدمين
                للمنصة.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر ومنسوبيها ومُلّاكها ومن يمثلها ويمثلهم لا علاقة لهم
                بأي مسئولية تخص منتج سليم و شرعي و مصرح به وفق قوانين وأنظمة
                المملكة العربية السعودية ويتم استخدامه لأغراض غير شرعية وغير
                نظامية، ولا يحق لأي شخص أو جهة الرجوع على منصة فارمر بأي دعوى أو
                مطالبة أو تعويض فيما يخص سوء الاستخدام المذكور، حيث أن جميع
                المنتجات أو الخدمات المقدّمة في منصة فارمر تقدّم عن طريق التجار
                ومتاجرهم.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصّة فارمر لا علاقة لها بأي نشاط غير شرعي أو غير نظامي يقوم به
                المتجر، أو أي نشاط يخالف الأنظمة والتعليمات في المملكة العربية
                السعودية، حيث تنحصر مسئولية منصة فارمر في توفير وتقديم أدوات
                الدعم التقني كتأسيس المتجر الالكتروني وما يتبع ذلك من خدمات دعم
                تقني.
              </li>
              <li class="mb-3 size-2 text-gray">
                تعلم إدارة منصة فارمر كافة التجار أنها في حال ملاحظاتها لأي
                أنشطة مشبوهة يجري التخطيط لارتكابها أو جاري ارتكابها أو جرت على
                المنصة فإنها سوف تقدم بلاغ للسلطات المختصة عن هذه الأنشطة وهي
                غير مسئولة بأي حال عن هذه المخالفات التي تتم دون علم أو ملاحظة
                المنصة.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة الثانية عشر / سرية المعلومات:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                من المعلوم والمتعارف عليه بأن استخدام الانترنت والتطبيقات
                والمنصات التقنية ليست آمنة ١٠٠٪ وسرية المعلومات الشخصية لا يمكن
                ضمان حفضها بشكل قاطع .
              </li>
              <li class="mb-3 size-2 text-gray">
                توفر منصة فارمر أفضل معايير حماية المستخدمين والمستهلكين لمنع
                وصول أي شخص غير مفوض او مصرح له إلى معلومات المستخدمين الشخصية
                أوبيانات متاجرهم.
              </li>
              <li class="mb-3 size-2 text-gray">
                أنت تعلم وتوافق على أن منصة فارمر قد تستخدم معلوماتك التي زودتها
                بها، بهدف تقديم الخدمات لك في المنصة، ولإرسال رسائل تسويقية لك
                او لعملائك السابقين او المحتملين وتخضع لقواعد سرية المعلومات لـ
                سياسة الخصوصية وسرية المعلومات المتوفر بالموقع.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة الثالثة عشر / إلغاء العضوية:
            </h2>
            <ul>
              <li class="mb-3 size-2 text-gray">
                إن منصة فارمر بحسب اتفاقية الاستخدام هذه او أي عقود ملحقة أخرى
                وبحسب القوانين والأنظمة بالمملكة العربية السعودية قد تلجأ إلى
                وقف مؤقت أو دائم للعضوية او المتجر في حالة التالي:
              </li>
              <ol>
                <li class="mb-3 size-2 text-gray">
                  انتهاك قواعد وأحكام اتفاقية الاستخدام.
                </li>
                <li class="mb-3 size-2 text-gray">
                  إذا لم يكن بإمكان منصة فارمر توثيق أي من معلومات المستخدم
                  المقدمة إليها.
                </li>
                <li class="mb-3 size-2 text-gray">
                  إذا قررت منصة فارمر أن نشاطات المستخدم او التاجر مخالفة
                  للقانون أو أن نشاطات المستخدم قد تتسبب لمستخدمين آخرين أو
                  للمنصة بمتاعب أو مخالفات قانونية.
                </li>
                <li class="mb-3 size-2 text-gray">
                  قد توافق المنصة إلى إعادة نشاط المستخدمين الموقوفين او بعضهم
                  بحسب تقديرها وبدون التبرير لاحد
                </li>
                <li class="mb-3 size-2 text-gray">
                  إن منصة فارمر تحافظ على حقها في اتخاذ الإجراءات المناسبة في
                  حالة انتهاك قواعد وأحكام اتفاقية الاستخدام او أي عقود متممة او
                  متفرعه من هذه الاتفاقية
                </li>
                <li class="mb-3 size-2 text-gray">
                  بدون إلحاق ضرر بحقوق التاجر الأخرى يمكن للمنصة وقف أو إلغاء
                  العضوية او تقييد بعض خدمات المنصة وفي أي وقت وبدون إنذار ولأي
                  سبب ودون أي مبرر.
                </li>
              </ol>
            </ul>

            <h2 class="weight-bold size-3 mb-3">
              المادة الرابعة عشر / عمليات الدفع والبيع والشراء:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                التاجر ملزم بإدارة المتجر واستقبال الطلبات والتأكد من الدفع
                واختيار طرق الدفع التي يفضلها التاجر في البداية وبما يحافظ على
                المستهلك و نحن كمنصة فارمر قد نختار طرق دفع بديلة او محددة وبحسب
                ما تقتضية المصلحة لكي لا ينشئ نزاعاً بين التاجر وبين المستهلك
                وتقدير ذلك هو لإدارة المنصة وحدها ويلتزم التاجر باي تنظيم جديد
                لذلك بحسب ما تقتضية مصلحة كل الأطراف.
              </li>
              <li class="mb-3 size-2 text-gray">
                يلتزم التاجر بإدارة عمليات الدفع والبيع والشراء التي تتم في
                متجره عبر وسائل الدفع المقرّرة في هذه الاتفاقية او التي يتم
                تقريرها لاحقاً بناءً على المصلحة المشتركة لكل المتعاملين
                بالمنصة.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر لا علاقة لها بأي نزاع بين المستهلك والتاجر او مزودي
                الخدمات، ولا علاقة للمنصة بأي تقصير يحصل من المستهلك تجاه التاجر
                مالياً.
              </li>
              <li class="mb-3 size-2 text-gray">
                يعلم التاجر والعملاء ومزودي الخدمات علماً تاماً ونافياً للجهالة
                بأن إدارة منصة فارمر تتبع في استقبال اوتحويل الأموال داخل المنصة
                او خارجها سوائاً كان من حساب بنكي لحساب بنكي آخر وكل ذلك يخضع
                لنظام مؤسسة النقد العربي السعودي، وبحسب أنظمة تنظيم التجارة
                الإلكترونية على عمليات البيع والشراء الإلكترونية.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر قد تفرض رسوم على التجار نتيجة لعملياتهم داخل المنصة،
                والرسوم قد تكون على سبيل المثال لا الحصر: رسوم الجهات الحكومية
                أو رسوم بنكية أو رسوم إدارية أو أي رسوم أخرى ، تجري مطالبة
                المنصة بدفعها نتيجة لنشاط التاجر أو الفرد الذي يستخدم المنصة
                بغرض التجارة ولن يتم فرض هذه الرسوم الا في ضل وجود تنظيم رسمي
                حكومي لذلك.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة الخامسة عشر / الغير مسموح به :
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                كتاجر في منصة فارمر تلتزم بعدم إعلان أو نشر اي محتوى مخالف
                لسياسة الخصوصية وسرية المعلومات أو مخالف لقواعد وأحكام اتفاقية
                الاستخدام هذه او الانظمة العامة او ما يكون فيه انتهاك لنظام
                العلامات التجارية الخليجي او القيم الإسلامية.
              </li>
              <li class="mb-3 size-2 text-gray">
                عرض أي ممنوعات او محرمات او منتجات تحتاج لاذن مسبق للبيع.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة السادسة عشر / الجرائم المعلوماتية :
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                يلتزم التجار بعدم مخالفة أي من أحكام نظام الجرائم المعلوماتية
                السعودي، وفي حال مخالفتهم لأحكام نظام الجرائم المعلوماتية
                السعودي فإن هذا يخضع لمسئولتيهم المحضة ولإدارتهم، وليس هناك أي
                مسئولية تقع على عاتق منصة فارمر ويكون التاجر هو المسئول عن متجره
                وعن تعامله مع المستهلكين مسئولية كاملة.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة السابعة عشر / الضمان:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                لا نضمن في المنصة إصلاح الأعطال ولا نضمن خلو المنتجات التي تقدم
                من التجار العيوب وإنما يضمنها المتجر أو التاجر في حالة ذكر
                الضمان ومدته وذلك بوصف المنتج او ان يتأكد المستهلك من فترة
                الضمان ووجودها من عدمه ماعدا المنتجات التي يوجد لها تنظيم حكومي
                الزامي للضمان، وعلى التاجر أن يلتزم بحسن الجودة للبضاعة.
              </li>
              <li class="mb-3 size-2 text-gray">
                يلتزم المتجر بوضع سياسة الاستبدال والاسترجاع التي تخص متجره، كما
                يجب عليه أن يلتزم بأحكام نظام التجارة الالكترونية وغير ذلك من
                الأنظمة التي تتعلّق بالضمان أو بالتجارة الالكترونية.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة الثامنة عشر / مسئولية التاجر:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                يوافق التاجر على تحمل المسئولية بالنيابة عن منصة فارمر أو
                منسوبيها أو مُلّاكها أو المتفرعين عنها من أي ضرر قد يقع على منصة
                فارمر من جراء مخالفات التاجر ، كما يلتزم برفع أي ضرر أو منع أي
                ضرر قد يلحق بمنصة فارمر أو بمنسوبيها أو بأحدهم من جراء مطالبات
                أو خسائر أو أعطال أو تكاليف أو نفقات أو رسوم نتجت بسبب إساءة
                استخدام التاجر أو سوء استخدام المستخدم ونتج عن ذلك خرق لاتفاقية
                الاستخدام أو الأنظمة والقوانين بالمملكة العربية السعودية أو تعدي
                على حقوق أحد التجار أو أطراف ثالثة أو شكوى من أحد المستخدمين أو
                أطراف أخرى.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة التاسعة عشر / العلاقة والمراسلات بين منصة فارمر والتجار:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                لا تتضمن أي من قواعد وأحكام اتفاقية الاستخدام هذه إشارة إلى وجود
                شراكة بين أي تاجر ومنصة فارمر، ولا تسمح منصة فارمر لأي تاجر في
                أي حال من الأحوال بالإشارة المباشرة أو الغير مباشرة أو الإملاء
                إلى وجود علاقة أي كان نوعها سواء كانت مباشرة أو غير مباشرة بينه
                كتاجر وبين منصة فارمر و إدارتها،
              </li>
              <li class="mb-3 size-2 text-gray">
                عندما يرغب التاجر في إرسال رسالة او معلومة إلى منصة فارمر، عليه
                أن يقوم بإرسالها عبر البريد الإلكتروني وتقوم منصة فارمر بالرد
                على الرسالة الإلكترونية باقرب وقت ممكن.
              </li>
              <li class="mb-3 size-2 text-gray">
                توافق كتاجر على أن ترسل إليك أي رسالة من منصة فارمر بواسطة
                البريد الإلكتروني الذي قمت بتزويد منصة فارمر به خلال عملية
                التسجيل او من خلال رسالة واتس اب او رسالة نصية على الجوال المسجل
                بالمتجر.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة العشرون / نقل الحقوق والالتزامات:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                أنت كتاجر لا تملك الحق في الاعتراض على تصرفات إدارة منصة فارمر
                حيال طريقة عملها او استراتيجية المنصة سواء كانت هذه التصرفات على
                سبيل المثال لا الحصر: تمس كيان منصة فارمر أو التزاماتها أو
                ملكيتها أو مسئولياتها أو تصرفات تقنية أو تصرفات إدارية تتعلّق
                بمنصة فارمر، ولا تلتزم منصة فارمر بإبلاغك، ولمنصة فارمر إن رأت
                أهمية إبلاغك عن أي من هذه التصرفات أن تقوم بإبلاغك وفقاً
                لإرادتها المحضة فقط.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة الحادية والعشرون / قواعد أساسية عند التعامل مع المستهلك:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                يجب على التاجر عند تعامله مع المستهلك أن يلتزم بالصدق والأمانة
                والحيادية.
              </li>
              <li class="mb-3 size-2 text-gray">
                يلتزم التاجر بحسن الأخلاق والرقي في التعامل من خلاله وكذلك
                موظفيه.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة الثانية والعشرون / الخدمات اللوجستية (خدمات الطرف الثالث):
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                بموجب قواعد وأحكام اتفاقية الاستخدام هذه فإن منصة فارمر قد تساعد
                بتوفير بعض الخدمات اللوجستية عن طريق طرف ثالث وقد تكون هذه
                الخدمات على سبيل المثال لا الحصر: خدمات شركات الشحن وتوصيل
                المنتجات والبضائع والتخزين.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر بتوفيرها للخدمات اللوجستية لا تعتبر الزاماً لها وليس
                إلا تسهيلًا وتعاونًا منها ولمساعدة مستخدمين منصة فارمر.
              </li>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر بأنها غير مسئولة بشكل مباشر أو غير مباشر عن أي تصرفات
                تصدر من أي طرف ثالث وأن ما تقوم به هو مجرد ربط تقني او تشغيلي
                بين المستخدم ومُقدِم الخدمة (الطرف الثالث) وتكون هذه العلاقة
                أحكامها مستقلة وتتم بين التاجر وبين الطرف الثالث.
              </li>
              <li class="mb-3 size-2 text-gray">
                إن بعض مقدمي الخدمات اللوجستية يضعون اشتراطات خاصة بهم أو تكاليف
                متنوعة ولا تمتلك منصة فارمر أي سلطة على هذه الاشتراطات أو هذه
                التكاليف، ولذلك تنصح منصة فارمر التجار المسجلين لديها إلى
                الاطلاع على شروط مقدم الخدمة (الطرف الثالث) وتكاليف خدماته قبل
                التعامل معه.
              </li>
              <li class="mb-3 size-2 text-gray">
                عند التعامل مع مقدم الخدمة (الطرف الثالث) فسيتم من خلال المنصة
                تزويده ببيانات المستخدم الشخصية التي يطلبها، وغير ذلك من
                البيانات التي يحتاجها مقدّم الخدمة (الطرف الثالث)، ويكون ذلك
                وفقاً لقواعد وأحكام سياسة الخصوصية وسرية المعلومات المعمول بها
                لدى منصة فارمر.
              </li>
            </ol>
            <h2 class="weight-bold size-3 mb-3">
              المادة الثالثة والعشرون / الدعم الفني:
            </h2>
            <ul>
              <li class="mb-3 size-2 text-gray">
                منصة فارمر توفر بعض خدمات الدعم الفني للمتاجر، ويكون ذلك وفقاً
                لنوع المتجر ونطاق التغطية وتكون على سبيل المثال:
              </li>
              <ol>
                <li class="mb-3 size-2 text-gray">
                  لوحة تحكم مجانية للمتاجر وبخدمات مخصصة .
                </li>
                <li class="mb-3 size-2 text-gray">
                  إضافة بعض الخدمات الجديدة التي يطلبها التاجر والتي غالبًا ما
                  تخضع لرسوم مالية ويبقى تقدير ذلك لإدارة المنصة .
                </li>
                <li class="mb-3 size-2 text-gray">
                  اذا وجد أي مقترح او تعديل يخدم الأعضاء فإن المنصة ستعمل
                  لإضافته بأقرب وقت.
                </li>
              </ol>
            </ul>
            <h2 class="weight-bold size-3 mb-3">
              المادة الرابعة والعشرون / الأحكام العامة:
            </h2>
            <ol>
              <li class="mb-3 size-2 text-gray">
                في حال النزاع لا سمح الله فيتم حل الخلاف عن طريق المفاوضات
                الودية وفي حال استمرار الخلاف فيتم حله عن طريق الجهات المختصة .
              </li>
              <li class="mb-3 size-2 text-gray">
                في حال إلغاء أي مادة أو بند في هذه الاتفاقية فإن هذا لا يلغي
                باقي المواد والبنود والقواعد وتظل سارية حتى إشعار آخر او تعديل
                معلن ورسمي من إدارة منصة فارمر.
              </li>
            </ol>
            <ul>
              <li class="mb-3 size-2 text-gray">
                تعدل بين حين وآخر بحسب مقتضى الحال آلية العمل والتفاهم والاتفاق
                والتعاقد بين المنصة والتاجر ويلتزم التاجر ويوافق على أن يضع في
                الاعتبار ما يلي:
              </li>
              <ol>
                <li class="mb-3 size-2 text-gray">
                  هذه الاتفاقية سارية وهي العقد فيما بين التاجر ومنصة فارمر وذلك
                  بمجرد تسجيل التاجر ومزاولة البيع من خلال المنصة ولا يلزم
                  التوقيع عليها من الطرفين
                </li>
                <li class="mb-3 size-2 text-gray">
                  على المتجر أن يضع اتفاقية استخدام خاصة به ولا تتعارض مع مصالح
                  منصة فارمر وعلى أن تكون وفقاً لأحكام الأنظمة والقوانين المعمول
                  بها في المملكة العربية السعودية وعلى وجه الخصوص نظام التعاملات
                  الالكترونية ونظام التجارة الالكترونية وغيرها من الأنظمة التي
                  تتعلّق بنشاط التاجر ومن أي جهة حكومية ذات اختصاص.
                </li>
              </ol>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>