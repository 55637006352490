import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundingnumber'
})
export class RoundingnumberPipe implements PipeTransform {

  transform(value: any): any {
    if(!value) return 0;

    return Math.round(value)
  }

}
