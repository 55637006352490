import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { Globals } from '../../../../core/globals/index';
import { WalletsService } from 'src/app/services/wallets.service';
import { BalanceRequestFormComponent } from '../balance-request-form/balance-request-form.component';
import { ConfirmationFormComponent } from 'src/app/core/components/confirmation-form/confirmation-form.component';

@Component({
  selector: 'app-wallet',
  templateUrl: './balance-request.component.html',
  styleUrls: ['./balance-request.component.scss']
})
export class BalanceRequestComponent implements OnInit {

  defaultImage = 'assets/image/uim_image-v.png'
  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'wallet',
  }
  status: any
  details: any
  isFilter: boolean = false
  currentUser: any
  wallets: any
  transactions: any
  loadingError = false
  loading = false
  params: any = {}
  page = 1

  tabs = [{
    name: 'tab1',
    state: true
  }, {
    name: 'tab2',
    state: false
  }];

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private walletsService: WalletsService,
    private globals: Globals,
    private alertify: AlertifyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  selectStatus(statusName: string) {
    this.params.status = statusName
    this.page = 1
    this.getWallets()
  }


  ngOnInit(): void {
    this.currentUser = JSON.parse(this.globals.currentUser || null)
    this.params.status = ''
    this.status = this.params.status
    this.activatedRoute.queryParams.subscribe((map: any) => {
      if (Object.keys(map).length) {
        this.params = {
          ...map,
        }

        this.page = this.params['page']
      }
      this.getWallets()
    })
  }

  getWallets() {
    this.loading = true
    const params = {
      ...this.params,
      page: this.page,
    }
    this.addQueryString(params)
    this.walletsService.geWallets(params).subscribe((res: any) => {
      this.wallets = res.data.items
      this.loading = false
      this.loadingError = false
      this.updatePagination(res.data.pagination)
    })
  }

  getTransactions() {
    this.loading = true
    const params = {
      ...this.params,
      page: this.page,
    }
    this.addQueryString(params)
    this.walletsService.geTransactions(params).subscribe((res: any) => {
      this.transactions = res.data.items
      this.loading = false
      this.loadingError = false
      this.updatePagination(res.data.pagination)
    })
  }


  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'wallets',
    }
  }


  onPageChangeMarketPlaces(pageNo: any) {
    this.page = pageNo
    this.getWallets()
  }


  goToDetails(id) {
    this.params = {
      ...this.params,
      page: this.page,
    }
    this.router.navigate([`/balance-request/details/${id}`], { queryParams: this.params })
  }


  openFilter() {
    this.isFilter = !this.isFilter
  }


  addQueryString(param) {
    let queryParams: string = param
    let params = new HttpParams()
    for (const [key, value] of Object.entries(queryParams)) {
      params = params.append(key, value)
    }

    window.history.replaceState({}, '', `/balance-request?${params.toString()}`)
  }

  clearFilter() {
    window.history.replaceState({}, '', `/balance-request`)
    this.params.status = ''
    this.status = this.params.status
    this.page = 1
    if (this.tabs[1].state) {
      this.getTransactions()
    } else {
      this.getWallets()
    }
  }



  createWalletForm() {
    const dialogRef = this.dialog.open(BalanceRequestFormComponent, {
      width: '90%',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((date) => {
      if (date) {
        this.walletsService.createWallet(date).subscribe((res: any) => {
          this.getWallets()
        })
      }

    });
  }

  geWalletInfo() {
    this.walletsService.geWalletInfo().subscribe((res: any) => {
      this.details = res.data
    })
  }



  changTab(tabNumber) {
    this.tabs.forEach(tab => {
      tab.state = tab.name == tabNumber ? true : false;
    });
    this.clearFilter()

  }
  accept(item) {
    this.translate
      .get('DRIVERS.massage.accept', {
        value: 'accept',
      })
      .subscribe((res: string) => {
        const dialogRef = this.dialog.open(ConfirmationFormComponent, {
          width: '50%',
          disableClose: true,
          data: { component: 'acceptQuotation', message: res }
        });
        dialogRef.afterClosed().subscribe((mood) => {
          if (mood != false) {
            this.walletsService.accept(item.id, mood).subscribe((res: any) => {
              this.getWallets()
            }, (error: any) => {
              if (error.error.code == 400) {
                this.alertify.error(error.error.error);
              }
            })
          }

        });
      });

  }


}

