import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private globals: Globals, private rouetr: Router, private AuthenticationService: AuthenticationService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.AuthenticationService.LoggedIn()) {
      return true;
    }
    this.rouetr.navigate(['/login']);
    return false;
  }

}
