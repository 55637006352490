import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimRequestComponent } from './balance-request/balance-request.component';
import { ClaimRequestDetailsComponent } from './balance-request-details/balance-request-details.component';

const routes: Routes = [
  { path: '', component: ClaimRequestComponent, data: { title: 'claim' } },
  { path: 'details/:id', component: ClaimRequestDetailsComponent, data: { title: 'claim Details' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClaimRoutingModule { }
