<div class="table-container">



  <div class="flex flex-col md:flex-row justify-between pl-4 pr-4 pt-4">
    <h2 class="mb-2">{{ "HEADER.DATA_MANAGEMENT.delivery_management" | translate }}</h2>
    <div class="flex justify-end pl-4 pr-4 pb-4">
      <!-- <button class="btn btn-default ml-2 mr-2" (click)="clearFilter()"><i class='bx bx-revision'></i></button>
      <button class="btn btn-default" [class.btn-open-filter]="isFilter" (click)="openFilter()">
        <i class='bx bx-filter-alt'></i>
      </button> -->
    </div>

  </div>



  <!-- <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap "
    [class.open-filter]="isFilter">

    <select (change)="selectStatusMarketPlaces($event.target.value)" class="form-select ml-2  mb-2"
      [(ngModel)]="status">
      <option selected value="all">{{ "QUOTION.attributes.status.all" | translate }}
      </option>
      <option value="waiting_offers">{{ "QUOTION.attributes.status.waiting_offers" | translate }}
      </option>
      <option value="offer_sent">{{ "QUOTION.attributes.status.offer_sent" | translate }}</option>
      <option value="offer_not_sent">{{ "QUOTION.attributes.status.offer_not_sent" | translate }} </option>

      <option value="accepted_offer">{{ "QUOTION.attributes.status.accepted_offer" | translate }}
      </option>

      <option value="waiting_review">{{ "QUOTION.attributes.status.waiting_review" | translate }}
      </option>
      <option value="done">{{ "QUOTION.attributes.status.done" | translate }}</option>
    </select>


  </div> -->




  <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
  </app-pagination>



  <div class="component-loader" (errorCallback)="getMarketPlaces()" [errorMessage]="'lOADER.errorMessage' | translate "
    component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
    [componentLoading]="loading">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{
            "MARKETPLACES.extras.number"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "MARKETPLACES.extras.direction"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "MARKETPLACES.extras.ride_type"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "MARKETPLACES.extras.address_line1"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "MARKETPLACES.extras.address_line1to"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "MARKETPLACES.actions.release_to_drivers"
            | translate
            }}
          </th>


        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let marketplace of marketplaces trackBy: trackByFn ">
          <td class="pointer weight-label" (click)="goToDetails(marketplace.id)">
            {{ marketplace?.number }}
          </td>

          <td class="">
            <div>
              {{ ("MARKETPLACES.extras." + marketplace?.quotation_request?.direction) | translate}}
            </div>
          </td>
          <td class="">
            <div>
              {{ marketplace?.quotation_request?.ride_type }}
            </div>
          </td>
          <td class="">
            <div>
              {{ marketplace?.quotation_request?.pickup_address?.address_line1 }}
            </div>
          </td>
          <td class="">
            <div>
              {{ marketplace?.quotation_request?.delivery_address?.address_line1 }}
            </div>
          </td>

          <td>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="list-{{marketplace.id}}"
                [ngModel]="marketplace?.release_to_drivers"
                (change)="releaseToDrivers(marketplace.id, $event.target['checked'])">
            </div>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
</app-pagination>