import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileTypeService {
  constructor() { }

  checkPattern(file) {
    let pattern = /image-*/;
    return file.type.match(pattern);
  }

}
