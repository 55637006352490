<div class="modal-wrapper">
  <i class='bx bx-x pointer text-3xl flex' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "CHANGE_PASSWORD.title" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <form [formGroup]="changePasswordForm">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12"
            [class.has-error]="!changePasswordForm.get('current_password').valid &&changePasswordForm.get('current_password').touched">
            <div class="form-group">
              <label class="text-gray control-label">{{ "CHANGE_PASSWORD.current_password.label" | translate}}</label>
              <input class="form-control" type="{{ changeHiddenCurrentPassword }}" formControlName="current_password" />
              <span class="icon">
                <i class="fa fa-eye" aria-hidden="true" (click)="changeCurrentPasswordHidden()"></i>
              </span>
            </div>
            <!-- <span class="text-error size-small mt-2 validator">
              {{ "CHANGE_PASSWORD.current_password.label" | translate }}
            </span> -->
          </div>
          <div class="col-md-12"
            [class.has-error]="!changePasswordForm.get('password').valid && changePasswordForm.get('password').touched ">
            <div class="form-group">
              <label class="mb-2">{{"CHANGE_PASSWORD.new_password.label" | translate}}</label>
              <input class="form-control " type="{{ changeHiddenPassword }}" formControlName="password" />
              <span class="icon">
                <i class="fa fa-eye" aria-hidden="true" (click)="changePasswordHidden()"></i>
              </span>
            </div>
            <!-- <span class="text-error size-small mt-2 validator">
              {{ "CHANGE_PASSWORD.new_password.label" | translate }}
            </span> -->
          </div>
        </div>
      </div>
    </form>
    <div class="modal-footer mt-4">
      <button class="btn btn-primary mb-2" (click)="onSubmit()">
        {{ "CHANGE_PASSWORD.action.change" | translate }}
      </button>
      <button class="btn btn-default mb-2" (click)="closeModal()">
        {{ "CHANGE_PASSWORD.action.cancel" | translate }}
      </button>
    </div>
  </div>
</div>
