<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      <ng-container *ngIf="data == null">
        {{ "DRIVERS.actions.add_worker"| translate }}
      </ng-container>
      <ng-container *ngIf="data != null">
        {{ "DRIVERS.actions.edit_worker" | translate }}
      </ng-container>

    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <form [formGroup]="userForm">
          <div class="row mb-2">

            <div class="col-md-6" [class.has-error]="!userForm.get('name').valid && isSubmit">
              <div class="form-group">
                {{ "DRIVERS.extras.name" | translate }}
                <input class="form-control" type="text" formControlName="name" />
              </div>
            </div>
            <div class="col-md-6" [class.has-error]="!userForm.get('phone').valid && isSubmit">
              <div class="form-group">
                {{ "DRIVERS.extras.phone" | translate }}
                <input class="form-control" type="text" formControlName="phone" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                {{ "DRIVERS.extras.password" | translate }}
                <input class="form-control" type="text" formControlName="password" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                {{ "DRIVERS.extras.email" | translate }}
                <input class="form-control" type="text" formControlName="email" />
              </div>
            </div>

            <!-- <div class="col-md-6 mb-10" *ngIf="data == null">
              <div class="form-group">
                {{ "DRIVERS.extras.user_category" | translate }}
                <ng-select [clearable]="true" class="select select-ng-tabel" formControlName="user_category">
                  <ng-option *ngFor="let item of userCategories" [value]="item">
                    {{("DRIVERS.extras." + item) | translate}}
                  </ng-option>
                </ng-select>
              </div>
            </div> -->


            <div class="col-md-6">
              <div class="form-group file">
                <label class="mb-2"> {{ "DRIVERS.extras.profile" | translate }}</label>
                <div class="input-group mb-3">
                  <input class="form-control " accept=".png, .jpg, .jpeg, .gif" (change)="OnFileSelectedProfile($event)"
                    type="file" />
                </div>

              </div>
            </div>










          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="createWorker()">
        <ng-container *ngIf="data == null">
          {{ "DRIVERS.actions.add_worker"| translate }}
        </ng-container>
        <ng-container *ngIf="data != null">
          {{ "DRIVERS.actions.edit_worker" | translate }}
        </ng-container>
      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>
