import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MarketplaceDetailsComponent } from './marketplace-details/marketplace-details.component';
import { MarketplaceListComponent } from './marketplace-list/marketplace-list.component';
import { MarketplaceOutgoingListComponent } from './marketplace-outgoing-list/marketplace-outgoing-list.component';
import { MarketplaceOutgoingDetailsComponent } from './marketplace-outgoing-details/marketplace-outgoing-details.component';
import { DeliveryManagementListComponent } from './delivery-management-list/delivery-management-list.component';
import { DeliveryManagementDetailsComponent } from './delivery-management-details/delivery-management-details.component';
import { MarketplaceOutgoingCurrentComponent } from './marketplace-outgoing-current/marketplace-outgoing-current.component';
import { MarketplaceOutgoingHistoryComponent } from './marketplace-outgoing-history/marketplace-outgoing-history.component';
import { ContractFormComponent } from './contract-form/contract-form.component';


const routes: Routes = [
  { path: 'marketplace_incoming', component: MarketplaceListComponent, data: { title: 'Marketplace List' } },
  { path: 'marketplace_outgoing', component: MarketplaceOutgoingListComponent, data: { title: 'Marketplace Outgoing List' } },
  { path: 'marketplace_outgoing_history', component: MarketplaceOutgoingHistoryComponent, data: { title: 'Marketplace Outgoing List History' } },
  { path: 'marketplace_outgoing_current', component: MarketplaceOutgoingCurrentComponent, data: { title: 'Marketplace Outgoing List Current' } },
  { path: 'marketplace_incoming/details/:id', component: MarketplaceDetailsComponent, data: { title: 'Marketplace Details' } },
  { path: 'marketplace_outgoing/details/:id', component: MarketplaceOutgoingDetailsComponent, data: { title: 'Marketplace Outgoing Details' } },
  { path: 'marketplace_outgoing_current/details/:id', component: MarketplaceOutgoingDetailsComponent, data: { title: 'Marketplace Outgoing Details' } },
  { path: 'marketplace_outgoing_history/details/:id', component: MarketplaceOutgoingDetailsComponent, data: { title: 'Marketplace Outgoing Details' } },
  { path: 'contract', component: ContractFormComponent, data: { title: 'Contract Form' } },

  { path: 'shipping_management', component: DeliveryManagementListComponent, data: { title: 'Delivery Management List' } },
  { path: 'shipping_management/details/:id', component: DeliveryManagementDetailsComponent, data: { title: 'Delivery Management Details' } },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketplacesRoutingModule { }
