import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreProfileService {

  constructor(private http: HttpClient) { }

  getStoreProfile() {
    return this.http.get(baseUrl + `shops/api/v1/profile`);
  }
  getShopCategories(params: any) {
    return this.http.get(baseUrl + `shops/api/v1/shop_categories`, { params: params });
  }

  updateStoreProfile(model: any) {
    return this.http.put<any>(baseUrl + 'shops/api/v1/profile', model);
  }

  getProfile() {
    return this.http.get(baseUrl + `users/api/v1/profile`);
  }

}
