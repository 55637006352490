<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header ">
    <div class="text-center">
      <p>
        {{ "WHOLESALE.extras.total_tax_included" | translate }} {{total_after_tax}}
        <strong>{{'CURRENCY.'+currency | translate }}</strong>
      </p>
    </div>
    <div class="flex justify-between">
      <p class="w-full text-center"> {{'PURCHASE_ORDER.attributes.offer_details'|translate}}</p>
    </div>
  </div>
  <div class="modal-content">
    <div class="component-loader">
      <div class="modal-body">

        <div class="row">
          <div class="col-md-12">
            <div class="table">
              <table class="">
                <thead>
                  <tr>
                    <th scope="col" class="text-center"> {{ "PURCHASE_ORDER.attributes.part_number" | translate }}</th>
                    <th scope="col" class="text-center"> {{ "PURCHASE_ORDER.attributes.name" | translate }}</th>
                    <th scope="col" class="text-center"> {{ "PURCHASE_ORDER.attributes.quantity.label" | translate }}
                    </th>
                    <!-- <th scope="col" class="text-center"> {{ "PRODUCT.attributes.price" | translate }} </th> -->
                    <!-- <th scope="col" class="text-center"> {{ "PURCHASE_ORDER.attributes.brand_name" | translate }}</th> -->
                    <th scope="col" class="text-center"> {{ "PURCHASE_ORDER.attributes.country_name" | translate }}</th>



                    <th scope="col" class="text-center"> </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of offer_items">
                    <tr>
                      <td class="text-center">{{item.part_number}}</td>
                      <td class="text-center">{{item.name}}</td>
                      <td class="text-center">{{item.quantity}}</td>
                      <!-- <td class="text-center">{{item.price}} <strong>{{"sar"|translate}}</strong></td> -->
                      <!-- <td class="text-center">{{item.brand_name}}</td> -->
                      <td class="text-center">{{item.country_name}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>


          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="mb-2"> {{ "EXPENSES.attributes.notes" | translate }}</label>
              <span>
                {{notes}}
              </span>
            </div>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <button class="btn btn-primary mb-2" *ngIf="status != 'accepted_offer' && status != 'canceled'"
          (click)="acceptOffer()">
          {{ "PURCHASE_ORDER.attributes.accept_offer" | translate }}
        </button>
        <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>


      </div>
    </div>
  </div>
</div>
