<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">{{ "STOREPROFILE.extra.profile_info" | translate }}</p>
  </div>
  <div class="modal-content">

    <form [formGroup]="shopeProfileForm">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-2">
                {{ "STOREPROFILE.extra.name_ar" | translate }}
              </label>
              <input class="form-control spec-name_ar" type="text" formControlName="name_ar" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" [class.has-error]="
                            !shopeProfileForm.get('name_en').valid
                          ">
              <label class="mb-2">
                {{ "STOREPROFILE.extra.name_en" | translate }}
              </label>
              <input class="form-control spec-name_en" type="text" formControlName="name_en" />
              <span class="text-error size-small mt-2 validator"
                *ngIf="shopeProfileForm.get('name_en')?.errors?.pattern">
                {{ "STOREPROFILE.extra.name_en_error_msg" | translate }}
              </span>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-2">
                {{ "STOREPROFILE.extra.description_ar" | translate }}
              </label>
              <textarea class="form-control" formControlName="description_ar"></textarea>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-2">
                {{ "STOREPROFILE.extra.description_en" | translate }}
              </label>
              <textarea class="form-control" formControlName="description_en"></textarea>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-2">
                {{ "STOREPROFILE.extra.business_category_ar" | translate }}
              </label>
              <textarea class="form-control" formControlName="business_category_ar"></textarea>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" [class.has-error]="!shopeProfileForm.get('business_category_en').valid">
              <label class="mb-2">
                {{ "STOREPROFILE.extra.business_category_en" | translate }}
              </label>
              <textarea class="form-control" formControlName="business_category_en"></textarea>
              <span class="text-error size-small mt-2 validator"
                *ngIf="shopeProfileForm.get('business_category_en')?.errors?.pattern">
                {{ "STOREPROFILE.extra.business_category_en_error_msg" | translate }}
              </span>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-2">
                {{ "STOREPROFILE.extra.tax_licence" | translate }}
              </label>
              <input class="form-control spec-tax_number" type="text" formControlName="tax_number" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-2">
                {{ "STOREPROFILE.extra.email" | translate }}
              </label>
              <input class="form-control spec-email" type="text" formControlName="email" />
            </div>
          </div>
          <!-- <div class="col-md-6">
              <div class="form-group">
                <label class="mb-2">
                  {{ "STOREPROFILE.extra.address" | translate }}
                  </label
                >
                <input
                  class="form-control"
                  type="text"
                  formControlName="address_line1"
                />
              </div>
            </div> -->
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-2">
                {{ "STOREPROFILE.extra.phone" | translate }}
              </label>
              <input class="form-control spec-phone" type="text" formControlName="phone" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group file">
              <label class="mb-2"> {{ "STOREPROFILE.extra.image" | translate }}</label>
              <div class="input-group mb-3">
                <input class="form-control spec-store-profile-imge" name="spec-store-profile-imge"
                  accept=".png, .jpg, .jpeg, .gif" (change)="OnFileProfileSelected($event)" type="file"
                  formControlName="attachment" />
              </div>

            </div>
          </div>

          <!-- ONLINE PRESENCE -->
          <div class="col-md-6" *ngIf="(!isWholesale && !isMarketplace) && isStoreOnline">
            <div class="form-group file">
              <label class="mb-2"> {{ "STOREPROFILE.extra.cover_image" | translate }}</label>
              <span class="mx-2 fa fa-info-circle" mat-raised-span matTooltipPosition="left"
                matTooltip='{{"STOREPROFILE.extra.for_online_presence_usage" | translate}}'></span>
              <div class="input-group mb-3">
                <input class="form-control spec-store-profile-imge" name="spec-store-cover-imge"
                  accept=".png, .jpg, .jpeg, .gif" (change)="OnFileCoverSelected($event)" type="file"
                  formControlName="cover_image" />
              </div>
            </div>
          </div>

          <div class="col-md-6" *ngIf="(!isWholesale && !isMarketplace) && isStoreOnline">
            <div class="form-group file">
              <label class="mb-2"> {{ "STOREPROFILE.extra.banner_image" | translate }}</label>
              <span class="mx-2 fa fa-info-circle" mat-raised-span matTooltipPosition="left"
                matTooltip='{{"STOREPROFILE.extra.for_online_presence_usage" | translate}}'></span>
              <div class="input-group mb-3">
                <input class="form-control spec-store-profile-imge" name="spec-store-banner-imge"
                  accept=".png, .jpg, .jpeg, .gif" (change)="OnFileBannerSelected($event)" type="file"
                  formControlName="banner_image" />
              </div>

            </div>
          </div>

          <div class="col-md-6" *ngIf="(!isWholesale && !isMarketplace) && isStoreOnline">
            <div class="form-group">
              <label class="mb-2">{{ "STOREPROFILE.extra.video_url" | translate }}</label>
              <span class="mx-2 fa fa-info-circle" mat-raised-span matTooltipPosition="left"
                matTooltip='{{"STOREPROFILE.extra.for_online_presence_usage" | translate}}'></span>
              <input class="form-control spec-phone" type="text" formControlName="video_url" />
            </div>
          </div>

          <div class="col-md-6" *ngIf="(!isWholesale && !isMarketplace) && isStoreOnline">
            <div class="form-group">
              <label class="mb-2">{{ "STOREPROFILE.extra.delivery_time_days" | translate }}</label>
              <span class="mx-2 fa fa-info-circle" mat-raised-span matTooltipPosition="left"
                matTooltip='{{"STOREPROFILE.extra.for_online_presence_usage" | translate}}'></span>
              <input class="form-control" type="number" formControlName="delivery_time" appNumberOnly="$event" />
            </div>
          </div>
          <!-- /ONLINE PRESENCE -->

          <div class="col-md-6">
            <div class="form-group" [class.has-error]="
                            !shopeProfileForm.get('address_line1').valid && isSubmitted
                          ">
              <label class="mb-2">{{ "WAREHOUSES.attributes.address.label" | translate }}</label>
              <input class="form-control spec-address_line1" type="text" formControlName="address_line1" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group mt-7">
              <button class="btn btn-primary mr-2 spec-get_location" (click)="openMapForm()">
                {{ "MAP.attributes.get_location.label" | translate }}
              </button>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group" [class.has-error]="
                            !shopeProfileForm.get('lat').valid && isSubmitted
                          ">
              <label class="mb-2">{{ "WAREHOUSES.attributes.latitude.label" | translate }}</label>
              <input class="form-control spec-lat" type="number" formControlName="lat" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" [class.has-error]="
                            !shopeProfileForm.get('long').valid && isSubmitted
                          ">
              <label class="mb-2">{{ "WAREHOUSES.attributes.longitude.label" | translate }}</label>
              <input class="form-control spec-long" type="number" formControlName="long" />
            </div>
          </div>

          <!-- <div class="col-md-6">
            <label class="mb-2">{{"STOREPROFILE.extra.incoming_order_categories" | translate}}</label>
            <ng-select [items]="shopCategories" [clearable]="true" [multiple]="true" bindLabel="name" bindValue="id"
              formControlName="shop_category_ids" class="select select-ng-tabel" data-cy="countries">
            </ng-select>
          </div> -->

        </div>
      </div>
    </form>

    <div class="modal-footer">
      <button class="btn btn-primary mb-2" (click)="onSubmit()">
        {{ "COMMON.edit" | translate }}
      </button>
      <button class="btn btn-default mb-2" (click)="closeModal()">
        {{ "COMMON.cancel" | translate }}
      </button>
    </div>
  </div>
</div>