import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { DriversService } from 'src/app/services/drivers.service';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {

  reader: FileReader;
  selected = null;
  truckForm: any = this.fb.group({
    comment: ['', Validators.required],
    attachments_attributes: [],
  });

  constructor(private dialogRef: MatDialogRef<AddCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private driversService: DriversService,
    private fb: FormBuilder,
    public translate: TranslateService,) {
    this.reader = new FileReader();

  }


  OnFileSelected2(event) {
    this.selected = <File>event.target.files[0];
    if (this.selected) {
      const fileTypes = ['jpg', 'png', 'jpeg', 'gif'];
      let name = this.selected.name;
      let array = name.split('.');
      let fileType = array[array.length - 1];
      if (fileTypes.includes(fileType)) {
        this.reader.readAsDataURL(this.selected);
        this.reader.onload = () => { };
      } else {
        this.translate
          .get('MARKETPLACES.messages.mass_file_not_available', {
            value: 'mass_file_not_available',
          })
          .subscribe((res: string) => {
            const dialogRef = this.dialog.open(MessageFormComponent, {
              width: '400px',
              disableClose: true,
              data: { message: res, state: false },

            });
            dialogRef.afterClosed().subscribe(() => {
              this.selected = {}
            });
          });
      }
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  addComment() {
    if (this.truckForm.valid) {
      let data = {
        comment: {
          ...this.truckForm.value
        }
      }
      if (this.selected != null) {
        data.comment.attachments_attributes = [
          {
            name: new Date(),
            file: this.reader.result
          },

        ]
      }

      this.dialogRef.close(data);
    }
  }


}
