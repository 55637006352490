import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shipment-reschedule-requests',
  templateUrl: './shipment-reschedule-requests.component.html',
  styleUrls: ['./shipment-reschedule-requests.component.scss']
})
export class ShipmentRescheduleRequestsComponent implements OnInit {

  isSubmit: boolean = false;
  startDateFormat: any = null
  pickupTime: any = null
  max: any = null
  min: any = null
  truckForm: any = this.fb.group({
    new_shipment_date: ['', Validators.required],
    pickup_time: ['', Validators.required],
    notes: [''],
  });

  constructor(private dialogRef: MatDialogRef<ShipmentRescheduleRequestsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public translate: TranslateService,) {
  }

  ngOnInit() {
    this.truckForm.patchValue({
      new_shipment_date: new Date(this.data.trip.shipment_date),
      pickup_time: new Date(this.data.trip.shipment_date)
    })
  }


  setDateBox(event, name) {
    if (name === 'new_shipment_date') {
      this.min = event.target.value
    } else {
      this.max = event.target.value
    }
    this.truckForm.patchValue({
      pickup_time: new Date(event.target.value)
    })

  }



  infoFormFormatted() {
    this.startDateFormat = new Date(this.truckForm.get('new_shipment_date')?.value).getTime()
    this.pickupTime = new Date(this.truckForm.get('pickup_time')?.value).getTime()
  }


  closeModal() {
    this.dialogRef.close();
  }



  addShipmentRescheduleRequests() {
    this.isSubmit = true;
    if (this.truckForm.valid) {
      this.infoFormFormatted();
      let data = {
        shipment_reschedule_request: {
          notes: this.truckForm?.get('notes')?.value,
          new_shipment_date: (this.pickupTime - new Date(this.truckForm?.get('pickup_time')?.value).getTimezoneOffset() * 60000) / 1000,
        }
      }
      this.dialogRef.close(data);
    }
  }


}
