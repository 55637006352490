import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WalletComponent } from './wallet/wallet.component';
import { WalletDetailsComponent } from './wallet-details/wallet-details.component';

const routes: Routes = [
  { path: '', component: WalletComponent, data: { title: 'wallet' } },
  { path: 'details/:id', component: WalletDetailsComponent, data: { title: 'wallet Details' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WalletRoutingModule { }
