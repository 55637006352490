import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  apiUrl = `shops/api/v1/countries.json`;

  constructor(
    private http: HttpClient
  ) {
  }

  getCountries(params = {}) {
    return this.http.get(baseUrl + this.apiUrl, { params: params });
  }
  updateUserCountries(id, countryIds) {
    return this.http.post(baseUrl + 'shops/api/v1/countries/assign_countries_to_user', {
      user_id: id,
      country_ids: countryIds
    });
  }
}
