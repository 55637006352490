import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.css']
})
export class OtpFormComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<OtpFormComponent>,
    public translate: TranslateService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  openZatcaPortal() {
    window.open("https://login.gazt.gov.sa/irj/portal?ume.logon.locale=ar&login=angular", "_blank");
  }

  onOtpChange(otp: any) {
    if (otp.length == 5) {
      this.dialogRef.close(otp)
    }

  }

  onSubmit() {

  }


  closeModal() {
    this.dialogRef.close();
  }

}
