<div class="modal-wrapper" [formGroup]="reasonForm">
  <ng-container *ngIf="data.type !='warranty_item_create' ">
    <i class='bx bx-x pointer text-3xl flex' *ngIf="data.preventBack != true" (click)="closeModal()"></i>
  </ng-container>
  <div class="modal-header">
    <p class="w-full text-center">
      {{massage}}
    </p>
  </div>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12" *ngFor="let item of reasons">
          <div class="form-group flex flex-row items-center h-10">
            <input class="form-check-input mr-2 ml-2" id="{{item.id}}" type="radio" value="{{item.id}}"
              [checked]="selectedReason == item.id " (change)="setReasons($event.target.value,item?.id)">
            <label class="form-check-label ml-2 mr-2" for="{{item.id}}">
              {{item?.name}}
            </label>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group flex flex-row items-center h-10">
            <input class="form-check-input mr-2 ml-2" type="radio" value="other" [checked]="selectedReason == 'other' "
              (change)="setReasons($event.target.value,'other')">
            <label class="form-check-label ml-2 mr-2">
              {{'COMMON.REASONS.OTHER' | translate }}
            </label>
          </div>
        </div>
        <div class="col-md-12" *ngIf="selectedReason == 'other'">
          <div class="form-group">
            <textarea class="form-control" cols="40" formControlName="reason"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-4">
      <button class="btn btn-primary mb-2" (click)="onSubmit()" [disabled]="!reasonForm.valid">
        {{ "COMMON.confirm" | translate }}
      </button>
      <ng-container *ngIf="data.type !='warranty_item_create'">
        <button *ngIf="data.preventBack != true" class="btn btn-default mb-2" (click)="closeModal()">
          {{ "COMMON.cancel" | translate }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
