import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels }
  from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.css']
})
export class ShareButtonsComponent implements OnInit {

  shareURL: string

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  constructor(
    public translate: TranslateService,
    private alertify: AlertifyService,
    private dialogRef: MatDialogRef<ShareButtonsComponent>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data != null) {
      this.shareURL = this.data
    }

  }

  copyLink() {
    let message = this.shareURL
    let selBox: any = this.document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = 0;
    selBox.style.top = 0;
    selBox.style.opacity = 0;
    selBox.value = message;
    this.document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(selBox);
    this.translate
      .get('STOREPROFILE.extra.link_copied', { value: 'link_copied' })
      .subscribe((res: string) => {
        this.alertify.success(res);
      });
  }

  closeModal() {
    this.dialogRef.close();
  }

}
