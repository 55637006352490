import { MatDialog } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component'
import { AlertifyService } from 'src/app/core/services/alertify.services'
import { CoverageZonesService } from 'src/app/services/coverage-zones.service'
import { HomeService } from 'src/app/services/home.service'
import { ProductsService } from 'src/app/services/products.service'
// import { Globals } from '../../core/globals/';
import { Component, OnInit, ViewChild, } from '@angular/core';
import { days, months } from '../../home/home.const';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loadingError = false
  loading = false
  isMarketplace: boolean = false;

  chartTypePie1: any
  chartDatasetsPie1: any
  chartLabelsPie1: any
  chartColorsPie1: any
  chartTypePie2: any
  chartDatasetsPie2: any
  chartLabelsPie2: any
  chartColorsPie2: any
  chartTypePie3: any
  chartDatasetsPie3: any
  chartLabelsPie3: any
  chartColorsPie3: any

  chartOptions4: any
  chartOptions2: any
  chartOptions3: any


  homeDataInfo: any
  isloading: boolean = true
  // currentUser: any = {};
  zatcaUser: any
  styleElement: HTMLStyleElement;
  colors: Array<string> = ["#FFB200", "#FFF5CC", "#55CF61", "#c6f0ca"];
  value1 = 80;
  value2 = 40;


  constructor(private homeDataService: HomeService,
    // private globals: Globals,
    public translate: TranslateService) { }

  ngOnInit() {
    // this.currentUser = JSON.parse(this.globals.currentUser || null)
    // this.isMarketplace = this.currentUser?.shop.is_marketplace;

    this.getHomePageData()
    this.barChart()

    this.pieChart1()
    this.pieChart2()
    this.pieChart3()
    this.changeColors()
    this.chartLine()
  }



  chartLine() {
    this.chartOptions4 = {
      series: [],
      chart: {
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      colors: ['#02A0FC', '#FF4560'],
      grid: {
        padding: {
          right: 50,
          left: 80,
        },
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },

      yaxis: {

      },
      xaxis: {
        categories: months
      }
    }
  }


  changeColors() {
    this.styleElement = document.createElement('style');
    const head = document.getElementsByTagName('head')[0];
    const css = `
    .style1 .mat-mdc-progress-bar-fill::after {
      background-color: ${this.colors[0]} !important;
    }
    .style1 .mat-mdc-progress-bar-buffer   {
      background-color: ${this.colors[1]}!important;
    }

    .style2 .mat-mdc-progress-bar-fill::after {
      background-color: ${this.colors[2]} !important;
    }
    .style2 .mat-mdc-progress-bar-buffer  {
      background-color: ${this.colors[3]}!important;
    }
    `;
    this.styleElement.innerHTML = '';
    this.styleElement.type = 'text/css';
    this.styleElement.appendChild(document.createTextNode(css));
    head.appendChild(this.styleElement);

  }

  // userHasRoles(roles) {
  //   const userRoles = this.currentUser?.shop?.roles.map(role => role.key);
  //   return userRoles?.some(userRole => roles.includes(userRole));
  // }



  pieChart1() {
    this.chartTypePie1 = 'pie'
    this.chartDatasetsPie1 = [];
    // this.chartLabelsPie1 = [
    //   'آجلة',
    //   'نقدية',
    // ]
    this.chartColorsPie1 = [
      {
        backgroundColor: [
          'rgba(255, 69, 96, 1)',
          'rgba(255, 192, 201, 1)',
        ],
        borderColor: [
          'rgba(255, 69, 96, 1)',
          'rgba(255, 192, 201, 1)',
        ],
      },
    ]
  }

  pieChart2() {
    this.chartTypePie2 = 'pie'
    this.chartDatasetsPie2 = [];
    // this.chartLabelsPie2 = [
    //   'المكتمل',
    //   'القائم',
    // ]
    this.chartColorsPie2 = [
      {
        backgroundColor: [
          'rgba(2, 160, 252, 1)',
          'rgba(204, 248, 254, 1)',
        ],
        borderColor: [
          'rgba(2, 160, 252, 1)',
          'rgba(204, 248, 254, 1)',
        ],
      },
    ]
  }
  pieChart3() {
    this.chartTypePie3 = 'pie'
    this.chartDatasetsPie3 = [];
    // this.chartLabelsPie3 = [
    //   'الفواتير',
    //   'المرتجعات',
    // ]
    this.chartColorsPie3 = [
      {
        backgroundColor: [
          'rgba(85, 207, 97, 1)',
          'rgba(205, 233, 201, 1)',
        ],
        borderColor: [
          'rgba(85, 207, 97, 1)',
          'rgba(205, 233, 201, 1)',
        ],
      },
    ]
  }

  getHomePageData() {
    this.loading = true

    this.homeDataService.getHomePageData().subscribe(
      (response: any) => {

        this.homeDataInfo = response
        this.value1 = this.homeDataInfo?.store_revenue_current_month
        this.value2 = this.homeDataInfo?.store_yearly_revenue_target
        this.chartDatasetsPie1 = [
          this.homeDataInfo.invoce_cash_ratio,
          this.homeDataInfo.invoce_later_ratio,
        ]
        this.chartDatasetsPie3 = [
          this.homeDataInfo.invoice_sell_ratio,
          this.homeDataInfo.invoce_refund_ratio,
        ]
        this.chartDatasetsPie2 = [
          this.homeDataInfo.pending_orders_ratio,
          this.homeDataInfo.delivered_orders_ratio,
        ]
        this.chartOptions2.series = [
          { data: this.homeDataInfo?.previous_year_sales, name: 'الشهر السابق ' },
          { data: this.homeDataInfo?.current_month_sales, name: 'الشهر الحالي ' },
        ]
        this.chartOptions4.series = [
          {
            name: 'العام الحالي ',
            data: this.homeDataInfo?.current_year_sales
          },
          {
            name: 'العام السابق ',
            data: this.homeDataInfo?.previous_year_sales
          }
        ]

        this.zatcaUser = {
          simple_invoicing: response?.shop?.simple_invoicing,
          is_zatca_ready: response?.shop?.is_zatca_ready,
          invoice_total_count: response?.invoice_total_count,
          invoice_total: response?.invoice_total,
        }

        this.isloading = false
        this.loading = false
        this.loadingError = false
      },
      (error) => { },
    )
  }


  barChart() {
    //bar
    this.chartOptions2 = {
      series: [

      ],
      chart: {
        type: "bar",
      },

      colors: ['#FFCD12', '#55CF61',],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      yaxis: {
        labels: {
          show: true,
          align: 'center',
          minWidth: 0,
          maxWidth: 160,

        },
      },
      xaxis: {
        categories: days
      },

      fill: {
        opacity: 1
      },
      tooltip: {}
    }
  }






  // all
  public chartOptions: any = {
    responsive: true,
  }
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }
}
