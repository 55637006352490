import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reason-confirmation',
  templateUrl: './reason-confirmation.component.html',
  styleUrls: ['./reason-confirmation.component.scss']
})
export class ReasonConfirmationComponent implements OnInit {

  reasonForm: FormGroup;
  massage: string;
  type: string;
  reasons: any
  selectedReason

  constructor(
    private dialogRef: MatDialogRef<ReasonConfirmationComponent>,
    public translate: TranslateService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true
  }

  ngOnInit() {
    this.reasons = this.data?.reasons || []
    this.type = this.data?.type
    this.reasonForm = this.fb.group({
      reason: ['', [Validators.required]],
      reason_id: ['', [Validators.required]]
    })
    this.massage = this.data?.message
    this.reasonForm.get('reason_id').setValue(this.reasons[0]?.id)
    this.reasonForm.get('reason').setValue('')
    this.reasonForm.get('reason').clearValidators()
    this.reasonForm.updateValueAndValidity()
    this.selectedReason = this.reasons[0]?.id
  }

  setReasons(value: any, reason: any) {
    this.selectedReason = value ;
    if (this.selectedReason != 'other') {
      this.reasonForm.get('reason_id').setValue(reason)
      this.reasonForm.get('reason').clearValidators()
      this.reasonForm.get('reason').setValue(null)
    } else {
      this.reasonForm.get('reason_id').clearValidators()
      this.reasonForm.get('reason').setValidators([Validators.required])
      this.reasonForm.get('reason_id').setValue(null)
    }
    this.reasonForm.updateValueAndValidity()
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    let reasonData = {
      reason_Data: {
        ...this.reasonForm.value
      }
    }
    if (this.reasonForm.valid) {
      this.dialogRef.close(reasonData);
    }
  }


}


