import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input('labelName') labelName

  @Output('change') change = new EventEmitter()
  @Input() set paginationData(paginationData: any) {
    this.updateIndices(paginationData)
  }
  start = 0
  end = 1
  page = 1
  perPage = 10
  id: string
  per_page = 10
  current_page = 1
  total_items = 0
  total_pages = 1
  pages: any = []

  constructor() {}

  ngOnInit(): void {}
  updateIndices(paginationParams) {
    this.id = paginationParams.id
    this.per_page = paginationParams.itemsPerPage
    this.current_page = paginationParams.currentPage
    this.total_items = paginationParams.totalItems
    this.total_pages = paginationParams.totalPages
    this.calculatePages()
  }

  calculatePages() {
    this.pages = []
    for (let index = 1; index <= this.total_pages; index++) {
      if (index == this.current_page) {
        this.pages.push({ number: index, active: true })
      } else {
        this.pages.push({ number: index, active: false })
      }
    }

    if (this.current_page == 1) {
      this.start = 1
      this.end = this.current_page + 4
    } else if (this.current_page == 2) {
      this.start = this.current_page - 1
      this.end = this.current_page + 3
    } else {
      this.start = this.current_page - 2
      this.end = this.current_page + 2
    }

    if (this.current_page == this.total_pages) {
      this.end = this.total_pages
      this.start = this.current_page - 4
    }
    if (this.total_pages == 1) {
      this.end = 0
      this.start = 0
    }
  }

  pageChanged(event) {
    this.page = event
    this.change.emit(event)
  }
}
