<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "DRIVERS.actions.assignDriver" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <form [formGroup]="truckForm">
          <div class="row mb-2">


            <div class="col-md-6 mb-10">
              <div class="form-group">
                {{ "HEADER.DATA_MANAGEMENT.drivers" | translate }}
                <ng-select [clearable]="true" class="select select-ng-tabel" formControlName="driver_id">
                  <ng-option *ngFor="let item of drivers" [value]="item.id">
                    {{item.name}}
                  </ng-option>
                </ng-select>
              </div>
            </div>






          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="assignDriver()" [disabled]="truckForm.invalid">
        {{ "DRIVERS.actions.assignDriver" | translate }}

      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>